import {collection} from 'firebase/firestore';
// import { initializeFirestore, doc, collection, enableIndexedDbPersistence, increment, updateDoc,
//  orderBy, query, addDoc, DocumentData, setDoc } from 'firebase/firestore';
import {useFirestore, useSigninCheck, useFirestoreCollectionData} from 'reactfire';

const PARENT_COLLECTION_NAME = 'storage';

/**
 * Custom hook providing access to Firestore collection of signed-in user's documents.
 * REQUIRES FirestoreProvider configured in the hierarchy above.
 * @param {string} docName Key of the settings. Each component/use-case is supposed to have an arbitrary key.
 * @return {{}} { status, data }
 */
export function useFirestoreUserCollection() {
  const firestore = useFirestore();
  // console.log('useFirestoreDocument(): firestore.app.projectId=' + JSON.stringify(firestore.app.options?.projectId));
  const {data: signInCheckResult} = useSigninCheck();
  const uid = signInCheckResult?.user?.uid ?? 'anonymous';

  // subscribe to a document for realtime updates
  // Firestore document specific to user
  // console.log('useFirestoreUserCollection(): path=', 'users', uid, PARENT_COLLECTION_NAME);
  const colRef = collection(firestore, 'users', uid, PARENT_COLLECTION_NAME);

  // console.log('useFirestoreCollectionData(): about to read data...');
  const {status, data} = useFirestoreCollectionData(colRef, {
    idField: 'id', // this field will be added to the object created from each document
  });
  // console.log('useFirestoreCollectionData(): data=', data);

  return {status, data};
}
