// import {useState} from 'react';

import {Input} from '../shared/Input';
import {Colors} from '../../constants/colors';
import {useEffect, useState} from 'react';
import {Card} from '../shared/Card';
import {Spacing} from '../shared/Spacing';
import {Line} from '../shared/Line';

/**
 *
 * @return {td}
 */
export function CalculatorManDays() {
  const [valueFromInputA, setValueFromInputA] = useState('');
  const [valueFromInputB, setValueFromInputB] = useState('');
  const [valueFromInputC, setValueFromInputC] = useState('');
  const [hours, setHours] = useState(0);
  const [mins, setMins] = useState(0);

  useEffect(() => {
    const days: number = parseInt(valueFromInputA);
    const percent: number = parseInt(valueFromInputB);
    console.log('days', days, 'percent', percent);
    let result = '';
    if (days && percent) {
      const resultNumber = countMD(days, percent);
      const hoursCount = cleanNumber(countHours(resultNumber));
      const minsCount = cleanNumber(countMins(resultNumber));

      setHours(hoursCount);
      setMins(minsCount);

      result = cleanNumber(resultNumber).toString();
    }

    setValueFromInputC(result);
  }, [valueFromInputA, valueFromInputB]);

  const countMD = (days: number, percent: number): number => {
    return (days / 100) * percent;
  };

  const cleanNumber = (num: number) => {
    num = Math.round(num * 100) / 100;
    return num;
  };

  const countHours = (number: number): number => {
    if (number) {
      const numb = Math.floor(number);
      const decimals = number - numb;
      return Math.floor(decimals * 8);
    }
    return 0;
  };

  const countMins = (number: number): number => {
    if (number) {
      const numb = Math.floor(number);
      const decimals = number - numb;
      const hours = decimals * 8;
      return (hours - Math.floor(hours)) * 60;
    }
    return 0;
  };

  const MDresult = parseFloat(valueFromInputC);
  const MDresultFloor = Math.floor(MDresult);
  const showDays = MDresult !== 0 && MDresult - MDresultFloor !== 0 && MDresultFloor !== 0 && MDresultFloor;
  const showHours = hours !== 0;
  const showMins = mins !== 0;

  return (
    <div>
      <Card bgColor={Colors.white} minHeight={366}>
        <Spacing />
        <div style={{fontSize: 12, textAlign: 'start'}}> {'Kalkulátor pro výpočet MDs:'}</div>
        <Line />
        <Spacing />
        <Input
          label="kalendářních dní: "
          labelColor={Colors.secondary}
          onChangeCallback={setValueFromInputA}
          value={valueFromInputA}
        />
        <Spacing />
        <Input
          label="procent: "
          labelColor={Colors.secondary}
          value={valueFromInputB}
          onChangeCallback={setValueFromInputB}
        />
        <Spacing />
        <Line />
        <Spacing />
        <Input
          disabled
          labelColor={Colors.white}
          bgColor={Colors.secondary}
          width={184}
          value={valueFromInputC}
          additonalText="MD"
        />
        <div style={{fontSize: 10, textAlign: 'end'}}>
          {showDays ? MDresultFloor + ' dnů ' : ''}
          {showHours ? hours + ' hodin' : ''} {showMins ? mins + ' minut' : ''}
        </div>
      </Card>
    </div>
  );
}
