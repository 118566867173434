import PlusAddButton from './shared/PlusAddButton';
import Table from 'react-bootstrap/Table';
import {REQUEST_STATUS} from '../types/requestStatus';
import {StockData} from '../hooks/useStocks';
import MinusDeleteButton from './shared/MinusDeleteButton';
import StocksBuys from './StocksBuys';
import {StocksSettingsBuy, StockHistoryData, StockDay} from './Stocks.d';
import {useFirestoreCacheDocument} from '../hooks/useFirestoreCacheDocument';

import _ from 'lodash';
import {findNearestOlderRecordByDate} from '../misc/stockDateUtils';
import {DateTime} from 'luxon';

type MyStocksSymbolsTable = {
  setupMode: boolean;
  showModalClick: () => void;
  stockDataStatus: REQUEST_STATUS;
  stockData: StockData[] | undefined;
  showMiniTableDetailsClick: (a: string) => void;
  searchStockInBuys: (a: string) => boolean;
  findNameOfStockFromSymbol: (a: string) => string;
  countTotalProfit: (a: string) => number;
  prettyUSDPlusMinusNumber: (a: number) => string;
  prettyCZKPlusMinusNumber: (a: number) => string;
  czk: number | undefined;
  removeStockClick: (a: string) => void;
  showModalBuyClick: (a: string) => void;
  stocksBuy: StocksSettingsBuy[];
  detailsShowing: string;
  netProfitUSD: (a: number, b: number, c: number) => number;
  deleteBuyClick: (a: StocksSettingsBuy) => void;
  editBuyClick: (a: StocksSettingsBuy) => void;
  countTotalPercent: (a: string) => number;
};

/**
 * Format historical value
 * @param {number} currentValue
 * @param {number} historicalValue
 * @return {string} Formatted string
 */
function formatHistoricalStockValue(currentValue: number, historicalValue: number): string {
  const diff = currentValue - historicalValue;
  const percentage = Math.abs((currentValue - historicalValue) / historicalValue) * 100;
  const sign = diff >= 0 ? '↑' : '↓'; // '+' : '';
  return `$${historicalValue.toFixed(1)} (${sign}${Math.abs(diff).toFixed(1)} ~ ${sign}${percentage.toFixed(0)}%)`;
}

/**
 * a component for rows of user's stocks
 * @return {React.FC}
 */
function StocksSymbolsTable({
  setupMode,
  showModalClick,
  stockDataStatus,
  stockData,
  showMiniTableDetailsClick,
  searchStockInBuys,
  findNameOfStockFromSymbol,
  countTotalProfit,
  prettyUSDPlusMinusNumber,
  prettyCZKPlusMinusNumber,
  czk,
  removeStockClick,
  showModalBuyClick,
  stocksBuy,
  detailsShowing,
  netProfitUSD,
  deleteBuyClick,
  editBuyClick,
  countTotalPercent,
}: MyStocksSymbolsTable) {
  const {status: cacheStatus, data: cacheData} = useFirestoreCacheDocument('cache-stock-historical');
  const stockHistoryData: StockHistoryData = cacheData as StockHistoryData;
  const cacheAvailable = cacheStatus === 'success' && !!stockHistoryData;
  return (
    <>
      {' '}
      {/* ADD button */}
      <PlusAddButton visibility={setupMode} doOnClick={showModalClick} label="Add" />
      {/* THE MAIN TABLE with users stocks */}
      <Table striped hover data-testid="reactTable">
        <thead>
          <tr>
            <th className="text-center">Symbol</th>
            <th className="text-center">Company</th>
            <th className="text-center">Price</th>
            <th className="text-end text-secondary small">Prev. Close</th>
            <th className="text-center fw-bold">%</th>
            <th className="text-end">Total Profit</th>
            <th className="text-center text-secondary fw-normal small">in CZK</th>
          </tr>
        </thead>
        {(stockDataStatus === REQUEST_STATUS.SUCCESS || stockDataStatus === REQUEST_STATUS.LOADING) && (
          <>
            {stockData?.map((stock, i) => {
              // prepare historical stock info
              let stockHistoryInfo = '';
              const stockSymbol = stock.symbol;
              if (cacheAvailable) {
                const stockHistory: StockDay[] | undefined = _.find(
                  stockHistoryData?.stocks,
                  x => x.symbol === stockSymbol
                )?.data;

                if (stockHistory) {
                  const now = DateTime.now();
                  const weekStartData: StockDay | undefined = findNearestOlderRecordByDate(
                    now.startOf('week').minus({days: 1}),
                    stockHistory
                  );
                  const monthStartData: StockDay | undefined = findNearestOlderRecordByDate(
                    now.startOf('month').minus({days: 1}),
                    stockHistory
                  );
                  const yearStartData: StockDay | undefined = findNearestOlderRecordByDate(
                    now.startOf('year').minus({days: 1}),
                    stockHistory
                  );
                  const minus90DaysData: StockDay | undefined = findNearestOlderRecordByDate(
                    now.minus({days: 91}),
                    stockHistory
                  );
                  const price = stock.regularMarketPrice;
                  if (weekStartData)
                    stockHistoryInfo += `Start of week: ${formatHistoricalStockValue(price, weekStartData.close)}\n`;
                  if (monthStartData)
                    stockHistoryInfo += `Start of month: ${formatHistoricalStockValue(price, monthStartData.close)}\n`;
                  if (yearStartData)
                    stockHistoryInfo += `Start of year: ${formatHistoricalStockValue(price, yearStartData.close)}\n`;
                  if (minus90DaysData)
                    stockHistoryInfo += `90 days ago: ${formatHistoricalStockValue(price, minus90DaysData.close)}\n`;
                }
              }

              return (
                <tbody key={i}>
                  {/* on click to row minitable is showed */}
                  <tr
                    className="align-middle"
                    onClick={() => {
                      showMiniTableDetailsClick(stockSymbol);
                    }}
                  >
                    {/* SYMBOL */}
                    <td className="col-3 text-center">
                      <a
                        target="blank"
                        href={'https://finance.yahoo.com/quote/' + stockSymbol}
                        className={searchStockInBuys(stockSymbol) ? 'fw-bold' : 'fw-normal'}
                      >
                        {stock.symbol}
                      </a>
                    </td>
                    {/* COMPANY */}
                    <td className="text-center">
                      {stock.displayName ? stock.displayName : findNameOfStockFromSymbol(stockSymbol)}
                    </td>
                    {/* PRICE */}
                    <td className="text-end fw-bold" title={stockHistoryInfo}>
                      ${Math.round(stock.regularMarketPrice * 100) / 100}
                      <span
                        className={
                          stock.regularMarketPrice - stock.regularMarketPreviousClose > 0
                            ? 'text-success'
                            : 'text-danger'
                        }
                      >
                        {stock.regularMarketPrice - stock.regularMarketPreviousClose > 0 ? ' ↑' : ' ↓'}
                      </span>
                    </td>
                    {/* PREV. CLOSE */}
                    <td className="text-end text-secondary small" title={stockHistoryInfo}>
                      ${Math.round(stock.regularMarketPreviousClose * 100) / 100}
                    </td>
                    {/* % */}
                    <td
                      className={
                        'text-end fw-bold text-' + (countTotalPercent(stockSymbol) >= 0 ? 'success' : 'danger')
                      }
                    >
                      {countTotalPercent(stockSymbol) ? Math.round(countTotalPercent(stockSymbol)) + ' %' : ''}
                    </td>
                    {/* TOTAL PROFIT */}
                    <td
                      className={
                        'text-end fw-bold ' + (countTotalProfit(stockSymbol) > 0 ? 'text-success' : 'text-danger')
                      }
                    >
                      {countTotalProfit(stock.symbol) !== 0
                        ? prettyUSDPlusMinusNumber(countTotalProfit(stockSymbol))
                        : ''}
                    </td>
                    {/* IN CZK / DELETE button */}
                    <td className="small text-center text-secondary">
                      {countTotalProfit(stock.symbol) !== 0 && czk ? (
                        prettyCZKPlusMinusNumber(countTotalProfit(stockSymbol) * czk)
                      ) : (
                        <MinusDeleteButton
                          visibility={setupMode && !searchStockInBuys(stockSymbol)}
                          doOnClick={() => removeStockClick(stockSymbol)}
                          label="Delete"
                        />
                      )}
                    </td>
                  </tr>
                  {/* row with minitable  */}
                  <tr
                    onClick={() => {
                      showMiniTableDetailsClick(stockSymbol);
                    }}
                  >
                    {/* ADD BUY button*/}
                    <td>
                      {setupMode ? (
                        <PlusAddButton visibility={true} doOnClick={() => showModalBuyClick(stockSymbol)} label="Buy" />
                      ) : (
                        ''
                      )}
                    </td>
                    <td colSpan={5}>
                      <StocksBuys
                        stocksBuy={stocksBuy}
                        stock={stock}
                        detailsShowing={detailsShowing}
                        setupMode={setupMode}
                        netProfitUSD={netProfitUSD}
                        prettyUSDPlusMinusNumber={prettyUSDPlusMinusNumber}
                        czk={czk}
                        prettyCZKPlusMinusNumber={prettyCZKPlusMinusNumber}
                        deleteBuyClick={deleteBuyClick}
                        editBuyClick={editBuyClick}
                      />
                    </td>
                  </tr>
                </tbody>
              );
            })}
          </>
        )}
      </Table>
    </>
  );
}
export default StocksSymbolsTable;
