import {useState, useEffect} from 'react';

/**
 * Returns value as found in local storage
 * @param {string} key of the item
 * @param {string} defaultValue value to return if item is missing
 * @return {object} found item or
 */
function getStorageValue(key: string, defaultValue: unknown = null) {
  // getting stored value
  const saved = localStorage.getItem(key);
  if (saved === null) return defaultValue;
  let initial;
  try {
    initial = JSON.parse(saved);
  } catch (e) {}
  if (initial === undefined) return defaultValue;
  return initial;
}

export interface LocalStorageHookResponse {
  value: unknown;
  setValue: React.Dispatch<unknown>;
}

/**
 * Custom hook providing access to browser's local storage
 * @param {string} key to the value
 * @param {object} defaultValue to
 * @return {{}} {value, setValue}
 */
export function useLocalStorage(key: string, defaultValue: unknown = null): LocalStorageHookResponse {
  const [value, setValue] = useState<unknown>(() => {
    return getStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    localStorage.setItem(key, JSON.stringify(value));
    // console.log(`useLocalStorage() setting value to local storage [${key}] = `, value);
  }, [key, value]); // run when key or value changes

  return {value, setValue} as const;
}
