import {useEffect, useState} from 'react';
import {Colors} from '../../constants/colors';
import {REQUEST_STATUS, useExchangeRates} from '../../hooks/useExchangeRates';
import {styles} from '../../constants/styles';

/**
 * vykreslení Inputu v záhlaví tabulky, 1. řádek tabulky kalkulačky,
 * @param {any} props
 * @return {thead} - 1. row of calculator table with imput
 */
function RadekSInputem(props: any) {
  return (
    <thead>
      <tr>
        <th colSpan={5}>
          <input
            style={{
              width: '100%',
              backgroundColor: Colors.grey,
              border: 0,
              borderRadius: 8,
              height: styles.calculatorBasicDisplayHeight,
              color: Colors.white,
              fontSize: 30,
              textAlign: 'end',
              padding: 16,
            }}
            onChange={props.zmena}
            value={props.nastavHodnotu}
            data-testid="test-display"
          />
        </th>
      </tr>
    </thead>
  );
}
/**
 * vykreslení tlačítka
 * @param {any} props
 * @return {td} - 1 tlačítko je td s inputem
 */
function Tlacitko(props: any) {
  const numbers = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
  const operators = ['+', '-', '×', '÷', '='];

  return (
    <td id={'td' + props.ulozenaHodnota}>
      <input
        style={{
          width: styles.calculatorButtonWidth,
          height: styles.calculatorButtonHeight,
          borderRadius: styles.calculatorButtonBorderRadius,
          backgroundColor: numbers.includes(props.ulozenaHodnota)
            ? Colors.primary
            : operators.includes(props.ulozenaHodnota)
            ? Colors.secondary
            : Colors.white,
          borderColor: numbers.includes(props.ulozenaHodnota)
            ? Colors.white
            : operators.includes(props.ulozenaHodnota)
            ? Colors.white
            : Colors.white,
          color: numbers.includes(props.ulozenaHodnota)
            ? Colors.white
            : operators.includes(props.ulozenaHodnota)
            ? Colors.white
            : Colors.secondary,
          borderWidth: 1,
          fontSize: 20,
        }}
        type="button"
        className="button"
        id={props.ulozenaHodnota}
        onClick={props.ulozenaZmena}
        value={props.ulozenaHodnota}
      />
    </td>
  );
}
/**
 * vykreslení řádku s tlačítky
 * @param {any} props
 * @return {tbody} - 1 řádek kalkulačky
 */
function RadekSTlacitky(props: any) {
  return (
    <tbody>
      <tr>
        <Tlacitko ulozenaHodnota={props.symboly[0]} ulozenaZmena={props.zmena} />
        <Tlacitko ulozenaHodnota={props.symboly[1]} ulozenaZmena={props.zmena} />
        <Tlacitko ulozenaHodnota={props.symboly[2]} ulozenaZmena={props.zmena} />
        <Tlacitko ulozenaHodnota={props.symboly[3]} ulozenaZmena={props.zmena} />
        <Tlacitko ulozenaHodnota={props.symboly[4]} ulozenaZmena={props.zmena} />
      </tr>
    </tbody>
  );
}
/**
 * Calculator component
 * @return {React.FC} - komponenta kalkulačky - tabulka
 */
function CalculatorBasic() {
  // State vlastnosti:
  const [cisloZapamatuj, setCisloZapamatuj] = useState(0); // zapamatuje 1. číslo pro operace +, -, x, /, %, ...
  const [operace, setOperaci] = useState(''); // uklada zadanou operaci +, -, x, /, %, ...
  const [cisloInput, setCisloInput] = useState('0'); // uklada string z inputu a propisuje ho tam zpět
  const [noveCislo, setNoveCislo] = useState(true); // true  vymaže input pro zadání nového čísla, např. po stisku +, -, x, / ...
  const [pamet, setPamet] = useState(0); // drží císelnou hodnotu paměti M+, M-, MRC...
  const [posledniKlavesa, setPosledniKlavesa] = useState(''); // drží poslední stisknutou klávesu pro porovnání opětovného stisknutí MRC
  const [radek1] = useState(['MRC', 'M-', 'M+', '%', 'C']);
  const radek2Part1: (number | string)[] = [7, 8, 9];
  const radek2Part2: string[] = ['$', '€'];
  const [radek2, setRadek2] = useState([...radek2Part1, ' ', ' ']);
  const [radek3] = useState([4, 5, 6, '-', '÷']);
  const [radek4] = useState([1, 2, 3, '+', '×']);
  const [radek5] = useState([0, '.', '±', '√', '=']);
  // main custom hook state with status and data
  const [exchangeRatesStatus, exchangeRatesStatusData] = useExchangeRates('CZK');
  // console.log('useExchangeRatesstatus:', exchangeRatesStatus, 'data:', exchangeRatesStatusData);

  // set the values
  useEffect(() => {
    // once exchange rate is available add euro + dollar buttons
    if (exchangeRatesStatus === REQUEST_STATUS.SUCCESS) {
      setRadek2([...radek2Part1, ...radek2Part2]);
    }
  }, [exchangeRatesStatus]);

  /**
   * Calculator reset - zresetuje vše na počáteční stav
   */
  function zresetuj() {
    setCisloZapamatuj(0); // zapamatuje předchozí číslo
    setOperaci(''); // uklada operci
    setCisloInput('0'); // uklada string z inputu a propisuje ho tam zpět
    setNoveCislo(true);
    setPamet(0);
  }

  /**
   * Calculator reset - zresetuje stav pro zadání nového čísla
   */
  function zresetujBezInputu() {
    setCisloZapamatuj(0); // zapamatuje předchozí číslo
    setOperaci(''); // uklada operci
    setNoveCislo(true);
  }

  /**
   *  uloží hodnotu z inputu do "cisloInput", ktery se propíše zpátky do inputu
   * @param {any} e
   */
  function reagujNaZmenuZInputu(e: any) {
    setCisloInput(e.target.value);
  }

  /**
   *  uloží parametr do state "cisloInput", ktery se propíše do inputu
   * @param {string} cislo - uloží do state číslo z tlačítka kalkulačky
   */
  function propisNecoDoInputu(cislo: string) {
    setCisloInput(cislo);
  }

  /**
   *  vyhodnotí co dělat po stisknutí buttonu
   * @param {any} e
   */
  function reagujNaClickZButtonu(e: any) {
    const hodnotaTlacitka = e.target.id;
    const inp = parseFloat(cisloInput);

    /**
     * uloží state operace
     */
    function ulozOperaci() {
      setCisloZapamatuj(inp); // zapamatuje vstup
      setOperaci(hodnotaTlacitka); // uloží operaci
      setNoveCislo(true); // zresetuje input
    }

    // funkcionalita po stisku jednotlivých kláves
    switch (hodnotaTlacitka) {
      case 'MRC':
        // pokud zmackneme MRC 2x po sobe, tak se vymaže
        if (posledniKlavesa === 'MRC') {
          zresetuj();
        }
        // jinak zobrazí svou hodnotu
        else {
          propisNecoDoInputu(pamet.toString());
          setNoveCislo(true); // zresetuje input
        }
        break;
      case 'M-':
        setPamet(pamet - inp);
        setNoveCislo(true); // zresetuje input
        break;
      case 'M+':
        setPamet(pamet + inp);
        setNoveCislo(true); // zresetuje input
        break;
      case '%':
        ulozOperaci();
        break;
      case 'C':
        zresetuj();
        break;
      case '√':
        setCisloInput(Math.sqrt(inp).toString());
        setNoveCislo(true); // zresetuje input
        break;
      case '€': // neznámá fce
        const czkEur = exchangeRatesStatusData?.rates?.['EUR'] ?? 0;
        const eurCzk = Math.round(100 * (1 / czkEur)) / 100.0;
        propisNecoDoInputu('' + eurCzk);
        setNoveCislo(true);
        break;
      case '+':
        ulozOperaci();
        break;
      case '÷':
        ulozOperaci();
        break;
      case '-':
        ulozOperaci();
        break;
      case '×': // krát
        ulozOperaci();
        break;
      case '±':
        propisNecoDoInputu((-inp).toString());
        setNoveCislo(true); // zresetuje input
        break;
      case '$': // neznámá fce
        try {
          const czkUsd = exchangeRatesStatusData?.rates?.['USD'] ?? 0;
          const usdCzk = Math.round(100 * (1 / czkUsd)) / 100.0;
          propisNecoDoInputu('' + usdCzk);
          setNoveCislo(true);
        } catch {}
        // code block
        break;
      case '=':
        // sčítání
        if (operace === '+') {
          propisNecoDoInputu((cisloZapamatuj + inp).toString());
          zresetujBezInputu();
        } // odčítání
        else if (operace === '-') {
          propisNecoDoInputu((cisloZapamatuj - inp).toString());
          zresetujBezInputu();
        } // násobení
        else if (operace === '×') {
          propisNecoDoInputu((cisloZapamatuj * inp).toString());
          zresetujBezInputu();
        } // dělení
        else if (operace === '÷') {
          if (inp !== 0) {
            propisNecoDoInputu((cisloZapamatuj / inp).toString());
          }
          zresetujBezInputu();
        } else if (operace === '%') {
          propisNecoDoInputu(((cisloZapamatuj / 100) * inp).toString());
          zresetujBezInputu();
        } else propisNecoDoInputu('error');
        break;
      default: // 1,2,3,4,5,6,7,8,9,0,".",
        // zresetuje input
        if (noveCislo) {
          // true
          setCisloInput(hodnotaTlacitka); // začne psát odznova, stisknuté tlačítko se propíše do inputu
          setNoveCislo(false);
        } // uloží hodnotu z tlačítka a state uloží do inputu
        else {
          setCisloInput(cisloInput + hodnotaTlacitka);
        }
    }
    setPosledniKlavesa(hodnotaTlacitka);
  }
  // vykreslí kalkulačku
  return (
    <div id="Calculator" style={{margin: 16}}>
      <table>
        <RadekSInputem zmena={reagujNaZmenuZInputu} nastavHodnotu={cisloInput} />
        <RadekSTlacitky zmena={reagujNaClickZButtonu} symboly={radek1} />
        <RadekSTlacitky zmena={reagujNaClickZButtonu} symboly={radek2} />
        <RadekSTlacitky zmena={reagujNaClickZButtonu} symboly={radek3} />
        <RadekSTlacitky zmena={reagujNaClickZButtonu} symboly={radek4} />
        <RadekSTlacitky zmena={reagujNaClickZButtonu} symboly={radek5} />
      </table>
    </div>
  );
}

export default CalculatorBasic;
