import {useAuth} from 'reactfire';
import {Auth, GoogleAuthProvider, signInWithPopup} from 'firebase/auth';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

/**
 * Sign in with Google provider. Brings up a Google pop-up sign-in dialog.
 * @param {Auth} auth Firebase auth
 */
const signInGoogle = async (auth: Auth) => {
  // When configuring custom domain with Firebase, there are multiple things you have to do
  // @see https://firebase.google.com/docs/auth/web/google-signin?authuser=0#expandable-4
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
  // reload site so caches are purged and new user's Firebase context is clean
  location.reload();
};

export interface ModalSignInDialogProps {
  show: boolean;
  onHide: () => void;
}

/** Modal login dialog
 * @param {boolean} show Should the dialog be visible?
 * @param {function} onHide Callback invoked when dialog should be closed i.e. 'show' set to false
 * @return {React.FC} JSX
 */
export function ModalSignInDialog({show, onHide}: ModalSignInDialogProps) {
  const auth = useAuth();
  //   console.log('ModalSignInDialog: ', show);
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Sign In</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center">
          <div className="row">
            <div className="col-md-12">
              <Button
                className="btn"
                variant="outline-secondary"
                onClick={() => {
                  signInGoogle(auth);
                  onHide();
                }}
              >
                {' '}
                <img
                  width="20px"
                  style={{marginBottom: '3px', marginRight: '5px'}}
                  alt="Google sign-in"
                  src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
                />
                Login with Google
              </Button>
              {/* <a className="btn btn-outline-dark" href="/users/googleauth" role="button"></a> */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Cancel
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

/** Login dialog
 * @return {React.FC} JSX
 */
export function SignInDialog() {
  const auth = useAuth();
  //   console.log('SignInDialog: ', show);
  return (
    <>
      <p>Sign in to proceed to RIS...</p>
      <p></p>
      <div className="d-flex justify-content-center">
        <div className="row">
          <div className="col-md-12">
            <Button
              className="btn"
              variant="outline-secondary"
              onClick={() => {
                signInGoogle(auth);
              }}
            >
              {' '}
              <img
                width="20px"
                style={{marginBottom: '3px', marginRight: '5px'}}
                alt="Google sign-in"
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/512px-Google_%22G%22_Logo.svg.png"
              />
              Login with Google
            </Button>
            {/* <a className="btn btn-outline-dark" href="/users/googleauth" role="button"></a> */}
          </div>
        </div>
      </div>
    </>
  );
}
