/**
 * Demo page - no real functionality
 * @return {React.FC}
 */
function DemoPage2() {
  return (
    <div>
      <h1>Default Page 2</h1>
    </div>
  );
}

export default DemoPage2;
