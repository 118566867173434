import {useState, useEffect, useContext} from 'react';
import {useLocalStorage} from './useLocalStorage';
import {UserSettingsContext, UserSettingsContextHook} from '../context/UserSettingsProvider';

/**
 * Custom hook providing user settings persistence.
 * @param {string} settingsKey Key of the settings. Each component/use-case is supposed to have arbitrary key.
 * @return {{}} { settings, setSettings }
 */
export function useSettings(settingsKey: string) {
  const [started, setStarted] = useState(false);
  const [settings, setSettings] = useState<unknown>();
  const userSettingsServerHook: UserSettingsContextHook | null = useContext(UserSettingsContext); // get references to only what is used here
  const userSettingsOnServer = userSettingsServerHook?.getDataRefByKey(settingsKey);
  const {value: valueInLocalStorage, setValue: setValueInLocalStorage} = useLocalStorage('config-' + settingsKey);

  // effect to fetch data at the start
  useEffect(() => {
    if (started) return; // run only once
    // console.log(`useSettings([${settingsKey}]): PRE userSettingsOnServer = `, JSON.stringify(userSettingsOnServer));
    if (!userSettingsOnServer || !userSettingsOnServer?.isInitialized) return; // skip init states
    // console.log(
    //   `useSettings([${settingsKey}]): POST userSettingsOnServer = `,
    //   JSON.stringify(userSettingsOnServer?.data)
    // );
    // data is loaded, never run this again, all other changes to 'settings' would be initiated from outside on page's request
    setStarted(true);
    // console.log(`useSettings([${settingsKey}]): loading initial data from db/storage...`);

    // data on server override local data
    const settingOnServer = userSettingsOnServer.data;
    const data = settingOnServer ? settingOnServer : valueInLocalStorage;
    // console.log(`useSettings([${settingsKey}]): valueInLocalStorage = `, JSON.stringify(valueInLocalStorage));
    // console.log(`useSettings([${settingsKey}]): userSettingsOnServer = `, JSON.stringify(settingOnServer));
    // console.log(`useSettings([${settingsKey}]): data = `, JSON.stringify(data));
    // set data to state
    setSettings(() => data);
  }, [userSettingsOnServer]);

  // effect to store data upon changes
  useEffect(() => {
    const doRun = async () => {
      if (settings === undefined || settings === null) return; // skip init states
      // console.log(`useSettings([${settingsKey}])): settings changed to = `, settings);
      // set value to local storage
      setValueInLocalStorage(settings);
      // set value to remote storage
      if (userSettingsOnServer?.isInitialized) {
        // console.log(`useSettings([${settingsKey}])): setData() settings = `, settings);
        await userSettingsOnServer.setData(settings);
      }
    };
    doRun();
  }, [settings]);

  // return state keeping the user settings data for the application
  return {settings, setSettings} as const;
}
