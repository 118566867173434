import {Colors} from '../../constants/colors';
import {styles} from '../../constants/styles';

export const Card = ({bgColor, borderColor, width, children, minHeight}: any) => {
  return (
    <>
      <div
        style={{
          borderColor: borderColor ?? Colors.grey,
          border: '0.5px solid',
          margin: 16,
          padding: 16,
          width: width ?? styles.commonCardWidth,
          backgroundColor: bgColor ?? Colors.grey,
          borderRadius: 8,
          color: Colors.secondary,
          textAlign: 'center',
          minHeight: minHeight,
          maxWidth: '92%',
        }}
      >
        {children}
      </div>
    </>
  );
};
