import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faWrench} from '@fortawesome/free-solid-svg-icons';

/** Settings */
interface DoOnClickFunc {
  (): void;
}
interface ButtonProps {
  visibility: boolean;
  doOnClick: DoOnClickFunc;
  label: string;
}
/**
 * @param {ButtonProps} props
 * @return {React.FC}
 */
function SettingsButton(props: ButtonProps) {
  return (
    <button
      style={props.visibility ? {visibility: 'visible'} : {visibility: 'hidden'}}
      type="button"
      className="btn btn-outline-primary"
      onClick={event => {
        event.stopPropagation();
        props.doOnClick();
      }}
    >
      <FontAwesomeIcon icon={faWrench} />
      {props.label}
    </button>
  );
}

export default SettingsButton;
