import {doc} from 'firebase/firestore';
// import { initializeFirestore, doc, collection, enableIndexedDbPersistence, increment, updateDoc,
//  orderBy, query, addDoc, DocumentData, setDoc } from 'firebase/firestore';
import {useFirestore, useFirestoreDocData, useSigninCheck} from 'reactfire';

const CACHE_COLLECTION_NAME = 'cache';

/**
 * Custom hook providing access to documents in application data cache shared from server. Firestore collection of signed-in user's documents.
 * REQUIRES FirestoreProvider configured in the hierarchy above.
 * @param {string} docName Name of the document in the '/cache' collection
 * @return {{}} { status, data }
 */
export function useFirestoreCacheDocument(docName: string) {
  const firestore = useFirestore();
  // console.log('useFirestoreDocument(): firestore.app.projectId=' + JSON.stringify(firestore.app.options?.projectId));
  const {data: signInCheckResult} = useSigninCheck();
  const signedIn = signInCheckResult?.signedIn === true;
  // don't even try unauthorized
  if (!signedIn) return {status: 'error', data: null};

  // subscribe to a document for realtime updates
  // Firestore document specific to user
  // console.log('useFirestoreUserDocument(): path=', 'users', uid, PARENT_COLLECTION_NAME, docName);
  const docRef = doc(firestore, CACHE_COLLECTION_NAME, docName);

  // console.log('useFirestoreUserDocument(): about to read data...');
  const {status, data} = useFirestoreDocData(docRef);
  // console.log('useFirestoreUserDocument(): data=', data);s

  return {status, data};
}
