import {createContext} from 'react';
import {useSigninCheck} from 'reactfire';
import {useFirestoreUserDocument} from '../hooks/useFirestoreUserDocument';
import Props from '../types/Props';

export interface UserSettingsItem {
  key: string;
  value: string;
}

export interface UserSettingsContextData {
  readonly isInitialized: boolean;
  readonly data: UserSettingsItem[];
  setData: (value: any) => void;
}

/** Hook to get access to user settings under given key */
export interface UserSettingsContextHook {
  getDataRefByKey: (key: string) => UserSettingsContextData;
}

/** Context */
export const UserSettingsContext = createContext<UserSettingsContextHook | null>(null);

/**
 * Provider of user settings persistence to children underneath
 * REQUIRES Firebase.Auth context above
 * @param {Props} children
 * @return {React.ReactNode} elements provided with Firebase services
 */
export function UserSettingsProvider({children}: Props): React.ReactElement {
  // get current auth user
  const {status, data: signInCheckResult} = useSigninCheck();

  const hook: UserSettingsContextHook = {
    getDataRefByKey: (key: string) => {
      // console.log(`UserSettingsContextProvider: getDataRefByKey([${key}]) running...`);
      // get access to Firestore db doc
      const fsDoc = useFirestoreUserDocument('config-' + key);
      return {
        isInitialized: fsDoc.status === 'success',
        data: fsDoc.data?.data as UserSettingsItem[], // unwrap .data property from object envolope so we can handle 'array' as config payload
        setData: async value => {
          await fsDoc.setData({data: value}); // put .data property into an object envelope so we can handle 'array' as config payload
        },
      };
    },
  };

  let contextHook: UserSettingsContextHook | null = null;
  if (status === 'success' && signInCheckResult.signedIn) {
    contextHook = hook;
  }
  return <UserSettingsContext.Provider value={contextHook}>{children}</UserSettingsContext.Provider>;
}
