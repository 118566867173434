import Table from 'react-bootstrap/Table';
import {REQUEST_STATUS} from '../types/requestStatus';

type displVal = {
  stockDataStatus: REQUEST_STATUS;
  netProfitUSDAll: () => number;
  prettyUSDPlusMinusNumber: (n: number) => string;
  netBuyValueNowAll: () => number;
  netBuyValueBeforeAll: () => number;
  prettyPercentPlusMinusNumber: (n: number) => string;
  czk: number | undefined;
  prettyCZKPlusMinusNumber: (n: number) => string;
};

/** Modal Select drop down with all available stock symbols
 * @return {React.FC} JSX
 */
function StocksPortfolio({
  stockDataStatus,
  netProfitUSDAll,
  prettyUSDPlusMinusNumber,
  netBuyValueNowAll,
  netBuyValueBeforeAll,
  prettyPercentPlusMinusNumber,
  czk,
  prettyCZKPlusMinusNumber,
}: displVal) {
  const isLoading = !(stockDataStatus === REQUEST_STATUS.SUCCESS); // && netProfitUSDAll() !== 0;
  return (
    <>
      <Table>
        <thead>
          <tr className="fw-bold text-secondary small">
            <th className="col-1">
              <h4>Portfolio</h4>
            </th>
            <th className="col-3"> </th>
            <th className="col-2 text-end">NET PROFIT </th>
            <th className="col-2 text-center">PERCENTAGE </th>
            <th className="col-3 text-end">TOTAL VALUE</th>
          </tr>
        </thead>
        <tbody>
          {!isLoading ? (
            <>
              <tr>
                <td></td>
                <td></td>
                {/* NET PROFIT */}
                <td
                  className={
                    'align-bottom text-end fw-bold ' + (netProfitUSDAll() >= 0 ? 'text-success' : 'text-danger')
                  }
                >
                  {prettyUSDPlusMinusNumber(netProfitUSDAll())}
                </td>
                {/* % PERCENTAGE */}
                <td
                  className={
                    'align-bottom text-center fw-bold ' + (netProfitUSDAll() >= 0 ? 'text-success' : 'text-danger')
                  }
                >
                  {netBuyValueNowAll() !== 0
                    ? prettyPercentPlusMinusNumber(netBuyValueNowAll() / (netBuyValueBeforeAll() / 100) - 100)
                    : '0%'}
                </td>
                {/* TOTAL VALUE */}
                <td className="text-end text-secondary fw-bold display-6">
                  {prettyUSDPlusMinusNumber(netBuyValueNowAll())}
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                {/* NET PROFIT */}
                <td className="small text-end text-secondary">
                  {czk ? prettyCZKPlusMinusNumber(netProfitUSDAll() * czk) : ''}
                </td>
                {/* % PERCENTAGE */}
                <td></td>
                {/* TOTAL VALUE */}
                <td className="small text-end text-secondary fw-bold">
                  {czk ? prettyCZKPlusMinusNumber(netBuyValueNowAll() * czk) : ''}
                </td>
              </tr>
            </>
          ) : (
            <tr>
              <td></td>
              <td></td>
              <td>...loading...</td>
              <td></td>
              <td></td>
            </tr>
          )}
        </tbody>
      </Table>
    </>
  );
}

export default StocksPortfolio;
