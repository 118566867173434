import {DateTime} from 'luxon';

interface DateProps {
  dateStringIso: string | undefined;
  text: string | undefined;
}
/**
 * function writes czech date: "Út 2.8.2022"
 * @param {DateProps} props dateStringIso
 * @return {React.FC}
 */
function PrettyCzDate(props: DateProps) {
  // exchangeRatesStatusData?.timestampIso
  const niceDateFunc = () => {
    if (!props.dateStringIso) return '';
    const dt = DateTime.fromISO(props.dateStringIso);
    // return dt.setLocale('cs').toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY); // hardcoded 'cs' locale => 'po 1. 8. 2022 2:00'
    return dt.toLocaleString(DateTime.DATE_FULL); // => by browser locale 'Mon, Aug 1, 2022
  };
  return (
    <small style={{color: 'grey'}}>
      {props.text} {niceDateFunc()}
    </small>
  );
}
export default PrettyCzDate;
