/* eslint-disable no-unused-vars */

/**
 * Request status used with async resource loading
 */
export enum REQUEST_STATUS {
  LOADING = 'loading',
  SUCCESS = 'success',
  FAILURE = 'failure',
}
