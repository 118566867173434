import {useState} from 'react';
import {useSigninCheck, useAuth} from 'reactfire';
import Button from 'react-bootstrap/Button';
import ButtonGroup from 'react-bootstrap/ButtonGroup';
import Dropdown from 'react-bootstrap/Dropdown';
import {faUser} from '@fortawesome/free-solid-svg-icons';
import {LoadingSpinner} from '../../auth/LoadingSpinner';
import {ModalSignInDialog} from '../../auth/Login';
import {IconItem} from '../shared/IconItem';
import {UserIconItem} from '../shared/UserIconItem';

/**
 * Shows sign in user and actions related to auth
 * @return {React.FC} JSX
 */
export function SignInNavigationBarItem() {
  const [showModalSignInDialog, setShowModalSignInDialog] = useState(false); // show modal dialog or not
  const {status, data: signInCheckResult} = useSigninCheck();
  const auth = useAuth();

  if (status === 'loading') {
    return <LoadingSpinner />;
  }

  const signedIn = signInCheckResult.signedIn === true;
  const userName = signInCheckResult?.user?.displayName || 'user';

  const UnsignedUser = () => {
    return (
      <>
        <Dropdown as={ButtonGroup}>
          <Button
            variant="transparent"
            onClick={() => {
              setShowModalSignInDialog(true);
            }}
          >
            <IconItem icon={faUser} label="Sign In" />
          </Button>
        </Dropdown>
        <ModalSignInDialog
          show={showModalSignInDialog}
          onHide={() => {
            setShowModalSignInDialog(false);
          }}
        />
      </>
    );
  };
  const userAvatarUrl = signInCheckResult?.user?.photoURL ?? null;
  const authProvider = signInCheckResult?.user?.providerId; // e.g. 'firebase'

  const SignedUserA = () => {
    return (
      <Dropdown style={{flexDirection: 'column'}}>
        <Dropdown.Toggle split variant="transparent" id="dropdown-split-basic">
          <UserIconItem iconUrl={userAvatarUrl} label={userName} />
        </Dropdown.Toggle>
        <Dropdown.Menu>
          <Dropdown.Item disabled>{'Signed in as ' + userName}</Dropdown.Item>
          <Dropdown.Item disabled>{'via ' + authProvider}</Dropdown.Item>
          <Dropdown.Item
            onClick={() => {
              auth.signOut().then(() => console.log('signed out'));
            }}
          >
            Sign out
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  return signedIn ? <SignedUserA /> : <UnsignedUser />;
}
