import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {useWindowSize} from '../hooks/useWindowSize';
import Props from '../types/Props';

/**
 * Application layout component reponsible for laying out main screen components and handle screen rotation and similar.
 * Shows page components in proper layout
 * @return {React.FC}
 */
export function AppLayout({children}: Props): React.ReactElement {
  // use custom hook to get us updated when browser's window changes
  const windowSizeInfo = useWindowSize();

  if (!children) return <></>;

  // landscape mode - single row, multiple columns
  if (windowSizeInfo.isLandscapeMode) {
    return (
      <Container fluid>
        <Row>
          {React.Children.map(children, child => (
            <Col>{child}</Col>
          ))}
        </Row>
      </Container>
    );
  }
  // portrait mode - single column, multiple rows
  return (
    <Container fluid>
      <Col>
        {React.Children.map(children, child => (
          <Row>{child}</Row>
        ))}
      </Col>
    </Container>
  );
}
