// import {useContext} from 'react';
import {useSigninCheck} from 'reactfire';
// import {UserSettingsContext, UserSettingsContextData} from '../context/UserSettingsProvider';
import {Stack, Button} from 'react-bootstrap';
import {useFirestoreUserDocument} from '../hooks/useFirestoreUserDocument';
import {useFirestoreUserCollection} from '../hooks/useFirestoreUserCollection';
import ErrorBoundary from './ErrorBoundary';
import {useFirestoreCacheDocument} from '../hooks/useFirestoreCacheDocument';

/**
 * Demo Firestore functionality
 * @return {React.FC}
 */
function DemoFirestore() {
  const {status, data, setData, clearData} = useFirestoreUserDocument('test-DemoPage1');
  const {status: cacheStatus, data: cacheData} = useFirestoreCacheDocument('cache-stock-historical');
  // check how's user settings document
  const {
    // status: fsStatus,
    data: fsData,
  } = useFirestoreUserCollection();
  return (
    <ul>
      <li key="1">Status: {status}</li>
      <li key="2">Data: {'' + (data ? JSON.stringify(data) : '-')}</li>
      <li key="3">
        <button onClick={() => setData({date: new Date().toISOString(), message: 'ahoj'})}>Set Data</button>
        <button onClick={() => clearData()}>Clear Data</button>
      </li>
      <li key="4">
        User Settings:{' '}
        <code>
          <pre>{'' + (fsData ? JSON.stringify(fsData, null, 2) : '-')}</pre>
        </code>
      </li>
      {/* <li key="5">
        <button onClick={() => fsSetData({date: new Date().toISOString(), message: 'ahoj'})}>Set Settings Data</button>
        <button onClick={() => fsClearData()}>Clear Settings</button>
      </li> */}
      <li key="6">Cache Status: {cacheStatus}</li>
      <li key="7">
        Cache historical stock:
        <code>
          <pre>Data: {'' + (cacheData ? JSON.stringify(cacheData) : '-')}</pre>
        </code>
      </li>
    </ul>
  );
}

/**
 * Demo page - no real functionality
 * @return {React.FC}
 */
function DemoPage1() {
  const sirka = window.innerWidth;
  const vyska = window.innerHeight;
  const naSirku = sirka >= vyska;
  const tlacitka = ['prvni', 'druhy', 'treti', 'ctvrty'];

  // const userSettings: UserSettingsContextData | null = useContext(UserSettingsContext); // get references to only what is used here
  const {status, data: signInCheckResult} = useSigninCheck();
  return (
    <>
      <div>
        <h3>User:</h3>
        <div>Auth status: {status}</div>
        {status === 'success' && (
          <ul>
            <li key="1">Signed in: {'' + signInCheckResult.signedIn}</li>
            <li key="2">User info: {signInCheckResult.user?.displayName ?? '-'}</li>
            <li key="3">User id: {signInCheckResult.user?.uid ?? '-'}</li>
            <li key="4">
              User e-mail: {signInCheckResult.user?.email ?? '-'} (
              {signInCheckResult.user?.emailVerified ? 'verified' : 'unverified'})
            </li>
          </ul>
        )}

        <h3>Firestore Test:</h3>
        <ErrorBoundary>{signInCheckResult?.signedIn === true ? <DemoFirestore /> : 'Login first'}</ErrorBoundary>

        <h3>User Settings:</h3>

        {/* <div>User Settings context: {userSettings ? JSON.stringify(userSettings) : 'no user settings'}</div> */}

        <h3>{naSirku ? 'Na sirku' : 'Na vysku'}</h3>
        <div>
          vyska = {vyska}, sirka = {sirka}
        </div>
        <Stack direction={naSirku ? 'horizontal' : 'vertical'} className="col-xl-1 col-md-2 offset-1" gap={1}>
          <Stack direction={naSirku ? 'vertical' : 'horizontal'} gap={1}>
            {tlacitka.map((t, index) => (
              <Button key={index} variant="primary">
                {t}
              </Button>
            ))}
          </Stack>
          <Button variant="primary">OK</Button>
          <Button variant="primary">Dobre</Button>
        </Stack>
      </div>
    </>
  );
}

export default DemoPage1;
