import {ErrorMessage, Formik} from 'formik';
import {useState} from 'react';
import {Button, InputGroup, Form} from 'react-bootstrap';

/**
 * @return {React.FC}
 */
function Mortgage() {
  const [result, setResult] = useState({k: 0, p: 0, n: 0, s: 0, total: 0, minProperty: 0});

  const doRound = (a: number) => {
    return Math.round(a);
  };
  const countClick = (values: {ownAmount1: any; ownAmount2: any; ownAmount3: any}) => {
    if (values.ownAmount1 === 0 || values.ownAmount2 === 0 || values.ownAmount3 === 0) return;

    const k = values.ownAmount1;
    const p = values.ownAmount2 / 12; // /12 splátek za rok
    const n = values.ownAmount3 * 12; // *12 splátek za rok
    const r = 1 + p / 100;
    const myResult = k * (Math.pow(r, n) * ((r - 1) / (Math.pow(r, n) - 1)));
    const myTotal = (myResult * 12 * n) / 12;
    const minValueOfProperty = (k / 85) * 100;

    const obj = {
      k: k,
      p: p,
      n: n,
      s: doRound(myResult),
      total: doRound(myTotal),
      minProperty: doRound(minValueOfProperty),
    };
    setResult(obj);
  };
  /**
   * @return {React.FC}
   */ function ValueOfDebt() {
    let debt = result.k;
    const arr = [];
    while (debt > 0) {
      const aktInterest = (debt / 100) * result.p;
      const instalment = result.s;
      const instalmentOfDebtInAktInst = instalment - aktInterest;
      debt = debt - instalmentOfDebtInAktInst;
      if (debt > 0) {
        arr.push(debt);
      }
    }

    const detailedViewOfInstalment = arr.map((x, index) => {
      return (
        <tr key={index}>
          <td>{index + 1}. splátka: </td>
          <td>{result.s} Kč</td>
          <td>| zbývající jistina:</td>
          <td>{Math.round(x)} Kč</td>
        </tr>
      );
    });
    return (
      <small>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>{detailedViewOfInstalment}</tbody>
          <tfoot></tfoot>
        </table>
      </small>
    );
  }

  return (
    <div>
      <h1>Hypotéka</h1>
      <Formik
        initialValues={{ownAmount1: '', ownAmount2: '', ownAmount3: ''}}
        onSubmit={async values => {
          countClick(values);
        }}
      >
        {({values, handleChange, handleSubmit}) => (
          <Form noValidate onSubmit={handleSubmit}>
            <br /> <br />
            {/* input zkušební */}
            <InputGroup hasValidation className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-volume">výše hypotéky </InputGroup.Text>
              <Form.Control
                type="number"
                name="ownAmount1"
                value={values.ownAmount1}
                onChange={handleChange}
                aria-label="Text input with checkbox"
              />
              <InputGroup.Text id="inputGroup-sizing-volume">Kč</InputGroup.Text>
            </InputGroup>
            <InputGroup hasValidation className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-volume">procentní sazba</InputGroup.Text>
              <Form.Control
                type="number"
                name="ownAmount2"
                value={values.ownAmount2}
                onChange={handleChange}
                placeholder=""
              />
              <InputGroup.Text id="inputGroup-sizing-volume">%</InputGroup.Text>
            </InputGroup>
            <InputGroup hasValidation className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-volume">délka splácení </InputGroup.Text>
              <Form.Control type="number" name="ownAmount3" value={values.ownAmount3} onChange={handleChange} />
              <InputGroup.Text id="inputGroup-sizing-volume">let</InputGroup.Text>
            </InputGroup>
            <ErrorMessage name="ownAmount" component="div" />
            <br /> <br />
            <Button variant="primary" type="submit">
              Spočítej
            </Button>
          </Form>
        )}
      </Formik>
      <div style={result.s != 0 ? {visibility: 'visible'} : {visibility: 'hidden'}}>
        <br />
        <h2>Výsledek</h2>
        <table>
          <thead>
            <tr>
              <th></th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>úvěr: </td>
              <td>{result.k} Kč</td>
            </tr>
            <tr>
              <td>úrok: </td>
              <td>{result.p * 12} %</td>
            </tr>
            <tr>
              <td>roky: </td>
              <td>{result.n / 12} </td>
            </tr>
            <tr>
              <td>splátka: </td>
              <td>{result.s} Kč</td>
            </tr>
            <tr>
              <td>celkem zaplaceno: </td>
              <td>{result.total} Kč</td>
            </tr>
            <tr>
              <td>min. hodnota nem.: </td>
              <td>{result.minProperty} Kč</td>
            </tr>
          </tbody>
          <tfoot></tfoot>
        </table>
        <br />
        <h6>Zbývající jistina při jednotlivých splátkách:</h6>
        <ValueOfDebt />
      </div>
    </div>
  );
}

export default Mortgage;
