import {Link} from 'react-router-dom';
import Nav from 'react-bootstrap/Nav';
// import DropdownButton from 'react-bootstrap/DropdownButton';
import {useWindowSize} from '../hooks/useWindowSize';
import {useEnvironment} from '../hooks/useEnvironment';
import {
  faHouse,
  faVial,
  faCalculator,
  faMoneyCheckDollar,
  faChartLine,
  faTrain,
  faCoins,
} from '@fortawesome/free-solid-svg-icons';
import {IconItem} from './shared/IconItem';
import {SignInNavigationBarItem} from './navigation/SignInNavigationBarItem';

/**
 * Navigation Bar component.
 * Shows available pages
 * @return {React.FC}
 */
function NavigationBar() {
  // use custom hook to get us updated when browser's window changes
  const windowSizeInfo = useWindowSize();
  // are we running in production environment?
  const {isDevEnv} = useEnvironment();

  return (
    <div className={'d-flex justify-content-between ' + (windowSizeInfo.isLandscapeMode ? 'flex-column' : 'flex-row')}>
      <Nav
        id="navbar"
        as="ul"
        defaultActiveKey="/"
        className={'text-primary align-items-center ' + (windowSizeInfo.isLandscapeMode ? 'flex-column' : 'flex-row')}
        data-testid="test-navbar" // attribute used for identify the element for testing purposes
      >
        <Nav.Item as="li">
          <Nav.Link as={Link} to={'/'}>
            <IconItem icon={faHouse} label="Home" />
          </Nav.Link>
        </Nav.Item>
        {isDevEnv && (
          <>
            <Nav.Item as="li">
              <Nav.Link as={Link} to={'/demoPage1'}>
                <IconItem icon={faVial} label="Demo 1" />
              </Nav.Link>
            </Nav.Item>
            <Nav.Item as="li">
              <Nav.Link as={Link} to={'/demoPage2'}>
                <IconItem icon={faVial} label="Demo 2" />
              </Nav.Link>
            </Nav.Item>
          </>
        )}

        <Nav.Item as="li" className="">
          <Nav.Link as={Link} to={'/stocks'}>
            <IconItem icon={faChartLine} label="Stocks" />
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li" className="p-2">
          <Nav.Link as={Link} to={'/exchangeRate'}>
            <IconItem icon={faMoneyCheckDollar} label="FX Rates" />
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Nav.Link as={Link} to={'/calculator'}>
            <IconItem icon={faCalculator} label="Calculator" />
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Nav.Link as={Link} to={'/mortgage'}>
            <IconItem icon={faCoins} label="Mortgage" />
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li">
          <Nav.Link as={Link} to={'/trains'}>
            <IconItem icon={faTrain} label="Trains" />
          </Nav.Link>
        </Nav.Item>

        <Nav.Item as="li">
          <SignInNavigationBarItem />
        </Nav.Item>
      </Nav>
    </div>
  );
}

export default NavigationBar;
