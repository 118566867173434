import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import WindowedSelect, {createFilter} from 'react-windowed-select';
import {StockSymbol} from '../hooks/useStocks';

type MyStocksAdd = {
  setShowModalSelect: (a: boolean) => void;
  stockSymbols: StockSymbol[] | undefined;
  showModalSelect: boolean;
  addValueFromSelectClick: (a: string) => void;
  memoizedStockSymbolsAsSelectOptions: {value: string; label: string}[] | undefined;
};
/** Modal Select drop down with all available stock symbols
 * @return {React.FC} JSX
 */
function StocksAddSymbol({
  setShowModalSelect,
  stockSymbols,
  showModalSelect,
  addValueFromSelectClick,
  memoizedStockSymbolsAsSelectOptions,
}: MyStocksAdd) {
  const handleClose = () => {
    setShowModalSelect(false);
  };
  return stockSymbols ? (
    <Modal show={showModalSelect} onHide={handleClose} data-testid="reactSelectForAddStockSymbol">
      <Modal.Header closeButton>
        <Modal.Title>Add Stock Symbol</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <WindowedSelect
          windowThreshold={100}
          placeholder="Select stock"
          data-testid="modal-stock-select-symbol"
          onChange={(x: any) => {
            if (x?.value) {
              addValueFromSelectClick(x?.value);
              handleClose();
            }
          }}
          filterOption={createFilter({ignoreAccents: false})} // speed up by not requesting diacritics handling
          options={memoizedStockSymbolsAsSelectOptions}
        />
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  ) : (
    <>no data</>
  );
}
export default StocksAddSymbol;
