import {useState, useEffect} from 'react';
import {useFunctions} from 'reactfire';
import {httpsCallable} from 'firebase/functions';
// import {DateTime} from 'luxon';
import {REQUEST_STATUS} from '../types/requestStatus';
import {useLocalStorage, LocalStorageHookResponse} from './useLocalStorage';
import axios from 'axios';
// import {useLocalStorage} from './useLocalStorage';
export * from '../types/requestStatus';

export interface StockSymbol {
  symbol: string; // 'AAPL'
  company: string; // 'Apple Inc.'
}

export interface StockData {
  symbol: string; // 'AAPL'
  displayName: string; // 'Apple'
  currency: string; // 'USD'
  region: string; // 'US',
  marketCap: number; // 2295940513792
  regularMarketPrice: number; // 136.76
  regularMarketDayHigh: number; // 137.41
  regularMarketDayLow: number; // 135.86
  regularMarketPreviousClose: number; // 137.185
  fiftyTwoWeekLow: number; // 137.185
  fiftyTwoWeekHigh: number; // 137.185
  postMarketPrice: number; // 138.195
  marketState: 'OPEN' | 'CLOSED'; // 'CLOSED'
}

const testStocks: StockData[] = [
  {
    symbol: 'AAPL', // 'AAPL'
    displayName: 'Apple', // 'Apple'
    currency: 'USD', // 'USD'
    region: 'US', // 'US',
    marketCap: 2295940513792, // 2295940513792
    regularMarketPrice: 136.76, // 136.76
    regularMarketDayHigh: 137.41, // 137.41
    regularMarketDayLow: 135.86, // 135.86
    regularMarketPreviousClose: 137.185, // 137.185
    fiftyTwoWeekLow: 137.185, // 137.185
    fiftyTwoWeekHigh: 137.185, // 137.185
    postMarketPrice: 138.195, // 138.195
    marketState: 'OPEN',
  },
  {
    symbol: 'HARA',
    displayName: 'HaRa',
    currency: 'CZK',
    region: 'CZ',
    marketCap: 2295940514792,
    regularMarketPrice: 146.76,
    regularMarketDayHigh: 147.41,
    regularMarketDayLow: 145.86,
    regularMarketPreviousClose: 147.185,
    fiftyTwoWeekLow: 147.185,
    fiftyTwoWeekHigh: 147.185,
    postMarketPrice: 148.195,
    marketState: 'CLOSED',
  },
  {
    symbol: 'ARA',
    displayName: 'aRa',
    currency: 'CZK',
    region: 'CZ',
    marketCap: 2295940514792,
    regularMarketPrice: 146.76,
    regularMarketDayHigh: 147.41,
    regularMarketDayLow: 145.86,
    regularMarketPreviousClose: 147.185,
    fiftyTwoWeekLow: 147.185,
    fiftyTwoWeekHigh: 147.185,
    postMarketPrice: 148.195,
    marketState: 'CLOSED',
  },
  {
    symbol: 'KARA',
    displayName: 'KaRa',
    currency: 'CZK',
    region: 'CZ',
    marketCap: 2295940514792,
    regularMarketPrice: 146.76,
    regularMarketDayHigh: 147.41,
    regularMarketDayLow: 145.86,
    regularMarketPreviousClose: 147.185,
    fiftyTwoWeekLow: 147.185,
    fiftyTwoWeekHigh: 147.185,
    postMarketPrice: 148.195,
    marketState: 'CLOSED',
  },
];

/**
 * Fetches stocks from a remote API
 * @param {Functions} functions Firebase functions
 * @param {string} stocks code e.g. 'AAPL'
 * @return {FetchStocks} stock for the given code
 */
async function fetchStocks(functions: any, stocks: string[]): Promise<StockData[]> {
  if (!stocks || stocks.length === 0) return [];
  // invoke the function
  const fbStocksGetQuotes = httpsCallable(functions, 'stocksGetQuotes');
  console.log(stocks);
  stocks = stocks.filter(p => (p ? true : false));
  const result = await fbStocksGetQuotes({symbols: stocks});
  const st = result.data as StockData[];
  return st;
}

/**
 * function providing array of all posible stocks symbols
 * @param {LocalStorageHookResponse} localStorageCache local storage to use for caching
 * @return {string[]} like ['AAPL', 'HARA',...]
 */
async function fetchAllStockSymbols(localStorageCache: LocalStorageHookResponse): Promise<StockSymbol[]> {
  const {value: cachedSymbols, setValue: setCachedSymbols} = localStorageCache;
  // if cached, use it right away
  if (cachedSymbols) return cachedSymbols as StockSymbol[];
  // load from public data
  const STOCK_SYMBOLS_FILE_URL = process.env.PUBLIC_URL + '/data/stock-symbols.csv';
  // console.log('useStocks.fetchAllStockSymbols(): STOCK_SYMBOLS_FILE_URL = ', STOCK_SYMBOLS_FILE_URL);
  try {
    const stockSymbolsCsv = await axios.get(STOCK_SYMBOLS_FILE_URL);
    const lines: string[] = stockSymbolsCsv.data.split('\n');
    lines.shift(); // skip first line (header)
    const stockSymbols = lines.map(x => {
      const [symbol, name] = x.split(',');
      const stockSymbol: StockSymbol = {symbol: symbol, company: name.trim()};
      return stockSymbol;
    });
    // console.log('stockSymbols=', JSON.stringify(stockSymbols));
    setCachedSymbols(stockSymbols);
    return stockSymbols;
  } catch (err) {
    console.error("Couldn't load list of all stock symbols.", err);
  }
  return [];
}

export interface StocksHookResponse {
  stockDataStatus: REQUEST_STATUS;
  stockData: StockData[] | undefined;
  stockSymbols: StockSymbol[] | undefined;
}

/**
 * Custom hook providing exchange rates for given base currency
 * @param {string} userStocks
 * @return {ExchangeRateInfo}
 */
export function useStocks(userStocks: string[]): StocksHookResponse {
  // state to keep track of loading
  const [stockDataStatus, setStockDataStatus] = useState<REQUEST_STATUS>(REQUEST_STATUS.LOADING);
  const [, setError] = useState<string | undefined>();
  const [stockData, setStockData] = useState<StockData[] | undefined>(testStocks); // set def. value TODO odebrat test!!!
  const [stockSymbols, setStockSymbols] = useState<StockSymbol[] | undefined>();
  const localStorageCache = useLocalStorage('cache-stock-symbols');
  // get access to Firebase
  const functions = useFunctions(); // requires FirebaseProvider as a parent

  // effect to fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        // try to fetch data from local cache
        setStockDataStatus(REQUEST_STATUS.LOADING);
        // TODO implement caching?

        // request to load data, you can use fetch API too
        const payload = await fetchStocks(functions, userStocks);
        // set data in state and loading to success
        setStockData(payload);
        setStockDataStatus(REQUEST_STATUS.SUCCESS);
      } catch (e: unknown) {
        console.error('Error', e);
        setStockDataStatus(REQUEST_STATUS.FAILURE);
        setError((e as Error).toString());
      }
    };
    fetchData();
    // }, []);
  }, [userStocks]);

  useEffect(() => {
    const loadStockSymbols = async () => {
      const symbols = await fetchAllStockSymbols(localStorageCache);
      setStockSymbols(symbols);
    };
    loadStockSymbols();
  }, []);

  return {stockDataStatus, stockData, stockSymbols};
}
