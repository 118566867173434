import {useState, useEffect, useMemo} from 'react';
import {useSettings} from '../hooks/useSettings';
import {useStocks} from '../hooks/useStocks';
import {REQUEST_STATUS} from '../types/requestStatus';
import {DateTime} from 'luxon';
import PrettyCzDateTime from './shared/PrettyCzDateTime';
import SettingsButton from './shared/SettingsButton';
import {useExchangeRates} from '../hooks/useExchangeRates';
import StocksPortfolio from './StocksPortfolio';
import {StocksSettingsBuy} from './Stocks.d';
import StocksAddSymbol from './StocksAddSymbol';
import StocksSymbolsTable from './StocksSymbolsTable';
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Button, Form, InputGroup, Modal, Row} from 'react-bootstrap';
import WindowedSelect, {createFilter} from 'react-windowed-select';

/**
 * a component for user's stocks list
 * @return {React.FC}
 */
function Stocks() {
  /** --------------------------------states------------------------------------------- */
  /** Settings */
  interface StocksSettings {
    symbols: string[];
  }
  /** Default settings to use if there are no or invalid user settings available */
  const defaultUserSettings: StocksSettings = {
    symbols: [], // , 'GOOG', 'SCHN'
  };

  /** Default settings to use if there are no or invalid user settings available */
  const defaultUserSettBuy: StocksSettingsBuy[] = [];
  const [setupMode, setSetupMode] = useState(false); // for visibility of + and - settings buttons
  const [showModalSelect, setShowModalSelect] = useState(false); // modal dialog to select stock symbols to add
  const [showModalSelectBuy, setShowModalSelectBuy] = useState(false); // modal dialog to select stock symbols to add
  const {settings: userSettings, setSettings: setUserSettings} = useSettings('stocks'); // user settings from customhook
  const {settings: userSettBuy, setSettings: setUserSettBuy} = useSettings('stocksBuy'); // user settings from custom hook
  const [stocks, setStocks] = useState<string[]>([]); // needed array of stocks symbols from user setting
  const [stocksBuy, setStocksBuy] = useState<StocksSettingsBuy[]>([]); // array of buys
  const [detailsShowing, setDetailsShowing] = useState(''); // holds which details (in minitable) are showed
  const [editBuy, setEditBuy] = useState<StocksSettingsBuy[]>([]); // for remembering edited buy
  const [exchangeRatesStatus, exchangeRatesStatusData] = useExchangeRates('USD');
  const czk = exchangeRatesStatusData?.rates.CZK;
  exchangeRatesStatus;

  // main custom hook state with status and data
  const {stockDataStatus, stockData, stockSymbols} = useStocks(stocks); // userStockSettings.symbols;
  // speed up stock symbol select by preparing and memoizing proper options object structure upfront
  const memoizedStockSymbolsAsSelectOptions = useMemo(() => {
    return stockSymbols?.map(x => {
      return {
        value: x.symbol,
        label: `${x.symbol} | ${x.company}`,
      };
    });
  }, [stockSymbols]);

  // loading from "user settings" after screen-reloading and setting "stocks" from it
  useEffect(() => {
    const newSettings = Object.assign(defaultUserSettings, userSettings); // [] je v settingy.currencies
    setStocks(newSettings.symbols);
  }, [userSettings]);

  // loading from "user settings" after screen-reloading and setting "stocks" from it
  useEffect(() => {
    const newSettBuy = Object.assign(defaultUserSettBuy, userSettBuy);
    setStocksBuy(newSettBuy);
  }, [userSettBuy]);

  /** function find name of stock from the symbol
   * @return {string}
   */

  // eslint-disable-next-line require-jsdoc
  function findNameOfStockFromSymbol(symbol: string): string {
    if (memoizedStockSymbolsAsSelectOptions) {
      const symbolObject = memoizedStockSymbolsAsSelectOptions?.filter(a => a.value === symbol);
      const symbolLabel = symbolObject[0].label;
      const displayName = symbolLabel.substring(symbol.length + 3, symbolLabel.length);
      return displayName;
    }
    return 'nenalezeno';
  }
  /** ==================================================================================================== */
  /** =====================================BUY MODAL SELECT StocksAddBuy================================== */
  /** ==================================================================================================== */

  interface PropsSymb {
    symbol: string;
  }
  /** BUY Modal Select drop down with all available stock symbols
   * inputs for adding new BUY to
   * @param {string} props symbol
   * @return {React.FC} JSX
   */
  function StocksAddBuy(props: PropsSymb) {
    // function for modal window CLOSE
    const handleClose = () => {
      setShowModalSelectBuy(false);
    };
    // set values as new BUY to userSettingsBuy
    const handleSubmit = (values: any) => {
      // date validation in form
      const buy = {
        symbol: values.symbol,
        openPrice: values.price,
        openDateIso: DateTime.fromISO(values.openDate).toISO(),
        volume: values.volume,
      };
      let newStockBuy;
      // ADD buy
      if (editBuy.length === 0) {
        handleClose();
        newStockBuy = [...stocksBuy, buy];
        setUserSettBuy(newStockBuy);
        // check if symbol is in userSettings, if not, add it there (to setUserSettings)
        addValueFromSelectClick(values.symbol);
      }
      // EDIT buy
      else {
        newStockBuy = stocksBuy;
        // switch old (delete) to new buy (add) - find 1st match
        for (let i = 0; i < newStockBuy.length; i++) {
          if (newStockBuy[i] === editBuy[0]) {
            newStockBuy[i] = buy;
            setEditBuy([]);
            handleClose();
            setUserSettBuy(newStockBuy);
            return;
          }
        }
      }
    };
    // schema for form validation
    const schema = Yup.object().shape({
      symbol: Yup.string().required('Required'),
      price: Yup.number().min(0.01).required('Required'),
      openDate: Yup.date().max(DateTime.now()).required('Required'),
      volume: Yup.number().min(0.001).required('Required'),
    });
    // component Modal in Formik
    return stockSymbols ? (
      <Formik
        validationSchema={schema}
        onSubmit={handleSubmit}
        initialValues={
          // in add mode, fill only input symbol
          !editBuy[0]
            ? {
                symbol: props.symbol,
                price: '',
                openDate: DateTime.now().toString(),
                volume: 1,
              }
            : {
                // in edit mode, fill all inputs
                symbol: editBuy[0].symbol,
                price: editBuy[0].openPrice,
                openDate: editBuy[0].openDateIso,
                volume: editBuy[0].volume,
              }
        }
      >
        {({handleSubmit, handleChange, handleBlur, values, touched, isValid, errors, setFieldValue}) => (
          <Modal show={showModalSelectBuy} onHide={handleClose} data-testid="reactSelectForAddBuyStock">
            <Modal.Header closeButton>
              <Modal.Title> {editBuy[0] ? 'EDIT NEW STOCK BUY' : 'ADD NEW STOCK BUY'}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form noValidate onSubmit={handleSubmit}>
                <Row className="mb-3">
                  {/* SELECT */}
                  <WindowedSelect
                    // in editation mode is not allowed to change symbol
                    isDisabled={editBuy[0] ? true : false}
                    windowThreshold={100}
                    defaultValue={memoizedStockSymbolsAsSelectOptions?.filter(option => option.value === props.symbol)}
                    filterOption={createFilter({ignoreAccents: false})} // speed up by not requesting diacritics handling
                    options={memoizedStockSymbolsAsSelectOptions}
                    name="symbol"
                    onChange={(e: any) => setFieldValue('symbol', e.value)}
                  />
                </Row>
                {/* input PRICE */}
                <InputGroup hasValidation className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-price">Price </InputGroup.Text>
                  <Form.Control
                    placeholder="in USD per 1 piece"
                    type="number"
                    name="price"
                    value={values.price}
                    onChange={handleChange}
                    isValid={touched.price && !errors.price}
                  />
                  <InputGroup.Text id="inputGroup-dolar">$</InputGroup.Text>
                </InputGroup>
                {/* input DATE */}
                <InputGroup hasValidation className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-date"> Date </InputGroup.Text>
                  <Form.Control
                    type="date"
                    name="openDate"
                    value={DateTime.fromISO(values.openDate).toISODate()} // input need format YYYY-MM-DD
                    onChange={handleChange}
                    isValid={
                      touched.openDate && !errors.openDate && DateTime.now() >= DateTime.fromISO(values.openDate)
                    }
                  />
                </InputGroup>
                {/* input VOLUME */}
                <InputGroup hasValidation className="mb-3">
                  <InputGroup.Text id="inputGroup-sizing-volume">Volume</InputGroup.Text>
                  <Form.Control
                    type="number"
                    name="volume"
                    value={values.volume}
                    onChange={handleChange}
                    isValid={touched.volume && !errors.volume}
                  />
                </InputGroup>
                {/* ADD/EDIT button */}
                <div className="col d-flex justify-content-end">
                  <Button variant="secondary" type="submit">
                    {editBuy[0] ? 'EDIT STOCK BUY' : 'ADD STOCK BUY'}
                  </Button>
                </div>
              </Form>
            </Modal.Body>
            {/* <Modal.Footer></Modal.Footer> */}
          </Modal>
        )}
      </Formik>
    ) : (
      <>no data</>
    );
  }

  // ===================================== functions ====================================================

  /** -------------------------------- search functions ------------------------------------ */
  /** a function returns if object in array has (or has not) in property expected value
   * @param {any[]} arr
   * @param {string} proper
   * @param {string} val
   * @return {boolean} if is found
   */
  const searchArrayWithObjects = (arr: any[], proper: string, val: string) => {
    for (const mujObjekt of arr) {
      // if (mujObjekt[vlastnost].includes(hodnota)) return true;
      if (mujObjekt[proper] === val) return true;
    }
    return false;
  };

  /** a function returns object value from object property in array of object
   * @param {string} symbol
   * @return {number}
   */
  const findInStockDataValue = (symbol: string) => {
    if (stockData) {
      for (const myObj of stockData) {
        if (myObj.symbol === symbol) {
          return myObj.regularMarketPrice;
        }
      }
    }
    return;
  };

  // function returns true/false - if stock SYMBOL is/isn't in buys
  const searchStockInBuys = (myStock: string) => {
    return searchArrayWithObjects(stocksBuy, 'symbol', myStock);
  };

  // function returns rounded net PROFIT of 1 buy
  const netProfitUSD = (regularMarketPrice: number, openPrice: number, volume: number) => {
    return Math.round((regularMarketPrice - openPrice) * volume * 100) / 100;
  };

  // function returns net PROFIT of all buys
  const netProfitUSDAll = () => {
    let allProfit = 0;
    for (let i = 0; i < stocksBuy.length; i++) {
      const val = findInStockDataValue(stocksBuy[i].symbol);
      if (val) {
        allProfit += netProfitUSD(val, stocksBuy[i].openPrice, stocksBuy[i].volume);
      }
    }
    // porovnaní cena nákupu
    return allProfit;
  };

  // function returns ACTUAL VALUE of ALL buys
  const netBuyValueNowAll = () => {
    let allBuyValue = 0;
    for (let i = 0; i < stocksBuy.length; i++) {
      const val = findInStockDataValue(stocksBuy[i].symbol);
      if (val) {
        allBuyValue += val * stocksBuy[i].volume;
      }
    }
    return allBuyValue;
  };
  // function returns BUY (PREVIOUS) VALUE of ALL buys - in the "time of buy"
  const netBuyValueBeforeAll = () => {
    let allBuyValue = 0;
    for (let i = 0; i < stocksBuy.length; i++) {
      allBuyValue += stocksBuy[i].openPrice * stocksBuy[i].volume;
    }
    return allBuyValue;
  };

  // fix from "$-158" to "- $158"
  const prettyUSDPlusMinusNumber = (num: number) => {
    const n = Math.round(num * 100) / 100;
    let text = num >= 0 ? '$' : '- $';
    text += Math.abs(n);
    return text;
  };

  // fix from "-158" to "-158 Kč"
  const prettyCZKPlusMinusNumber = (num: number) => {
    const n = Math.round(num * 100) / 100;
    return n + ' Kč';
  };

  // fix from "0.45687" to "0.46 %"
  const prettyPercentPlusMinusNumber = (num: number) => {
    const n = Math.round(num * 100) / 100;
    return n + ' %';
  };

  const countTotalProfit = (symb: string) => {
    let totalProfit = 0;
    // if there are any buys
    if (stocksBuy.length !== 0) {
      // array with buys of "symb" symbol
      const symbolBuys = stocksBuy.filter(x => x.symbol === symb);
      const actualStockValue = stockData?.find(s => s.symbol === symb)?.regularMarketPrice;
      if (actualStockValue) {
        symbolBuys.forEach(item => {
          totalProfit += (actualStockValue - item.openPrice) * item.volume;
        });
      }
    }
    return totalProfit;
  };

  const countTotalPercent = (symb: string) => {
    let totalPercent = 0;
    let totalActualValue = 0;
    let totalValueWhenBuys = 0;

    // if there are any buys
    if (stocksBuy.length !== 0) {
      // array with buys of "symb" symbol
      const symbolBuys = stocksBuy.filter(x => x.symbol === symb); // array of buys of symbol
      const actualStockValue = stockData?.find(s => s.symbol === symb)?.regularMarketPrice; // actual value
      if (actualStockValue) {
        symbolBuys.forEach(item => {
          totalActualValue += actualStockValue * item.volume;
          totalValueWhenBuys += item.openPrice * item.volume;
        });
        const jp = totalValueWhenBuys / 100;
        totalPercent = (totalActualValue - totalValueWhenBuys) / jp;
      }
    }
    return totalPercent;
  };

  // ======================================== click funcions =========================================
  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

  // shows all editing buttons for editation
  const showSettingButtonsClick = () => {
    setSetupMode(prev => !prev);
  };

  // shows modal select for adding stock to user settings
  const showModalClick = () => {
    setShowModalSelect(true);
  };
  // shows Modal Window for add stock to user settings and my buy-settings
  const showModalBuyClick = (s: string) => {
    setShowModalSelectBuy(true);
    setDetailsShowing(s);
  };

  // add the value from input to user's settings and the list of stocks
  const addValueFromSelectClick = (x: any) => {
    // to stop duplicity
    if (!stocks.includes(x)) {
      setUserSettings({symbols: [...stocks, x]});
      setStocks(prev => [...prev, x]);
    }
  };

  // remove stock from the list of stocks and user settings
  const removeStockClick = (s: string) => {
    // if (searchStockInBuys(s)) return; // if the symbol is in buys, do not remove
    const newArray = stocks.filter(p => p !== s);
    setUserSettings({symbols: newArray});
    setStocks(newArray);
  };

  // buy editation
  const editBuyClick = (o: StocksSettingsBuy) => {
    setEditBuy([o]);
    showModalBuyClick(o.symbol);
  };

  // delete from buy settings
  const deleteBuyClick = (o: StocksSettingsBuy) => {
    const newBuyArr = stocksBuy.filter(item => item !== o);
    if (!searchArrayWithObjects(newBuyArr, 'symbol', o.symbol)) {
      removeStockClick(o.symbol);
    }
    setUserSettBuy(newBuyArr);
  };

  // shows/hide details of my buying settings
  const showMiniTableDetailsClick = (s: string) => {
    if (detailsShowing === s) setDetailsShowing('');
    else setDetailsShowing(s);
  };

  // ===================================== the main component ====================================================
  return (
    <div id="Stocks">
      <h1>Stocks</h1>
      {stockDataStatus === REQUEST_STATUS.FAILURE && <p>no data</p>}
      {(stockDataStatus === REQUEST_STATUS.SUCCESS || stockDataStatus === REQUEST_STATUS.LOADING) && (
        <>
          <SettingsButton visibility={true} doOnClick={showSettingButtonsClick} label={''} />
          <StocksPortfolio
            stockDataStatus={stockDataStatus}
            netProfitUSDAll={netProfitUSDAll}
            prettyUSDPlusMinusNumber={prettyUSDPlusMinusNumber}
            netBuyValueNowAll={netBuyValueNowAll}
            prettyPercentPlusMinusNumber={prettyPercentPlusMinusNumber}
            czk={czk}
            prettyCZKPlusMinusNumber={prettyCZKPlusMinusNumber}
            netBuyValueBeforeAll={netBuyValueBeforeAll}
          />
          <StocksSymbolsTable
            setupMode={setupMode}
            showModalClick={showModalClick}
            stockDataStatus={stockDataStatus}
            searchStockInBuys={searchStockInBuys}
            findNameOfStockFromSymbol={findNameOfStockFromSymbol}
            countTotalProfit={countTotalProfit}
            prettyUSDPlusMinusNumber={prettyUSDPlusMinusNumber}
            prettyCZKPlusMinusNumber={prettyCZKPlusMinusNumber}
            czk={czk}
            removeStockClick={removeStockClick}
            showModalBuyClick={showModalBuyClick}
            stocksBuy={stocksBuy}
            detailsShowing={detailsShowing}
            netProfitUSD={netProfitUSD}
            deleteBuyClick={deleteBuyClick}
            editBuyClick={editBuyClick}
            showMiniTableDetailsClick={showMiniTableDetailsClick}
            stockData={stockData}
            countTotalPercent={countTotalPercent}
          />
          <StocksAddSymbol
            setShowModalSelect={setShowModalSelect}
            stockSymbols={stockSymbols}
            showModalSelect={showModalSelect}
            addValueFromSelectClick={addValueFromSelectClick}
            memoizedStockSymbolsAsSelectOptions={memoizedStockSymbolsAsSelectOptions}
          />
          <StocksAddBuy symbol={detailsShowing} />
          <small className="text-secondary">
            Open time the NASDAQ Stock Exchange: 9:30 am - 4:00 pm (<strong>15:30 - 22:00 h</strong>)
          </small>
          <PrettyCzDateTime dateStringIso={DateTime.now().toISO()} text="poslední aktualizace:" />
        </>
      )}
    </div>
  );
}

export default Stocks;
