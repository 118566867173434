/**
 * Error Boundary
 *
 * - used by top level App
 * - catch JS errors and display
 */

import {Component} from 'react';
import Props from '../types/Props';

interface StateInterface {
  hasError: boolean;
  error?: Error | null;
  info?: object;
}

/** Error Boundary */
class ErrorBoundary extends Component<Props, StateInterface> {
  state = {hasError: false};

  /** Catches exceptions generated in descendant components. Unhandled exceptions will cause the entire component tree to unmount.
   * @param {Error} error
   * @param {object} info
   */
  componentDidCatch(error: Error | null, info: object) {
    this.setState({hasError: true, error, info});
  }

  /** Render
   * @return {React.FC} JSX
   */
  render() {
    if (this.state.hasError) {
      return <div>Something went wrong.</div>;
    }
    return this.props.children;
  }
}

export default ErrorBoundary;
