export const screenPc = global.window.innerWidth >= 1070;
export const screenTbl = global.window.innerWidth >= 700 && !screenPc;
export const screenMobil = global.window.innerWidth < 700;

export const styles = {
  padding: 16,
  margin: 16,
  smallPadding: 8,
  commonCardWidth: '90%',
  calculatorBasicDisplayHeight: 100,
  inputMDWidthAndHeight: 70,

  calculatorButtonWidth: screenMobil ? 60 : screenTbl ? 100 : 80,
  calculatorButtonHeight: 50,
  calculatorButtonBorderRadius: 4,
};
