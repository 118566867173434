/** Train Station info */
export interface TrainStation {
  id: string; // station id e.g. '2299'
  name: string; // station name 'Ledeč nad Sázavou'
  noDiacriticsName: string; // 'Ledec nad Sazavou'
}

/**
 * Custom hook providing info on trains
 * @return {allStations} list of all train stations
 */
export function useTrains() {
  return {allStations};
}

/** Removes diacritics from given string
 * @param {string} str with diacritics
 * @return {string} without diacritics
 */
function removeDiacritics(str: string) {
  return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
}

/** 
 * Hard coded list of all CZ stations.
 * 
 * Extracted from 
 *      https://provoz.spravazeleznic.cz/tabule/Pages/StationList.aspx?Key=A
 * Regex extracting station list from HTML retrieved at the link above
 * https://regex101.com/
Regex:
<a class="dxbs-hyperlink" href="StationTable\.aspx\?Key=(?<key>\d*)"\>(?<station>.*)\<\/a><br\/>
/gmU

List: 
${key}|${station}\n


produces duplicates that need to be deduplicated. e.g. using Excel
*/
const allStationsRaw = [
  {id: '12', name: 'Adamov'},
  {id: '13', name: 'Adamov zastávka'},
  {id: '16', name: 'Adršpach'},
  {id: '69', name: 'Albrechtice u Českého Těšína'},
  {id: '74', name: 'Amalín'},
  {id: '89', name: 'Anenská Studánka'},
  {id: '97', name: 'Antonínov'},
  {id: '113', name: 'Aš'},
  {id: '114', name: 'Aš město'},
  {id: '115', name: 'Aš předměstí'},
  {id: '129', name: 'Babice nad Svitavou'},
  {id: '130', name: 'Babice u Šternberka'},
  {id: '132', name: 'Babylon'},
  {id: '142', name: 'Bahno'},
  {id: '146', name: 'Bakov nad Jizerou'},
  {id: '145', name: 'Bakov nad Jizerou město'},
  {id: '154', name: 'Balkova Lhota'},
  {id: '168', name: 'Bartoňov'},
  {id: '169', name: 'Bartoušov zastávka'},
  {id: '172', name: 'Baška'},
  {id: '173', name: 'Batelov'},
  {id: '176', name: 'Bavorov'},
  {id: '188', name: 'Bechov'},
  {id: '189', name: 'Bechyně'},
  {id: '191', name: 'Bechyně zastávka'},
  {id: '192', name: 'Bechyňská Smoleč'},
  {id: '180', name: 'Bečov nad Teplou'},
  {id: '181', name: 'Bečov u Mostu'},
  {id: '182', name: 'Bečváry'},
  {id: '184', name: 'Bedihošť'},
  {id: '185', name: 'Bednárec'},
  {id: '186', name: 'Bednáreček'},
  {id: '195', name: 'Bělá nad Radbuzou'},
  {id: '194', name: 'Bělá nad Radbuzou zastávka'},
  {id: '198', name: 'Bělá pod Bezdězem'},
  {id: '196', name: 'Bělá pod Bezdězem město'},
  {id: '197', name: 'Bělá pod Bezdězem zastávka'},
  {id: '200', name: 'Bělá u Staré Paky'},
  {id: '199', name: 'Bělá u Staré Paky zastávka'},
  {id: '201', name: 'Bělčice'},
  {id: '202', name: 'Běleč'},
  {id: '205', name: 'Bělotín'},
  {id: '207', name: 'Benešov nad Ploučnicí'},
  {id: '209', name: 'Benešov u Prahy'},
  {id: '246', name: 'Bernartice u Javorníka'},
  {id: '247', name: 'Bernartice u Trutnova'},
  {id: '251', name: 'Beroun'},
  {id: '253', name: 'Beroun-Závodí'},
  {id: '254', name: 'Běrunice'},
  {id: '256', name: 'Běšiny'},
  {id: '257', name: 'Běšiny Eurocamp'},
  {id: '258', name: 'Bezdědovice'},
  {id: '259', name: 'Bezděkov u Klatov'},
  {id: '260', name: 'Bezděkov u Radnic'},
  {id: '261', name: 'Bezděz'},
  {id: '262', name: 'Bezdružice'},
  {id: '265', name: 'Bežerovice'},
  {id: '263', name: 'Bezměrov'},
  {id: '281', name: 'Bílá Třemešná'},
  {id: '282', name: 'Bílá Voda'},
  {id: '283', name: 'Bílek'},
  {id: '284', name: 'Bílina'},
  {id: '285', name: 'Bílina kyselka'},
  {id: '286', name: 'Bílina-Chudeřice'},
  {id: '287', name: 'Bílovec'},
  {id: '288', name: 'Bílovice nad Svitavou'},
  {id: '289', name: 'Bílý Kostel nad Nisou'},
  {id: '290', name: 'Bílý Potok pod Smrkem'},
  {id: '297', name: 'Biskupice u Jevíčka'},
  {id: '298', name: 'Biskupice u Luhačovic'},
  {id: '299', name: 'Bítovčice'},
  {id: '301', name: 'Blahousty'},
  {id: '303', name: 'Blanice'},
  {id: '305', name: 'Blansko'},
  {id: '306', name: 'Blansko město'},
  {id: '307', name: 'Blatec'},
  {id: '308', name: 'Blatná'},
  {id: '309', name: 'Blatnice pod Svatým Antonínkem'},
  {id: '310', name: 'Blatnice u Nýřan'},
  {id: '311', name: 'Blatno u Jesenice'},
  {id: '314', name: 'Blažovice'},
  {id: '1252', name: 'Blešno'},
  {id: '318', name: 'Blížejov'},
  {id: '319', name: 'Blíževedly'},
  {id: '320', name: 'Blížkovice'},
  {id: '321', name: 'Blovice'},
  {id: '322', name: 'Bludov'},
  {id: '325', name: 'Bludov lázně'},
  {id: '329', name: 'Boč'},
  {id: '328', name: 'Bocanovice (Boconowice)'},
  {id: '355', name: 'Bochov'},
  {id: '333', name: 'Bohdašín'},
  {id: '334', name: 'Bohdíkov'},
  {id: '337', name: 'Bohosudov zastávka'},
  {id: '338', name: 'Bohousová'},
  {id: '340', name: 'Bohumilice v Čechách'},
  {id: '339', name: 'Bohumilice v Čechách zastávka'},
  {id: '341', name: 'Bohumín'},
  {id: '345', name: 'Bohunice'},
  {id: '346', name: 'Bohuňovice'},
  {id: '5350', name: 'Bohušice'},
  {id: '348', name: 'Bohuslavice nad Metují'},
  {id: '347', name: 'Bohuslavice nad Metují zastávka'},
  {id: '349', name: 'Bohuslavice nad Úpou'},
  {id: '350', name: 'Bohuslavice nad Vláří'},
  {id: '351', name: 'Bohuslavice u Kyjova'},
  {id: '352', name: 'Bohušov'},
  {id: '353', name: 'Bohušovice nad Ohří'},
  {id: '354', name: 'Bohutice'},
  {id: '19', name: 'Bohutín'},
  {id: '357', name: 'Bojanovice'},
  {id: '358', name: 'Bojkovice'},
  {id: '359', name: 'Bojkovice město'},
  {id: '360', name: 'Bojov'},
  {id: '361', name: 'Bolatice'},
  {id: '363', name: 'Bolehošť'},
  {id: '364', name: 'Boletice nad Labem'},
  {id: '365', name: 'Bolina'},
  {id: '370', name: 'Bor'},
  {id: '371', name: 'Bor zastávka'},
  {id: '372', name: 'Borač'},
  {id: '374', name: 'Borek pod Troskami'},
  {id: '375', name: 'Borek u Tachova'},
  {id: '376', name: 'Borek u Žlutic'},
  {id: '385', name: 'Bořetice'},
  {id: '386', name: 'Boří les'},
  {id: '387', name: 'Bořice'},
  {id: '388', name: 'Bořislav'},
  {id: '378', name: 'Borohrádek'},
  {id: '380', name: 'Borová u Poličky'},
  {id: '379', name: 'Borová u Poličky zastávka'},
  {id: '381', name: 'Borovany'},
  {id: '382', name: 'Borovnice'},
  {id: '383', name: 'Borovnička'},
  {id: '20', name: 'Borovy'},
  {id: '384', name: 'Boršov nad Vltavou'},
  {id: '392', name: 'Bošice'},
  {id: '393', name: 'Bošice zastávka'},
  {id: '391', name: 'Boskovice'},
  {id: '398', name: 'Božejovice'},
  {id: '400', name: 'Božičany'},
  {id: '399', name: 'Božice u Znojma'},
  {id: '408', name: 'Brandýs nad Labem'},
  {id: '407', name: 'Brandýs nad Labem zastávka'},
  {id: '405', name: 'Brandýs nad Labem-Zápská'},
  {id: '5422', name: 'Brandýs nad Orlicí'},
  {id: '410', name: 'Brandýsek'},
  {id: '411', name: 'Branice'},
  {id: '412', name: 'Branka u Opavy'},
  {id: '413', name: 'Brankovice'},
  {id: '414', name: 'Branky na Moravě'},
  {id: '415', name: 'Branná'},
  {id: '416', name: 'Bransouze'},
  {id: '417', name: 'Brantice'},
  {id: '488', name: 'Břasy'},
  {id: '419', name: 'Bratčice'},
  {id: '431', name: 'Bratkovice'},
  {id: '489', name: 'Břeclav'},
  {id: '492', name: 'Břest'},
  {id: '493', name: 'Břetislav'},
  {id: '494', name: 'Břevnice'},
  {id: '509', name: 'Břežany'},
  {id: '508', name: 'Břežany nad Ohří'},
  {id: '496', name: 'Březí'},
  {id: '497', name: 'Březina nad Jizerou'},
  {id: '498', name: 'Březiny u Děčína'},
  {id: '499', name: 'Březnice'},
  {id: '502', name: 'Březno u Chomutova'},
  {id: '503', name: 'Březno u Mladé Boleslavi'},
  {id: '505', name: 'Březno u Postoloprt'},
  {id: '506', name: 'Březová nad Svitavou'},
  {id: '495', name: 'Březová nad Svitavou-Dlouhá'},
  {id: '507', name: 'Březová u Broumova'},
  {id: '510', name: 'Břidličná'},
  {id: '511', name: 'Břidličná lesy'},
  {id: '512', name: 'Břidličná zastávka'},
  {id: '513', name: 'Bříza'},
  {id: '3578', name: 'Bříza obec'},
  {id: '453', name: 'Brniště'},
  {id: '454', name: 'Brno dolní nádraží'},
  {id: '455', name: 'Brno hlavní nádraží'},
  {id: '459', name: 'Brno-Černovice'},
  {id: '1656', name: 'Brno-Chrlice'},
  {id: '460', name: 'Brno-Horní Heršpice'},
  {id: '461', name: 'Brno-Královo Pole'},
  {id: '462', name: 'Brno-Lesná'},
  {id: '466', name: 'Brno-Řečkovice'},
  {id: '467', name: 'Brno-Slatina'},
  {id: '7691', name: 'Brno-Starý Lískovec'},
  {id: '3006', name: 'Brno-Židenice'},
  {id: '468', name: 'Brňov'},
  {id: '469', name: 'Brod nad Tichou'},
  {id: '470', name: 'Brodek u Přerova'},
  {id: '473', name: 'Broumov'},
  {id: '474', name: 'Broumov-Olivětín'},
  {id: '475', name: 'Brozánky'},
  {id: '476', name: 'Brtníky'},
  {id: '481', name: 'Brumov'},
  {id: '482', name: 'Brumov střed'},
  {id: '483', name: 'Brumovice'},
  {id: '485', name: 'Bruntál'},
  {id: '514', name: 'Břvany'},
  {id: '517', name: 'Bučovice'},
  {id: '518', name: 'Buda'},
  {id: '528', name: 'Budčice'},
  {id: '529', name: 'Budišov nad Budišovkou'},
  {id: '530', name: 'Budišov u Třebíče'},
  {id: '531', name: 'Budkovice'},
  {id: '533', name: 'Budyně nad Ohří'},
  {id: '538', name: 'Bujanov'},
  {id: '539', name: 'Bukovno'},
  {id: '543', name: 'Butoves'},
  {id: '546', name: 'Bykáň'},
  {id: '547', name: 'Bylany'},
  {id: '548', name: 'Bylnice'},
  {id: '557', name: 'Byšice'},
  {id: '551', name: 'Bystřany v Čechách'},
  {id: '552', name: 'Bystřice (Bystrzyca)'},
  {id: '553', name: 'Bystřice nad Pernštejnem'},
  {id: '554', name: 'Bystřice pod Hostýnem'},
  {id: '555', name: 'Bystřice u Benešova'},
  {id: '556', name: 'Bystřička'},
  {id: '549', name: 'Bystrovany'},
  {id: '561', name: 'Bzenec'},
  {id: '562', name: 'Bzenec přívoz'},
  {id: '563', name: 'Bzenec-Olšovec'},
  {id: '642', name: 'Čachnov'},
  {id: '643', name: 'Čachovice'},
  {id: '647', name: 'Čáslav'},
  {id: '649', name: 'Častolovice'},
  {id: '650', name: 'Častolovice zastávka'},
  {id: '654', name: 'Čebín'},
  {id: '573', name: 'Cebiv'},
  {id: '655', name: 'Čejč'},
  {id: '657', name: 'Čejetice'},
  {id: '658', name: 'Čejkovice'},
  {id: '23', name: 'Čeladná'},
  {id: '659', name: 'Čelákovice'},
  {id: '660', name: 'Čelákovice zastávka'},
  {id: '661', name: 'Čelákovice-Jiřina'},
  {id: '662', name: 'Čelčice'},
  {id: '663', name: 'Čelechovice na Hané'},
  {id: '664', name: 'Čenkov u Malšic'},
  {id: '1253', name: 'Čeperka'},
  {id: '665', name: 'Čerčany'},
  {id: '582', name: 'Cerekvice nad Bystřicí'},
  {id: '583', name: 'Cerekvice nad Loučnou'},
  {id: '581', name: 'Cerekvice nad Loučnou zastávka'},
  {id: '584', name: 'Cerhenice'},
  {id: '585', name: 'Cerhovice'},
  {id: '667', name: 'Čermná nad Orlicí'},
  {id: '668', name: 'Čermná ve Slezsku'},
  {id: '669', name: 'Černá v Pošumaví'},
  {id: '670', name: 'Černčice'},
  {id: '671', name: 'Černíny'},
  {id: '673', name: 'Černošice'},
  {id: '674', name: 'Černošice-Mokropsy'},
  {id: '24', name: 'Černotín'},
  {id: '675', name: 'Černousy'},
  {id: '676', name: 'Černovice u Chomutova'},
  {id: '678', name: 'Černovír'},
  {id: '679', name: 'Černožice'},
  {id: '680', name: 'Černuc'},
  {id: '681', name: 'Černý Dub'},
  {id: '682', name: 'Černý Kříž'},
  {id: '683', name: 'Čertova Stěna'},
  {id: '685', name: 'Červená nad Vltavou'},
  {id: '686', name: 'Červená Voda'},
  {id: '687', name: 'Červená Voda-Pod rozhlednou'},
  {id: '25', name: 'Červené Pečky'},
  {id: '689', name: 'Červené Poříčí'},
  {id: '690', name: 'Červenka'},
  {id: '6000', name: 'Červenka zastávka'},
  {id: '691', name: 'Červený Kostelec'},
  {id: '692', name: 'Červený Potok'},
  {id: '684', name: 'Červený Újezd u Votic zastávka'},
  {id: '702', name: 'Česká'},
  {id: '694', name: 'Česká Kamenice'},
  {id: '695', name: 'Česká Kubice'},
  {id: '696', name: 'Česká Lípa hlavní nádraží'},
  {id: '633', name: 'Česká Lípa střelnice'},
  {id: '634', name: 'Česká Lípa-Holý vrch'},
  {id: '697', name: 'Česká Metuje'},
  {id: '698', name: 'Česká Skalice'},
  {id: '699', name: 'Česká Třebová'},
  {id: '701', name: 'Česká Ves'},
  {id: '700', name: 'Česká Ves bazén'},
  {id: '626', name: 'České Budějovice'},
  {id: '625', name: 'České Budějovice jižní zastávka'},
  {id: '628', name: 'České Budějovice severní zastávka'},
  {id: '703', name: 'České Hamry'},
  {id: '704', name: 'České Velenice'},
  {id: '705', name: 'Český Brod'},
  {id: '706', name: 'Český Krumlov'},
  {id: '707', name: 'Český Šternberk'},
  {id: '693', name: 'Český Šternberk zastávka'},
  {id: '708', name: 'Český Těšín'},
  {id: '712', name: 'Čestice'},
  {id: '589', name: 'Cetkovice'},
  {id: '1588', name: 'Chabařovice'},
  {id: '1589', name: 'Chabeřice'},
  {id: '1597', name: 'Charvatce'},
  {id: '1598', name: 'Charvátská Nová Ves'},
  {id: '1599', name: 'Cheb'},
  {id: '1601', name: 'Cheb-Skalka'},
  {id: '1602', name: 'Cheb-Všeboř'},
  {id: '1605', name: 'Chlum u Rakovníka'},
  {id: '1606', name: 'Chlumčany u Dobřan'},
  {id: '1607', name: 'Chlumčany u Loun'},
  {id: '1608', name: 'Chlumec nad Cidlinou'},
  {id: '34', name: 'Chlumec u Českých Budějovic'},
  {id: '1609', name: 'Chlumec u Chabařovic'},
  {id: '1610', name: 'Chlumín'},
  {id: '1611', name: 'Chmeliště'},
  {id: '1613', name: 'Choceň'},
  {id: '1614', name: 'Chocerady'},
  {id: '1615', name: 'Chodov'},
  {id: '1617', name: 'Chodová Planá'},
  {id: '1619', name: 'Chodská Lhota'},
  {id: '1620', name: 'Cholina'},
  {id: '1621', name: 'Choltice'},
  {id: '1622', name: 'Chomutov'},
  {id: '3012', name: 'Chomutov město'},
  {id: '1627', name: 'Choratice'},
  {id: '1628', name: 'Chornice'},
  {id: '1632', name: 'Chotěboř'},
  {id: '1633', name: 'Chotěbudice'},
  {id: '3013', name: 'Chotěbuz (Kocobędz)'},
  {id: '1634', name: 'Chotějovice'},
  {id: '1635', name: 'Chotěšov pod Hazmburkem'},
  {id: '1636', name: 'Chotěšov u Stoda'},
  {id: '1637', name: 'Chotětov'},
  {id: '1638', name: 'Chotěvice'},
  {id: '1639', name: 'Chotiměř'},
  {id: '1640', name: 'Chotouchov'},
  {id: '1641', name: 'Choťovice'},
  {id: '1642', name: 'Chotoviny'},
  {id: '1643', name: 'Chotutice'},
  {id: '1644', name: 'Chotýčany'},
  {id: '1645', name: 'Chotyně'},
  {id: '1646', name: 'Chrást nad Sázavou'},
  {id: '1647', name: 'Chrast u Chrudimi'},
  {id: '1649', name: 'Chrást u Plzně'},
  {id: '1650', name: 'Chrást u Plzně obec'},
  {id: '1648', name: 'Chrást u Plzně zastávka'},
  {id: '1654', name: 'Chrášťany'},
  {id: '1655', name: 'Chrášťany zastávka'},
  {id: '1651', name: 'Chrastava'},
  {id: '1652', name: 'Chrastava-Andělská Hora'},
  {id: '1653', name: 'Chrastice'},
  {id: '1664', name: 'Chřenovice'},
  {id: '1665', name: 'Chřenovice-Podhradí'},
  {id: '1666', name: 'Chřibská'},
  {id: '1657', name: 'Chroboly'},
  {id: '1658', name: 'Chropyně'},
  {id: '1659', name: 'Chroustov'},
  {id: '1660', name: 'Chrudim'},
  {id: '1661', name: 'Chrudim město'},
  {id: '1662', name: 'Chrudim zastávka'},
  {id: '1669', name: 'Chuchelná'},
  {id: '1667', name: 'Chudobín'},
  {id: '1670', name: 'Chvaleč'},
  {id: '1671', name: 'Chvalešovice'},
  {id: '1672', name: 'Chvaletice'},
  {id: '1674', name: 'Chvalkovice na Hané'},
  {id: '1675', name: 'Chvatěruby'},
  {id: '5357', name: 'Chýně'},
  {id: '5356', name: 'Chýně jih'},
  {id: '1678', name: 'Chýnov'},
  {id: '1679', name: 'Chyše'},
  {id: '713', name: 'Číčenice'},
  {id: '715', name: 'Číchov'},
  {id: '590', name: 'Cidlina'},
  {id: '596', name: 'Cihelny'},
  {id: '716', name: 'Čimelice'},
  {id: '717', name: 'Činěves'},
  {id: '597', name: 'Církvice'},
  {id: '718', name: 'Čisovice'},
  {id: '719', name: 'Čistá'},
  {id: '598', name: 'Citice'},
  {id: '599', name: 'Cítoliby'},
  {id: '600', name: 'Citonice'},
  {id: '601', name: 'Cítov'},
  {id: '720', name: 'Čížkovice'},
  {id: '721', name: 'Čížov'},
  {id: '722', name: 'Čížová'},
  {id: '723', name: 'Čkyně'},
  {id: '613', name: 'Ctiněves'},
  {id: '725', name: 'Čtyřkoly'},
  {id: '726', name: 'Čunín'},
  {id: '731', name: 'Dačice'},
  {id: '732', name: 'Dačice město'},
  {id: '736', name: 'Dalovice'},
  {id: '737', name: 'Dambořice'},
  {id: '739', name: 'Dasnice'},
  {id: '740', name: 'Davle'},
  {id: '744', name: 'Děbolín'},
  {id: '747', name: 'Děčín hlavní nádraží'},
  {id: '753', name: 'Děčín východ'},
  {id: '755', name: 'Děčín zastávka'},
  {id: '756', name: 'Děčín-Boletice město'},
  {id: '757', name: 'Děčín-Bynov'},
  {id: '758', name: 'Děčín-Čertova Voda'},
  {id: '760', name: 'Děčín-Oldřichov'},
  {id: '762', name: 'Děčín-Přípeř'},
  {id: '761', name: 'Děčín-Prostřední Žleb'},
  {id: '763', name: 'Děčín-Staré Město'},
  {id: '765', name: 'Dědice'},
  {id: '766', name: 'Dědov'},
  {id: '767', name: 'Dehtín'},
  {id: '768', name: 'Děhylov'},
  {id: '772', name: 'Denětice'},
  {id: '777', name: 'Dešenice'},
  {id: '773', name: 'Desná'},
  {id: '774', name: 'Desná-Pustinská'},
  {id: '775', name: 'Desná-Riedlova Vila'},
  {id: '1254', name: 'Deštnice'},
  {id: '779', name: 'Dětmarovice'},
  {id: '780', name: 'Dětřichov nad Bystřicí'},
  {id: '793', name: 'Dívčí Hrad'},
  {id: '794', name: 'Dívčice'},
  {id: '796', name: 'Divnice'},
  {id: '798', name: 'Dlouhá Lhota'},
  {id: '799', name: 'Dlouhá Třebová'},
  {id: '800', name: 'Dlouhé Dvory'},
  {id: '805', name: 'Dobkovice'},
  {id: '806', name: 'Dobkovičky'},
  {id: '809', name: 'Dobrá na Šumavě'},
  {id: '811', name: 'Dobrá u Frýdku-Místku'},
  {id: '812', name: 'Dobrá Voda u Březnice'},
  {id: '813', name: 'Dobrá Voda u Hořic'},
  {id: '814', name: 'Dobrá Voda u Pelhřimova'},
  {id: '827', name: 'Dobřany'},
  {id: '828', name: 'Dobřany zastávka'},
  {id: '815', name: 'Dobratice pod Prašivou'},
  {id: '816', name: 'Dobré Pole'},
  {id: '829', name: 'Dobřenice'},
  {id: '830', name: 'Dobříčany'},
  {id: '832', name: 'Dobřichovice'},
  {id: '831', name: 'Dobříčkov'},
  {id: '833', name: 'Dobříkov na Šumavě'},
  {id: '834', name: 'Dobříkov u Chocně'},
  {id: '835', name: 'Dobříň'},
  {id: '836', name: 'Dobříš'},
  {id: '818', name: 'Dobroměřice'},
  {id: '819', name: 'Dobronice u Chýnova'},
  {id: '820', name: 'Dobronín'},
  {id: '821', name: 'Dobronín zastávka'},
  {id: '822', name: 'Dobrotice'},
  {id: '823', name: 'Dobrovice'},
  {id: '824', name: 'Dobrovíz'},
  {id: '6222', name: 'Dobrovíz-Amazon'},
  {id: '825', name: 'Dobruška'},
  {id: '837', name: 'Dobšice nad Cidlinou'},
  {id: '839', name: 'Dohalice'},
  {id: '840', name: 'Doksy'},
  {id: '841', name: 'Dolánky'},
  {id: '842', name: 'Dolany nad Vltavou'},
  {id: '843', name: 'Dolejší Hůrky'},
  {id: '844', name: 'Dolenice'},
  {id: '845', name: 'Dolík'},
  {id: '846', name: 'Dolní Benešov'},
  {id: '5038', name: 'Dolní Benešov-Zábřeh'},
  {id: '847', name: 'Dolní Beřkovice'},
  {id: '848', name: 'Dolní Bolíkov'},
  {id: '849', name: 'Dolní Bousov'},
  {id: '1731', name: 'Dolní Břežany-Jarov'},
  {id: '850', name: 'Dolní Březinka'},
  {id: '851', name: 'Dolní Cerekev'},
  {id: '852', name: 'Dolní Dobrouč'},
  {id: '853', name: 'Dolní Habartice'},
  {id: '854', name: 'Dolní Kamenice'},
  {id: '855', name: 'Dolní Křečany'},
  {id: '856', name: 'Dolní Lhota'},
  {id: '857', name: 'Dolní Libchavy'},
  {id: '858', name: 'Dolní Lipka'},
  {id: '859', name: 'Dolní Loučky'},
  {id: '860', name: 'Dolní Luby'},
  {id: '861', name: 'Dolní Lutyně'},
  {id: '862', name: 'Dolní Orlice'},
  {id: '863', name: 'Dolní Ostrovec'},
  {id: '864', name: 'Dolní Podluží'},
  {id: '865', name: 'Dolní Polubný'},
  {id: '866', name: 'Dolní Poříčí'},
  {id: '867', name: 'Dolní Poustevna'},
  {id: '869', name: 'Dolní Smrčné'},
  {id: '870', name: 'Dolní Stupno'},
  {id: '871', name: 'Dolní Zálezly'},
  {id: '872', name: 'Dolní Žandov'},
  {id: '873', name: 'Dolní Životice'},
  {id: '874', name: 'Dolní Žleb'},
  {id: '875', name: 'Dolní Žleb zastávka'},
  {id: '876', name: 'Doloplazy'},
  {id: '877', name: 'Doly'},
  {id: '878', name: 'Domanice'},
  {id: '879', name: 'Domašín'},
  {id: '880', name: 'Domašov nad Bystřicí'},
  {id: '881', name: 'Domažlice'},
  {id: '882', name: 'Domažlice město'},
  {id: '885', name: 'Domina'},
  {id: '5777', name: 'Domoradice'},
  {id: '889', name: 'Domoušice'},
  {id: '892', name: 'Doňov'},
  {id: '897', name: 'Doubí u Karlových Varů'},
  {id: '898', name: 'Doubí u Tábora'},
  {id: '28', name: 'Doubí u Turnova'},
  {id: '900', name: 'Doubravice nad Svitavou'},
  {id: '901', name: 'Doubravník'},
  {id: '902', name: 'Doudleby nad Orlicí'},
  {id: '904', name: 'Drahanovice'},
  {id: '905', name: 'Drahobudice'},
  {id: '906', name: 'Drahotuše'},
  {id: '918', name: 'Dřetovice'},
  {id: '919', name: 'Dřísy'},
  {id: '917', name: 'Droužkovice'},
  {id: '920', name: 'Dubany'},
  {id: '921', name: 'Dubec'},
  {id: '922', name: 'Dubí'},
  {id: '923', name: 'Dubičná'},
  {id: '925', name: 'Dubová Lhota'},
  {id: '928', name: 'Duchcov'},
  {id: '944', name: 'Dvorce'},
  {id: '947', name: 'Dvořisko'},
  {id: '945', name: 'Dvory nad Lužnicí'},
  {id: '948', name: 'Dvůr Králové nad Labem'},
  {id: '951', name: 'Dyje'},
  {id: '952', name: 'Dymokury'},
  {id: '6718', name: 'Dynín zastávka'},
  {id: '954', name: 'Dýšina-Horomyslice'},
  {id: '956', name: 'Džbánov'},
  {id: '955', name: 'Dzbel'},
  {id: '966', name: 'Ejpovice'},
  {id: '995', name: 'Filipovka'},
  {id: '1016', name: 'Frahelž'},
  {id: '1025', name: 'Františkov nad Ploučnicí'},
  {id: '1026', name: 'Františkovy Lázně'},
  {id: '1024', name: 'Františkovy Lázně-Aquaforum'},
  {id: '1034', name: 'Frenštát pod Radhoštěm'},
  {id: '1035', name: 'Frenštát pod Radhoštěm město'},
  {id: '1041', name: 'Frýdek-Místek'},
  {id: '1045', name: 'Frýdlant nad Ostravicí'},
  {id: '1044', name: 'Frýdlant nad Ostravicí zastávka'},
  {id: '1043', name: 'Frýdlant nad Ostravicí-Nová Dědina'},
  {id: '1047', name: 'Frýdlant v Čechách'},
  {id: '1042', name: 'Frýdlant v Čechách předměstí'},
  {id: '1050', name: 'Fulnek'},
  {id: '1098', name: 'Golčův Jeníkov'},
  {id: '1099', name: 'Golčův Jeníkov město'},
  {id: '1122', name: 'Grešlové Mýto'},
  {id: '1136', name: 'Grygov'},
  {id: '1161', name: 'Háj u Duchcova'},
  {id: '1162', name: 'Háj ve Slezsku'},
  {id: '1163', name: 'Hájek'},
  {id: '1164', name: 'Hajniště'},
  {id: '1165', name: 'Halenkov'},
  {id: '1166', name: 'Halenkov zastávka'},
  {id: '1193', name: 'Hamry nad Sázavou'},
  {id: '1194', name: 'Hamry-Hojsova Stráž'},
  {id: '1200', name: 'Hanušovice'},
  {id: '1201', name: 'Hanušovice Holba'},
  {id: '1202', name: 'Hanušovice zastávka'},
  {id: '1204', name: 'Harrachov'},
  {id: '1206', name: 'Hatě'},
  {id: '1208', name: 'Havířov'},
  {id: '5776', name: 'Havířov střed'},
  {id: '1209', name: 'Havířov-Suchá'},
  {id: '1212', name: 'Havlíčkův Brod'},
  {id: '1211', name: 'Havlíčkův Brod-Perknov'},
  {id: '1213', name: 'Havlovice'},
  {id: '1215', name: 'Havřice'},
  {id: '1216', name: 'Hazlov'},
  {id: '1225', name: 'Hejnice'},
  {id: '1233', name: 'Herálec'},
  {id: '1234', name: 'Herbertov'},
  {id: '1243', name: 'Heřmaň'},
  {id: '1242', name: 'Heřmaň obec'},
  {id: '1244', name: 'Heřmaničky'},
  {id: '1245', name: 'Heřmánky'},
  {id: '1246', name: 'Heřmanova Huť'},
  {id: '1247', name: 'Heřmanův Městec'},
  {id: '1239', name: 'Heroltice'},
  {id: '1249', name: 'Hevlín'},
  {id: '1267', name: 'Hladké Životice'},
  {id: '1266', name: 'Hladké Životice místní nádraží'},
  {id: '1268', name: 'Hlavno'},
  {id: '1269', name: 'Hleďsebe'},
  {id: '1270', name: 'Hlinsko pod Hostýnem'},
  {id: '1271', name: 'Hlinsko v Čechách'},
  {id: '1272', name: 'Hlinsko-Kouty'},
  {id: '1273', name: 'Hlízov'},
  {id: '1274', name: 'Hlubočky'},
  {id: '1275', name: 'Hlubočky zastávka'},
  {id: '1276', name: 'Hlubočky-Mariánské Údolí'},
  {id: '1278', name: 'Hluboká nad Vltavou'},
  {id: '1277', name: 'Hluboká nad Vltavou-Zámostí'},
  {id: '1279', name: 'Hluboká u Borovan'},
  {id: '1281', name: 'Hluboký Důl'},
  {id: '1282', name: 'Hlučín'},
  {id: '1283', name: 'Hlušovice'},
  {id: '1284', name: 'Hnátnice'},
  {id: '1285', name: 'Hněvčeves'},
  {id: '1286', name: 'Hněvice'},
  {id: '1290', name: 'Hnojník'},
  {id: '1292', name: 'Hodice'},
  {id: '1293', name: 'Hodkov'},
  {id: '1294', name: 'Hodkov zastávka'},
  {id: '1295', name: 'Hodkovice nad Mohelkou'},
  {id: '1296', name: 'Hodkovice u Trutnova'},
  {id: '1297', name: 'Hodňov'},
  {id: '1298', name: 'Hodonice'},
  {id: '1299', name: 'Hodonín'},
  {id: '1301', name: 'Hodonín zastávka'},
  {id: '1309', name: 'Hojsova Stráž-Brčálník'},
  {id: '1255', name: 'Holasovice'},
  {id: '1311', name: 'Holečkov'},
  {id: '1312', name: 'Holedeček'},
  {id: '1313', name: 'Holešov'},
  {id: '1314', name: 'Holetice'},
  {id: '1315', name: 'Holetín'},
  {id: '1316', name: 'Holice'},
  {id: '1317', name: 'Holice zastávka'},
  {id: '1319', name: 'Holkov'},
  {id: '1321', name: 'Holostřevy'},
  {id: '1322', name: 'Holoubkov'},
  {id: '1324', name: 'Holubov'},
  {id: '1325', name: 'Holýšov'},
  {id: '31', name: 'Hořátev'},
  {id: '1327', name: 'Horažďovice'},
  {id: '1328', name: 'Horažďovice předměstí'},
  {id: '1378', name: 'Hořesedly'},
  {id: '1379', name: 'Hořetice'},
  {id: '1380', name: 'Hořice na Šumavě'},
  {id: '1381', name: 'Hořice v Podkrkonoší'},
  {id: '1382', name: 'Hořiněves'},
  {id: '1330', name: 'Horka nad Moravou'},
  {id: '1331', name: 'Horka nad Sázavou'},
  {id: '1332', name: 'Horka u Chrudimi'},
  {id: '1333', name: 'Horka u Staré Paky'},
  {id: '1334', name: 'Horky u Čáslavi'},
  {id: '1335', name: 'Horky u Tábora'},
  {id: '1338', name: 'Horní Adršpach'},
  {id: '1339', name: 'Horní Beřkovice'},
  {id: '1340', name: 'Horní Blatná'},
  {id: '1341', name: 'Horní Branná'},
  {id: '1342', name: 'Horní Bříza'},
  {id: '1343', name: 'Horní Bříza zastávka'},
  {id: '1344', name: 'Horní Cerekev'},
  {id: '1345', name: 'Horní Dvořiště'},
  {id: '1346', name: 'Horní Háj'},
  {id: '1347', name: 'Horní Heřmanice'},
  {id: '1348', name: 'Horní Hradiště'},
  {id: '1350', name: 'Horní Kamenice'},
  {id: '1351', name: 'Horní Ledeč'},
  {id: '1352', name: 'Horní Lideč'},
  {id: '1354', name: 'Horní Lipová'},
  {id: '1355', name: 'Horní Moštěnice'},
  {id: '1356', name: 'Horní Nerestce'},
  {id: '1357', name: 'Horní Nová Ves'},
  {id: '1358', name: 'Horní Planá'},
  {id: '1359', name: 'Horní Planá zastávka'},
  {id: '1360', name: 'Horní Počaply'},
  {id: '1361', name: 'Horní Podluží'},
  {id: '1362', name: 'Horní Police'},
  {id: '1363', name: 'Horní Poustevna'},
  {id: '1364', name: 'Horní Povelice'},
  {id: '1365', name: 'Horní Řasnice'},
  {id: '1366', name: 'Horní Řepčice'},
  {id: '1368', name: 'Horní Slavkov'},
  {id: '2340', name: 'Horní Slavkov - Kounice'},
  {id: '1154', name: 'Horní Slavkov zastávka'},
  {id: '1369', name: 'Horní Suchá'},
  {id: '1370', name: 'Horní Sytová'},
  {id: '1371', name: 'Horní Tošanovice'},
  {id: '1372', name: 'Horní Ves'},
  {id: '1373', name: 'Horní Vilímeč'},
  {id: '1374', name: 'Horní Vltavice'},
  {id: '1383', name: 'Hořovice'},
  {id: '1376', name: 'Horšovský Týn'},
  {id: '1377', name: 'Horusice'},
  {id: '32', name: 'Hosín'},
  {id: '1385', name: 'Hostašovice'},
  {id: '1396', name: 'Hoštěc'},
  {id: '5419', name: 'Hoštejn'},
  {id: '1386', name: 'Hostěrádky-Rešov'},
  {id: '1387', name: 'Hostětín'},
  {id: '1398', name: 'Hoštice u Volyně'},
  {id: '1399', name: 'Hoštice-Heroltice'},
  {id: '1388', name: 'Hostinné'},
  {id: '1389', name: 'Hostinné město'},
  {id: '1391', name: 'Hostivice'},
  {id: '1390', name: 'Hostivice-Litovice'},
  {id: '5359', name: 'Hostivice-Sadová'},
  {id: '5358', name: 'Hostivice-U hřbitova'},
  {id: '1400', name: 'Hoštka'},
  {id: '1392', name: 'Hostomice nad Bílinou'},
  {id: '1393', name: 'Hostomice pod Brdy'},
  {id: '1394', name: 'Hostouň'},
  {id: '1395', name: 'Hostouň u Prahy'},
  {id: '1401', name: 'Hovězí'},
  {id: '33', name: 'Hovorčovice'},
  {id: '1523', name: 'Hrabačov'},
  {id: '1524', name: 'Hrabětice'},
  {id: '1525', name: 'Hrabišín'},
  {id: '1543', name: 'Hrachovec'},
  {id: '1527', name: 'Hradčany'},
  {id: '1526', name: 'Hradce'},
  {id: '1528', name: 'Hradčovice'},
  {id: '1529', name: 'Hradec Králové hlavní nádraží'},
  {id: '1427', name: 'Hradec Králové zastávka'},
  {id: '1428', name: 'Hradec Králové-Kukleny'},
  {id: '1429', name: 'Hradec Králové-Slezské Předměstí'},
  {id: '1530', name: 'Hradec nad Moravicí'},
  {id: '1531', name: 'Hradec nad Svitavou'},
  {id: '1532', name: 'Hradec u Kadaně'},
  {id: '1533', name: 'Hradec u Stoda'},
  {id: '1534', name: 'Hradec-Nová Ves'},
  {id: '1539', name: 'Hrádek (Gródek)'},
  {id: '1536', name: 'Hrádek nad Nisou'},
  {id: '1537', name: 'Hrádek u Rokycan'},
  {id: '1538', name: 'Hrádek u Sušice'},
  {id: '1540', name: 'Hradiště u Blatné'},
  {id: '1541', name: 'Hradiště v Čechách'},
  {id: '1545', name: 'Hranice na Moravě'},
  {id: '1544', name: 'Hranice na Moravě město'},
  {id: '1546', name: 'Hranice v Čechách'},
  {id: '1548', name: 'Hrdějovice'},
  {id: '1549', name: 'Hrdlořezy'},
  {id: '1550', name: 'Hrdly'},
  {id: '1551', name: 'Hrdoňovice'},
  {id: '1569', name: 'Hříběcí'},
  {id: '1570', name: 'Hřivice'},
  {id: '1552', name: 'Hrob'},
  {id: '1553', name: 'Hrobce'},
  {id: '1554', name: 'Hrochův Týnec'},
  {id: '1555', name: 'Hronov'},
  {id: '1556', name: 'Hronov zastávka'},
  {id: '1558', name: 'Hroznětín'},
  {id: '1559', name: 'Hroznětín zastávka'},
  {id: '1560', name: 'Hrubá Skála'},
  {id: '1561', name: 'Hrubá Voda'},
  {id: '1562', name: 'Hrubá Voda zastávka'},
  {id: '1563', name: 'Hrubá Voda-Smilov'},
  {id: '4919', name: 'Hrušky'},
  {id: '1564', name: 'Hrušky zastávka'},
  {id: '1565', name: 'Hrušová'},
  {id: '1566', name: 'Hrušovany nad Jevišovkou-Šanov'},
  {id: '1567', name: 'Hrušovany u Brna'},
  {id: '1571', name: 'Hudčice'},
  {id: '1573', name: 'Hulín'},
  {id: '1575', name: 'Humpolec'},
  {id: '1577', name: 'Husinec'},
  {id: '1578', name: 'Huslenky'},
  {id: '1579', name: 'Huslenky zastávka'},
  {id: '1583', name: 'Huštěnovice'},
  {id: '1580', name: 'Hustopeče nad Bečvou'},
  {id: '1581', name: 'Hustopeče u Brna'},
  {id: '1584', name: 'Hvězdonice'},
  {id: '1585', name: 'Hvězdoňovice'},
  {id: '1586', name: 'Hynčice'},
  {id: '1587', name: 'Hýskov'},
  {id: '1695', name: 'Ivančice'},
  {id: '1696', name: 'Ivančice letovisko'},
  {id: '1697', name: 'Ivančice město'},
  {id: '1698', name: 'Ivanovice na Hané'},
  {id: '1702', name: 'Jablonec nad Jizerou'},
  {id: '1701', name: 'Jablonec nad Jizerou-Hradsko'},
  {id: '1705', name: 'Jablonec nad Nisou'},
  {id: '1700', name: 'Jablonec nad Nisou centrum'},
  {id: '1703', name: 'Jablonec nad Nisou dolní nádraží'},
  {id: '1704', name: 'Jablonec nad Nisou zastávka'},
  {id: '1706', name: 'Jablonecké Paseky'},
  {id: '1707', name: 'Jablonné nad Orlicí'},
  {id: '1708', name: 'Jablonné v Podještědí'},
  {id: '1709', name: 'Jablůnka'},
  {id: '1710', name: 'Jackov'},
  {id: '1712', name: 'Jakartovice'},
  {id: '1713', name: 'Jakubčovice nad Odrou'},
  {id: '1715', name: 'Jamartice'},
  {id: '1717', name: 'Jamné nad Orlicí'},
  {id: '1719', name: 'Jankovice'},
  {id: '1721', name: 'Janová'},
  {id: '1722', name: 'Janovice nad Úhlavou'},
  {id: '1723', name: 'Janovice u Trutnova'},
  {id: '1726', name: 'Jarohněvice'},
  {id: '1727', name: 'Jaroměř'},
  {id: '1728', name: 'Jaroměř zastávka'},
  {id: '1729', name: 'Jaroměřice nad Rokytnou'},
  {id: '1730', name: 'Jarošov nad Nežárkou'},
  {id: '1733', name: 'Javorník nad Veličkou zastávka'},
  {id: '1734', name: 'Javorník ve Slezsku'},
  {id: '1738', name: 'Jedlová'},
  {id: '1743', name: 'Jemnice'},
  {id: '1746', name: 'Jeneč'},
  {id: '1747', name: 'Jeneč zastávka'},
  {id: '7680', name: 'Jeníkov-Oldřichov'},
  {id: '1748', name: 'Jeníkovice'},
  {id: '1750', name: 'Jeřice'},
  {id: '1751', name: 'Jeřmanice'},
  {id: '1752', name: 'Jesenec'},
  {id: '1754', name: 'Jesenice'},
  {id: '1755', name: 'Jeseník'},
  {id: '1756', name: 'Jeseník nad Odrou'},
  {id: '1757', name: 'Jesenný'},
  {id: '1762', name: 'Ješetice'},
  {id: '1760', name: 'Jestřabice'},
  {id: '1761', name: 'Jestřebí'},
  {id: '1764', name: 'Jetenovice'},
  {id: '1765', name: 'Jetětice'},
  {id: '1766', name: 'Jevíčko'},
  {id: '1767', name: 'Jevišovka'},
  {id: '1768', name: 'Jezdovice'},
  {id: '1770', name: 'Jičín'},
  {id: '1771', name: 'Jičín zastávka'},
  {id: '1772', name: 'Jičíněves'},
  {id: '1773', name: 'Jihlava'},
  {id: '1774', name: 'Jihlava město'},
  {id: '1775', name: 'Jihlava-Bosch Diesel'},
  {id: '1776', name: 'Jihlava-Staré Hory'},
  {id: '1777', name: 'Jihlávka'},
  {id: '1778', name: 'Jíkev'},
  {id: '1779', name: 'Jilemnice'},
  {id: '1780', name: 'Jilešovice'},
  {id: '1781', name: 'Jílové u Děčína'},
  {id: '1782', name: 'Jílové u Prahy'},
  {id: '1783', name: 'Jílovice'},
  {id: '1784', name: 'Jimlín'},
  {id: '1785', name: 'Jince'},
  {id: '1790', name: 'Jindřichov na Moravě'},
  {id: '1791', name: 'Jindřichov ve Slezsku'},
  {id: '1792', name: 'Jindřichovice pod Smrkem'},
  {id: '4017', name: 'Jindřichovice pod Smrkem-Skanzen'},
  {id: '1793', name: 'Jindřichův Hradec'},
  {id: '5354', name: 'Jinočany'},
  {id: '1796', name: 'Jinolice'},
  {id: '1799', name: 'Jiřetín pod Bukovou'},
  {id: '1256', name: 'Jiřetín pod Jedlovou'},
  {id: '1800', name: 'Jiřice'},
  {id: '1803', name: 'Jiříkov-Filipov'},
  {id: '1797', name: 'Jirkov'},
  {id: '1798', name: 'Jirkov zastávka'},
  {id: '1804', name: 'Jistebník'},
  {id: '1805', name: 'Jivany'},
  {id: '1257', name: 'Jívová'},
  {id: '1807', name: 'Josefův Důl'},
  {id: '1258', name: 'Kačice'},
  {id: '1814', name: 'Kácov'},
  {id: '1815', name: 'Kácov zastávka'},
  {id: '1817', name: 'Kadaň'},
  {id: '1818', name: 'Kadaň předměstí'},
  {id: '7640', name: 'Kadaň-Bystřice'},
  {id: '1819', name: 'Kadaň-Prunéřov'},
  {id: '1820', name: 'Kadaňský Rohozec'},
  {id: '1822', name: 'Kájov'},
  {id: '1832', name: 'Kamenec'},
  {id: '1834', name: 'Kamenice u Humpolce'},
  {id: '1836', name: 'Kamenná'},
  {id: '1837', name: 'Kamenné Zboží'},
  {id: '1838', name: 'Kamenné Žehrovice'},
  {id: '4921', name: 'Kamenný Malíkov výhybna'},
  {id: '1811', name: 'Kamenný Most u Kralup nad Vltavou'},
  {id: '1839', name: 'Kamenný Přívoz'},
  {id: '1840', name: 'Kamenný Újezd u Českých Budějovic'},
  {id: '1812', name: 'Kamenný Újezd u Českých Budějovic zastávka'},
  {id: '1830', name: 'Kamenný Újezd u Nýřan'},
  {id: '1831', name: 'Kamenný Újezd u Rokycan'},
  {id: '1845', name: 'Kanina'},
  {id: '1848', name: 'Kaple'},
  {id: '1849', name: 'Kaplice'},
  {id: '1853', name: 'Káranice'},
  {id: '1854', name: 'Kardašova Řečice'},
  {id: '5233', name: 'Kařez'},
  {id: '1875', name: 'Kařízek'},
  {id: '1855', name: 'Karlov pod Ještědem'},
  {id: '1859', name: 'Karlovice-Sedmihorky'},
  {id: '1860', name: 'Karlovy Vary'},
  {id: '1861', name: 'Karlovy Vary dolní nádraží'},
  {id: '7677', name: 'Karlovy Vary-Aréna'},
  {id: '1813', name: 'Karlovy Vary-Březová'},
  {id: '3016', name: 'Karlovy Vary-Dvory'},
  {id: '1867', name: 'Karlštejn'},
  {id: '1869', name: 'Karolinka'},
  {id: '1870', name: 'Karolinka zastávka'},
  {id: '1871', name: 'Karviná hlavní nádraží'},
  {id: '1873', name: 'Karviná-Darkov'},
  {id: '1876', name: 'Kasejovice'},
  {id: '1877', name: 'Kasejovice zastávka'},
  {id: '1882', name: 'Kaštice'},
  {id: '1884', name: 'Katovice'},
  {id: '1888', name: 'Katusice'},
  {id: '1892', name: 'Kaznějov'},
  {id: '1893', name: 'Kdyně'},
  {id: '1902', name: 'Kfely'},
  {id: '1918', name: 'Klabava'},
  {id: '1919', name: 'Kladno'},
  {id: '1920', name: 'Kladno město'},
  {id: '1921', name: 'Kladno-Dubí'},
  {id: '1922', name: 'Kladno-Ostrovec'},
  {id: '1923', name: 'Kladno-Rozdělov'},
  {id: '1924', name: 'Kladno-Švermov'},
  {id: '1925', name: 'Kladno-Vrapice'},
  {id: '1927', name: 'Klášterec nad Ohří'},
  {id: '1928', name: 'Klášterská Lhota'},
  {id: '1929', name: 'Klatovy'},
  {id: '1930', name: 'Klatovy město'},
  {id: '1933', name: 'Klenčí pod Čerchovem'},
  {id: '1934', name: 'Kleneč'},
  {id: '1935', name: 'Klešice'},
  {id: '1936', name: 'Klínec'},
  {id: '1939', name: 'Klobouky u Brna'},
  {id: '1940', name: 'Klobuky v Čechách'},
  {id: '1943', name: 'Klokočov'},
  {id: '1944', name: 'Klučov'},
  {id: '2022', name: 'Kmetiněves'},
  {id: '2024', name: 'Kněževes'},
  {id: '2025', name: 'Kněžice'},
  {id: '2026', name: 'Kněžmost'},
  {id: '2027', name: 'Kněžnice'},
  {id: '2029', name: 'Knínice u Boskovic'},
  {id: '2035', name: 'Koberno'},
  {id: '2037', name: 'Kobylá nad Vidnavkou'},
  {id: '2038', name: 'Kobylí na Moravě'},
  {id: '2040', name: 'Kojatín'},
  {id: '2041', name: 'Kojetice na Moravě'},
  {id: '38', name: 'Kojetice u Prahy'},
  {id: '2042', name: 'Kojetín'},
  {id: '2043', name: 'Kojice'},
  {id: '2044', name: 'Kojovice'},
  {id: '2045', name: 'Kokašice'},
  {id: '2051', name: 'Koleč'},
  {id: '2052', name: 'Kolešovice'},
  {id: '2053', name: 'Kolín'},
  {id: '2054', name: 'Kolín dílny'},
  {id: '2055', name: 'Kolín místní nádraží'},
  {id: '2060', name: 'Kolín zastávka'},
  {id: '2063', name: 'Kolín-Zálabí'},
  {id: '2061', name: 'Kolinec'},
  {id: '2065', name: 'Kolomuty'},
  {id: '2071', name: 'Komňátka'},
  {id: '2073', name: 'Konětopy'},
  {id: '2075', name: 'Konice'},
  {id: '2081', name: 'Konstantinovy Lázně'},
  {id: '2085', name: 'Kopidlno'},
  {id: '2087', name: 'Kopřivnice'},
  {id: '2089', name: 'Kopřivnice zastávka'},
  {id: '2096', name: 'Kořenice'},
  {id: '2097', name: 'Kořenov'},
  {id: '2098', name: 'Kořenov zastávka'},
  {id: '2091', name: 'Kornatice'},
  {id: '2090', name: 'Kornatice rybník'},
  {id: '2116', name: 'Košátky'},
  {id: '2100', name: 'Kosobody'},
  {id: '2101', name: 'Kosova Hora'},
  {id: '2120', name: 'Košťálov'},
  {id: '2104', name: 'Kostelany nad Moravou'},
  {id: '2106', name: 'Kostelec na Hané'},
  {id: '2107', name: 'Kostelec nad Labem'},
  {id: '2108', name: 'Kostelec nad Orlicí'},
  {id: '2105', name: 'Kostelec nad Orlicí město'},
  {id: '2110', name: 'Kostelec u Heřmanova Městce'},
  {id: '2109', name: 'Kostelec u Heřmanova Městce-Písník'},
  {id: '2111', name: 'Kostelec u Jihlavy'},
  {id: '2103', name: 'Kostelec u Jihlavy masna'},
  {id: '2112', name: 'Kostěnice'},
  {id: '2121', name: 'Koštice nad Ohří'},
  {id: '2113', name: 'Kostomlaty nad Labem'},
  {id: '2114', name: 'Kostomlaty pod Řípem'},
  {id: '2122', name: 'Koštov'},
  {id: '2123', name: 'Kotojedy'},
  {id: '2125', name: 'Kotouň'},
  {id: '2127', name: 'Kotvina'},
  {id: '2128', name: 'Kounov'},
  {id: '2129', name: 'Kouřim'},
  {id: '2130', name: 'Kout na Šumavě'},
  {id: '2132', name: 'Kovač'},
  {id: '2134', name: 'Kovářská'},
  {id: '2135', name: 'Kovářská městys'},
  {id: '2133', name: 'Kováry'},
  {id: '2136', name: 'Kovčín'},
  {id: '2142', name: 'Kožlany'},
  {id: '2140', name: 'Kozmice'},
  {id: '2141', name: 'Kozolupy'},
  {id: '2143', name: 'Kožušany'},
  {id: '2145', name: 'Krahulov'},
  {id: '2148', name: 'Kralice nad Oslavou'},
  {id: '2149', name: 'Kraličky'},
  {id: '2150', name: 'Králíky'},
  {id: '2151', name: 'Králíky zastávka'},
  {id: '2154', name: 'Královec'},
  {id: '2156', name: 'Kralovice u Rakovníka'},
  {id: '2157', name: 'Královice u Zlonic'},
  {id: '2158', name: 'Královské Poříčí'},
  {id: '2161', name: 'Kralupy nad Vltavou'},
  {id: '2160', name: 'Kralupy nad Vltavou předměstí'},
  {id: '39', name: 'Kralupy nad Vltavou-Minice'},
  {id: '2162', name: 'Králův Dvůr'},
  {id: '2147', name: 'Králův Dvůr-Popovice'},
  {id: '5421', name: 'Krasíkov'},
  {id: '2168', name: 'Krásná Lípa'},
  {id: '2169', name: 'Krásná Lípa město'},
  {id: '2170', name: 'Krásná Studánka'},
  {id: '2171', name: 'Krásné Loučky'},
  {id: '2174', name: 'Krásný Dvůr'},
  {id: '2175', name: 'Krásný Jez'},
  {id: '2176', name: 'Krásný Jez zastávka'},
  {id: '2177', name: 'Krásný Les'},
  {id: '2144', name: 'Krásný Les bažantnice'},
  {id: '2178', name: 'Krasoňovice'},
  {id: '40', name: 'Kratonohy'},
  {id: '2179', name: 'Kravaře v Čechách'},
  {id: '2180', name: 'Kravaře ve Slezsku'},
  {id: '2181', name: 'Kravaře-Kouty'},
  {id: '2210', name: 'Křemenec'},
  {id: '2211', name: 'Křemže'},
  {id: '2212', name: 'Křenov'},
  {id: '2213', name: 'Křenovice dolní nádraží'},
  {id: '2214', name: 'Křenovice horní nádraží'},
  {id: '2215', name: 'Křenovy'},
  {id: '2217', name: 'Křešice u Děčína'},
  {id: '2218', name: 'Křešice u Litoměřic'},
  {id: '2216', name: 'Křesín'},
  {id: '2187', name: 'Krhanice'},
  {id: '2188', name: 'Krhová'},
  {id: '2219', name: 'Křimov'},
  {id: '2220', name: 'Křimov zastávka'},
  {id: '2221', name: 'Křimov-Suchdol'},
  {id: '2222', name: 'Křinec'},
  {id: '2223', name: 'Křivoklát'},
  {id: '2224', name: 'Křižanov'},
  {id: '2225', name: 'Křižanovice'},
  {id: '2226', name: 'Křižany'},
  {id: '2192', name: 'Krnov'},
  {id: '2193', name: 'Krnov-Cvilín'},
  {id: '2194', name: 'Krnsko'},
  {id: '2195', name: 'Kroměříž'},
  {id: '2196', name: 'Kroměříž-Oskol'},
  {id: '2198', name: 'Kropáčova Vrutice'},
  {id: '2199', name: 'Krouna'},
  {id: '2200', name: 'Krouna zastávka'},
  {id: '2201', name: 'Krty'},
  {id: '2202', name: 'Krumvíř'},
  {id: '2203', name: 'Krupá'},
  {id: '2204', name: 'Krupka'},
  {id: '2205', name: 'Krupka město'},
  {id: '336', name: 'Krupka-Bohosudov'},
  {id: '2206', name: 'Kryry'},
  {id: '2207', name: 'Kryštofovo Údolí'},
  {id: '2228', name: 'Ktová'},
  {id: '2229', name: 'Kubova Huť'},
  {id: '2231', name: 'Kuks'},
  {id: '2234', name: 'Kunčice nad Labem'},
  {id: '2235', name: 'Kunčice pod Ondřejníkem'},
  {id: '2236', name: 'Kunčina'},
  {id: '2239', name: 'Kunovice'},
  {id: '2242', name: 'Kunovice zastávka'},
  {id: '2243', name: 'Kunovice-Loučka'},
  {id: '2247', name: 'Kuřim'},
  {id: '2248', name: 'Kutná Hora hlavní nádraží'},
  {id: '2249', name: 'Kutná Hora město'},
  {id: '2250', name: 'Kutná Hora předměstí'},
  {id: '2251', name: 'Kutná Hora-Sedlec'},
  {id: '2256', name: 'Květná'},
  {id: '2257', name: 'Květná zastávka'},
  {id: '2258', name: 'Kyje u Jičína'},
  {id: '2259', name: 'Kyjice'},
  {id: '2260', name: 'Kyjov'},
  {id: '2261', name: 'Kyjov zastávka'},
  {id: '2262', name: 'Kylešovice'},
  {id: '2263', name: 'Kynšperk nad Ohří'},
  {id: '2267', name: 'Kytlice'},
  {id: '2272', name: 'Ladná'},
  {id: '2275', name: 'Lampertice'},
  {id: '2279', name: 'Lanškroun'},
  {id: '2280', name: 'Lanšperk'},
  {id: '2281', name: 'Lanžhot'},
  {id: '2285', name: 'Lašovice'},
  {id: '2286', name: 'Laštovičky'},
  {id: '2291', name: 'Laziště'},
  {id: '2292', name: 'Lázně Bělohrad'},
  {id: '2293', name: 'Lázně Kynžvart'},
  {id: '2294', name: 'Lázně Toušeň'},
  {id: '2296', name: 'Lbín'},
  {id: '2297', name: 'Lčovice'},
  {id: '2299', name: 'Ledeč nad Sázavou'},
  {id: '2300', name: 'Ledečko'},
  {id: '2302', name: 'Ledečky'},
  {id: '2304', name: 'Lednice'},
  {id: '2305', name: 'Lednice rybníky'},
  {id: '2317', name: 'Lenešice'},
  {id: '2318', name: 'Lenora'},
  {id: '2319', name: 'Lenora zastávka'},
  {id: '2327', name: 'Leskovec'},
  {id: '2328', name: 'Leskovice'},
  {id: '2331', name: 'Leština u Světlé'},
  {id: '6015', name: 'Lesůňky'},
  {id: '2332', name: 'Letohrad'},
  {id: '2333', name: 'Letovice'},
  {id: '2334', name: 'Letovice zastávka'},
  {id: '2338', name: 'Levínská Olešnice'},
  {id: '2339', name: 'Ležky'},
  {id: '2341', name: 'Lhota pod Horami'},
  {id: '2342', name: 'Lhota pod Libčany'},
  {id: '2343', name: 'Lhota pod Přeloučí'},
  {id: '42', name: 'Lhota u Opavy'},
  {id: '2344', name: 'Lhota u Trutnova'},
  {id: '2345', name: 'Lhota Veselka'},
  {id: '2346', name: 'Lhotice u Jemnice'},
  {id: '2347', name: 'Lhotka nad Bečvou'},
  {id: '2349', name: 'Lhotka u Mělníka'},
  {id: '2348', name: 'Lhotka u Mělníka zastávka'},
  {id: '2353', name: 'Libčice nad Vltavou'},
  {id: '2352', name: 'Libčice nad Vltavou-Letky'},
  {id: '2354', name: 'Libeč'},
  {id: '2355', name: 'Liběchov'},
  {id: '2356', name: 'Libějice'},
  {id: '2357', name: 'Libějovice'},
  {id: '2358', name: 'Liberec'},
  {id: '2361', name: 'Liberec-Horní Růžodol'},
  {id: '2362', name: 'Liberec-Rochlice'},
  {id: '2363', name: 'Liběšice'},
  {id: '2364', name: 'Libice nad Cidlinou'},
  {id: '2365', name: 'Libina'},
  {id: '2366', name: 'Libkovice'},
  {id: '2367', name: 'Libočany'},
  {id: '2368', name: 'Libochovany'},
  {id: '2369', name: 'Libochovice'},
  {id: '2370', name: 'Libochovice město'},
  {id: '2371', name: 'Libomyšl'},
  {id: '2372', name: 'Libošovice'},
  {id: '2373', name: 'Libouchec'},
  {id: '2374', name: 'Libštát'},
  {id: '2375', name: 'Libuň'},
  {id: '2376', name: 'Libuň zastávka'},
  {id: '2385', name: 'Lichkov'},
  {id: '2378', name: 'Lidečko'},
  {id: '2377', name: 'Lidečko ves'},
  {id: '2389', name: 'Linhartice'},
  {id: '2390', name: 'Linhartovy'},
  {id: '2391', name: 'Líny'},
  {id: '2401', name: 'Lípa'},
  {id: '2404', name: 'Lípa nad Dřevnicí'},
  {id: '2405', name: 'Lípa nad Orlicí'},
  {id: '2406', name: 'Lipka'},
  {id: '2407', name: 'Lipnice'},
  {id: '2408', name: 'Lipník nad Bečvou'},
  {id: '2409', name: 'Lipno nad Vltavou'},
  {id: '2410', name: 'Lipov'},
  {id: '2411', name: 'Lipová Lázně'},
  {id: '2412', name: 'Lipová Lázně jeskyně'},
  {id: '2413', name: 'Lipová Lázně zastávka'},
  {id: '2414', name: 'Lipová u Chebu'},
  {id: '2415', name: 'Lipová u Šluknova'},
  {id: '2416', name: 'Liptaň'},
  {id: '2420', name: 'Lišany u Žatce'},
  {id: '2419', name: 'Lískovec u Frýdku'},
  {id: '2421', name: 'Líšnice'},
  {id: '2422', name: 'Líšný'},
  {id: '2423', name: 'Liteň'},
  {id: '2424', name: 'Litice nad Orlicí'},
  {id: '2425', name: 'Lítkovice'},
  {id: '2426', name: 'Litochovice nad Labem'},
  {id: '2427', name: 'Litoměřice Cihelna'},
  {id: '2429', name: 'Litoměřice horní nádraží'},
  {id: '2430', name: 'Litoměřice město'},
  {id: '2431', name: 'Litomyšl'},
  {id: '2432', name: 'Litomyšl zastávka'},
  {id: '2433', name: 'Litomyšl-Nedošín'},
  {id: '2434', name: 'Litovel'},
  {id: '2435', name: 'Litovel město'},
  {id: '2436', name: 'Litovel předměstí'},
  {id: '2437', name: 'Litultovice'},
  {id: '2438', name: 'Litvínov'},
  {id: '2439', name: 'Litvínov město'},
  {id: '2444', name: 'Lnáře'},
  {id: '2446', name: 'Lobkovice'},
  {id: '2447', name: 'Lobodice'},
  {id: '2452', name: 'Lochenice'},
  {id: '2453', name: 'Lochovice'},
  {id: '2448', name: 'Loděnice'},
  {id: '2454', name: 'Loket'},
  {id: '2455', name: 'Loket předměstí'},
  {id: '2457', name: 'Lom u Mostu'},
  {id: '2458', name: 'Lom u Mostu zastávka'},
  {id: '2459', name: 'Lom u Stříbra'},
  {id: '2460', name: 'Lom u Tachova'},
  {id: '2461', name: 'Lomnice nad Lužnicí'},
  {id: '2462', name: 'Lomnice nad Popelkou'},
  {id: '2463', name: 'Lomnice u Rýmařova'},
  {id: '2464', name: 'Lomnička'},
  {id: '2470', name: 'Loučim'},
  {id: '2469', name: 'Loucká'},
  {id: '2471', name: 'Loučky'},
  {id: '2475', name: 'Loučovice'},
  {id: '2476', name: 'Loučovice zastávka'},
  {id: '2477', name: 'Louka u Litvínova'},
  {id: '2478', name: 'Louka u Mariánských Lázní'},
  {id: '2479', name: 'Louka u Ostrohu'},
  {id: '2480', name: 'Loukov'},
  {id: '2481', name: 'Loukov u Mnichova Hradiště'},
  {id: '2482', name: 'Louky nad Olší'},
  {id: '2483', name: 'Louny'},
  {id: '2484', name: 'Louny město'},
  {id: '2487', name: 'Louny předměstí'},
  {id: '2488', name: 'Louny střed'},
  {id: '2489', name: 'Lovčice obec'},
  {id: '2492', name: 'Lovosice'},
  {id: '2494', name: 'Lovosice město'},
  {id: '2496', name: 'Lovosice zastávka'},
  {id: '2497', name: 'Lovosice závod'},
  {id: '2500', name: 'Lštění'},
  {id: '2504', name: 'Lubenec'},
  {id: '2505', name: 'Lubenec zastávka'},
  {id: '2511', name: 'Lubná'},
  {id: '2512', name: 'Luby u Chebu'},
  {id: '1259', name: 'Luby u Klatov'},
  {id: '2513', name: 'Lučany nad Nisou'},
  {id: '2518', name: 'Luh pod Smrkem'},
  {id: '2519', name: 'Luhačovice'},
  {id: '2520', name: 'Luhov'},
  {id: '2522', name: 'Luka nad Jihlavou'},
  {id: '2523', name: 'Luka pod Medníkem'},
  {id: '2525', name: 'Lukavec'},
  {id: '2526', name: 'Lukavice na Moravě'},
  {id: '2527', name: 'Lukavice v Čechách'},
  {id: '2529', name: 'Luková'},
  {id: '2528', name: 'Luková u Rudoltic v Čechách'},
  {id: '2532', name: 'Luleč'},
  {id: '2535', name: 'Lupěné'},
  {id: '6219', name: 'Luštěnice'},
  {id: '2538', name: 'Lutotín'},
  {id: '2541', name: 'Lužany'},
  {id: '2542', name: 'Lužec nad Vltavou'},
  {id: '2543', name: 'Lužec pod Smrkem'},
  {id: '2545', name: 'Lužice'},
  {id: '2546', name: 'Lužná u Rakovníka'},
  {id: '2547', name: 'Lužná u Vsetína'},
  {id: '2548', name: 'Lužnice'},
  {id: '2550', name: 'Lvová'},
  {id: '2554', name: 'Lysá nad Labem'},
  {id: '2553', name: 'Lysá nad Labem-Dvorce'},
  {id: '2561', name: 'Machnín'},
  {id: '2560', name: 'Machnín hrad'},
  {id: '2557', name: 'Mačkov'},
  {id: '2564', name: 'Majdalena'},
  {id: '2565', name: 'Majdalena zastávka'},
  {id: '2566', name: 'Malá Bělá'},
  {id: '2567', name: 'Malá Hraštice'},
  {id: '2569', name: 'Malá Morávka'},
  {id: '2570', name: 'Malá Skála'},
  {id: '2571', name: 'Malá Veleň'},
  {id: '2574', name: 'Malé Březno nad Labem'},
  {id: '2576', name: 'Malé Chvojno'},
  {id: '2575', name: 'Malé Hoštice'},
  {id: '2577', name: 'Malé Svatoňovice'},
  {id: '2578', name: 'Malé Žernoseky'},
  {id: '2579', name: 'Malechovice'},
  {id: '2580', name: 'Malenice nad Volyňkou'},
  {id: '2581', name: 'Malešov'},
  {id: '2583', name: 'Málkov'},
  {id: '2584', name: 'Malměřice'},
  {id: '2587', name: 'Malonice'},
  {id: '2588', name: 'Malovice u Netolic'},
  {id: '2589', name: 'Malovidy'},
  {id: '2590', name: 'Malšice'},
  {id: '2591', name: 'Malý Beranov'},
  {id: '2592', name: 'Malý Pěčín'},
  {id: '2593', name: 'Malý Rapotín'},
  {id: '2595', name: 'Malý Újezd'},
  {id: '2597', name: 'Mankovice'},
  {id: '2602', name: 'Marefy'},
  {id: '2605', name: 'Mariánské Lázně'},
  {id: '2599', name: 'Mariánské Lázně město'},
  {id: '1260', name: 'Markvartice'},
  {id: '2612', name: 'Martiněves u Děčína'},
  {id: '2613', name: 'Martiněves u Libochovic'},
  {id: '2614', name: 'Martinice u Velkého Meziříčí'},
  {id: '2615', name: 'Martinice v Krkonoších'},
  {id: '2618', name: 'Mašovice'},
  {id: '2632', name: 'Měchenice'},
  {id: '2633', name: 'Měcholupy'},
  {id: '2624', name: 'Meclov'},
  {id: '2625', name: 'Měděnec'},
  {id: '2626', name: 'Měděnec zastávka'},
  {id: '2628', name: 'Medlešice'},
  {id: '2635', name: 'Mělnická Vrutice'},
  {id: '2636', name: 'Mělník'},
  {id: '2638', name: 'Mělník-Mlazice'},
  {id: '2640', name: 'Merklín'},
  {id: '2641', name: 'Měrovice nad Hanou'},
  {id: '2648', name: 'Měšice u Prahy'},
  {id: '2649', name: 'Mešno'},
  {id: '2643', name: 'Městec Králové'},
  {id: '2644', name: 'Městečko Trnávka'},
  {id: '2645', name: 'Městečko u Benešova'},
  {id: '2646', name: 'Městečko u Křivoklátu'},
  {id: '2647', name: 'Město Albrechtice'},
  {id: '2653', name: 'Mezihoří'},
  {id: '2654', name: 'Meziměstí'},
  {id: '2656', name: 'Mezipotočí'},
  {id: '2657', name: 'Meziříčí'},
  {id: '1410', name: 'Mezno'},
  {id: '2665', name: 'Mikulášovice dolní nádraží'},
  {id: '2666', name: 'Mikulášovice horní nádraží'},
  {id: '2667', name: 'Mikulášovice střed'},
  {id: '2668', name: 'Mikulov na Moravě'},
  {id: '2669', name: 'Mikulov v Krušných horách'},
  {id: '2672', name: 'Mikulov-Nové Město'},
  {id: '2670', name: 'Mikulovice'},
  {id: '2675', name: 'Milavče'},
  {id: '2676', name: 'Mileč'},
  {id: '2677', name: 'Milenovice'},
  {id: '2678', name: 'Milevsko'},
  {id: '2679', name: 'Milhostov u Mariánských Lázní'},
  {id: '2680', name: 'Milíkov'},
  {id: '2681', name: 'Milín'},
  {id: '2683', name: 'Milostín'},
  {id: '2684', name: 'Milotice nad Bečvou'},
  {id: '2685', name: 'Milotice nad Opavou'},
  {id: '2686', name: 'Milovice'},
  {id: '2687', name: 'Mimoň'},
  {id: '2688', name: 'Minartice'},
  {id: '2689', name: 'Minkovice'},
  {id: '2691', name: 'Miroslav'},
  {id: '2692', name: 'Mirošov'},
  {id: '2693', name: 'Mirošov město'},
  {id: '2694', name: 'Mirošovice u Prahy'},
  {id: '2695', name: 'Mirošovice u Rataj nad Sázavou'},
  {id: '2696', name: 'Mírová'},
  {id: '2697', name: 'Mirovice'},
  {id: '2698', name: 'Mírovka'},
  {id: '2700', name: 'Mitrov'},
  {id: '2705', name: 'Mladá Boleslav hlavní nádraží'},
  {id: '2706', name: 'Mladá Boleslav město'},
  {id: '2707', name: 'Mladá Boleslav-Debř'},
  {id: '2710', name: 'Mladeč'},
  {id: '2711', name: 'Mladeč jeskyně'},
  {id: '2709', name: 'Mladecko'},
  {id: '2712', name: 'Mladějov na Moravě'},
  {id: '2713', name: 'Mladějov v Čechách'},
  {id: '2714', name: 'Mladějovice'},
  {id: '2716', name: 'Mladkov'},
  {id: '2717', name: 'Mladotice'},
  {id: '2718', name: 'Mladotice zastávka'},
  {id: '2719', name: 'Mlčechvosty'},
  {id: '2720', name: 'Mlýnec'},
  {id: '2721', name: 'Mlýnický Dvůr'},
  {id: '2722', name: 'Mlýny'},
  {id: '2723', name: 'Mnetěš'},
  {id: '2724', name: 'Mnich'},
  {id: '2726', name: 'Mnichovice'},
  {id: '2727', name: 'Mnichovo Hradiště'},
  {id: '2728', name: 'Mníšek pod Brdy'},
  {id: '2729', name: 'Mníšek u Liberce'},
  {id: '2737', name: 'Mochov'},
  {id: '2738', name: 'Mochov zastávka'},
  {id: '2732', name: 'Modlešovice'},
  {id: '2733', name: 'Modrá u Děčína'},
  {id: '2734', name: 'Modřice'},
  {id: '2736', name: 'Mohelnice'},
  {id: '2739', name: 'Mojžíř'},
  {id: '2740', name: 'Mokré Lazce'},
  {id: '2741', name: 'Mokrosuky'},
  {id: '2742', name: 'Mokrovraty'},
  {id: '2743', name: 'Moldava v Krušných horách'},
  {id: '2754', name: 'Moravany'},
  {id: '2755', name: 'Moravičany'},
  {id: '2756', name: 'Moravská Chrastová'},
  {id: '2757', name: 'Moravská Nová Ves'},
  {id: '2758', name: 'Moravská Třebová'},
  {id: '2759', name: 'Moravské Bránice'},
  {id: '2760', name: 'Moravské Budějovice'},
  {id: '2761', name: 'Moravský Beroun'},
  {id: '2763', name: 'Moravský Karlov'},
  {id: '2764', name: 'Moravský Krumlov'},
  {id: '2765', name: 'Moravský Písek'},
  {id: '2753', name: 'Moravský Písek zastávka'},
  {id: '2767', name: 'Mořkov hlavní trať'},
  {id: '5663', name: '"Mošnov,Ostrava Airport"'},
  {id: '2771', name: 'Most'},
  {id: '2777', name: 'Most-Kopisty'},
  {id: '2778', name: 'Most-Minerva'},
  {id: '2776', name: 'Mostek'},
  {id: '2781', name: 'Mosty u Jablunkova (Mosty koło Jabłonkowa)'},
  {id: '2780', name: 'Mosty u Jablunkova zastávka (Mosty koło Jabłonkowa'},
  {id: '2784', name: 'Mrač'},
  {id: '2785', name: 'Mrázov'},
  {id: '2786', name: 'Mrzkovice'},
  {id: '2789', name: 'Mšené Lázně'},
  {id: '2790', name: 'Mšeno'},
  {id: '2787', name: 'Mstětice'},
  {id: '2804', name: 'Mutějovice'},
  {id: '2805', name: 'Mutějovice zastávka'},
  {id: '2806', name: 'Mutěnice'},
  {id: '2809', name: 'Mutěnice zastávka'},
  {id: '2810', name: 'Mutěnín'},
  {id: '2811', name: 'Mutišov'},
  {id: '2813', name: 'Myslechovice'},
  {id: '2814', name: 'Mysliboř'},
  {id: '2815', name: 'Myslíč'},
  {id: '2816', name: 'Myslín'},
  {id: '2818', name: 'Mýto'},
  {id: '2826', name: 'Náchod'},
  {id: '2827', name: 'Náchod zastávka'},
  {id: '2828', name: 'Náchod-Běloves'},
  {id: '2829', name: 'Náchod-Malé Poříčí'},
  {id: '2830', name: 'Náměšť na Hané'},
  {id: '2831', name: 'Náměšť nad Oslavou'},
  {id: '2834', name: 'Napajedla'},
  {id: '2836', name: 'Nasavrky'},
  {id: '2837', name: 'Našiměřice'},
  {id: '2840', name: 'Návarov'},
  {id: '2841', name: 'Návojná'},
  {id: '2842', name: 'Návsí (Nawsie)'},
  {id: '2844', name: 'Nebanice'},
  {id: '2845', name: 'Nebužely'},
  {id: '2846', name: 'Nectava'},
  {id: '2847', name: 'Nedakonice'},
  {id: '2848', name: 'Nedvědice'},
  {id: '2849', name: 'Nedvězí'},
  {id: '2852', name: 'Nejdek'},
  {id: '2853', name: 'Nejdek zastávka'},
  {id: '2854', name: 'Nejdek-Oldřichov'},
  {id: '2855', name: 'Nejdek-Sejfy'},
  {id: '2856', name: 'Nejdek-Suchá'},
  {id: '2857', name: 'Nejdek-Tisová'},
  {id: '2859', name: 'Nekvasovy'},
  {id: '2860', name: 'Nelahozeves'},
  {id: '2861', name: 'Nelahozeves zámek'},
  {id: '2864', name: 'Němčice nad Hanou'},
  {id: '43', name: 'Nemilany'},
  {id: '2865', name: 'Nemilkov'},
  {id: '2866', name: 'Nemotice'},
  {id: '2868', name: 'Nemyčeves'},
  {id: '44', name: 'Neplachov'},
  {id: '2869', name: 'Nepomuk'},
  {id: '2870', name: 'Nepřevázka'},
  {id: '2871', name: 'Neratovice'},
  {id: '2872', name: 'Neratovice město'},
  {id: '6682', name: 'Neratovice sídliště'},
  {id: '2874', name: 'Nesovice'},
  {id: '2877', name: 'Neštědice'},
  {id: '2878', name: 'Neštěmice'},
  {id: '2876', name: 'Nesvačily'},
  {id: '2879', name: 'Netolice'},
  {id: '2880', name: 'Netolice zastávka'},
  {id: '2881', name: 'Netřeba'},
  {id: '2890', name: 'Neuměřice'},
  {id: '2891', name: 'Neumětely'},
  {id: '2897', name: 'Nevojice'},
  {id: '2898', name: 'Nezamyslice'},
  {id: '2899', name: 'Nezdenice'},
  {id: '2900', name: 'Neznašovy'},
  {id: '2901', name: 'Nezvěstice'},
  {id: '2905', name: 'Níhov'},
  {id: '2908', name: 'Nišovice'},
  {id: '2909', name: 'Nižbor'},
  {id: '2910', name: 'Nížkov'},
  {id: '2914', name: 'Noutonice'},
  {id: '2915', name: 'Nová Buková'},
  {id: '2917', name: 'Nová Cerekev'},
  {id: '2919', name: 'Nová Hradečná'},
  {id: '2920', name: 'Nová Huť'},
  {id: '1739', name: 'Nová Huť v Lužických horách'},
  {id: '2921', name: 'Nová Paka'},
  {id: '2922', name: 'Nová Paka město'},
  {id: '2923', name: 'Nová Pec'},
  {id: '2924', name: 'Nová Role'},
  {id: '2925', name: 'Nová Role zastávka'},
  {id: '2929', name: 'Nová Ves nad Lužnicí'},
  {id: '2927', name: 'Nová Ves nad Nisou'},
  {id: '2928', name: 'Nová Ves nad Popelkou'},
  {id: '2930', name: 'Nová Ves pod Pleší'},
  {id: '2821', name: 'Nová Ves u Českých Budějovic'},
  {id: '2931', name: 'Nová Ves u Kolína'},
  {id: '2932', name: 'Nová Ves u Křimova'},
  {id: '2933', name: 'Nová Ves u Leštiny'},
  {id: '2935', name: 'Nové Hamry'},
  {id: '2937', name: 'Nové Hodějovice'},
  {id: '2938', name: 'Nové Hrady'},
  {id: '2939', name: 'Nové Kopisty'},
  {id: '2940', name: 'Nové Losiny'},
  {id: '2943', name: 'Nové Město na Moravě'},
  {id: '2820', name: 'Nové Město na Moravě zastávka'},
  {id: '2942', name: 'Nové Město nad Cidlinou'},
  {id: '2941', name: 'Nové Město nad Metují'},
  {id: '2945', name: 'Nové Město pod Smrkem'},
  {id: '2946', name: 'Nové Ouholice'},
  {id: '2947', name: 'Nové Sedlo u Lokte'},
  {id: '2948', name: 'Nové Strašecí'},
  {id: '2949', name: 'Nové Údolí'},
  {id: '2954', name: 'Novina'},
  {id: '2956', name: 'Novosedlice'},
  {id: '2957', name: 'Novosedly'},
  {id: '2960', name: 'Nový Bor'},
  {id: '2961', name: 'Nový Bydžov'},
  {id: '2962', name: 'Nový Drahov'},
  {id: '2963', name: 'Nový Hrozenkov'},
  {id: '2819', name: 'Nový Hrozenkov zastávka'},
  {id: '2964', name: 'Nový Jičín město'},
  {id: '2966', name: 'Nový Kostel'},
  {id: '2967', name: 'Nový Kramolín'},
  {id: '2968', name: 'Nový Malín'},
  {id: '2970', name: 'Nučice'},
  {id: '2971', name: 'Nučice zastávka'},
  {id: '2979', name: 'Nymburk hlavní nádraží'},
  {id: '2980', name: 'Nymburk město'},
  {id: '2986', name: 'Nýřany'},
  {id: '2985', name: 'Nýrsko'},
  {id: '2991', name: 'Obora u Kaznějova'},
  {id: '2995', name: 'Obrataň'},
  {id: '2997', name: 'Obrnice'},
  {id: '2998', name: 'Obrubce'},
  {id: '2999', name: 'Očelice'},
  {id: '3048', name: 'Odrlice'},
  {id: '3049', name: 'Odry'},
  {id: '3050', name: 'Odry-Loučky'},
  {id: '3054', name: 'Ohníč'},
  {id: '3055', name: 'Ohnišťany'},
  {id: '3056', name: 'Ohnišťovice'},
  {id: '3057', name: 'Okna'},
  {id: '3059', name: 'Okříšky'},
  {id: '3058', name: 'Okrouhlice'},
  {id: '3060', name: 'Olbramkostel'},
  {id: '3061', name: 'Olbramovice'},
  {id: '3065', name: 'Oldřichov v Hájích'},
  {id: '3066', name: 'Oldřiš'},
  {id: '3069', name: 'Oleško'},
  {id: '3070', name: 'Olešná na Moravě'},
  {id: '3071', name: 'Olešná u Rakovníka'},
  {id: '3072', name: 'Olešnice'},
  {id: '3073', name: 'Olomouc hlavní nádraží'},
  {id: '3074', name: 'Olomouc město'},
  {id: '3078', name: 'Olomouc-Hejčín'},
  {id: '3079', name: 'Olomouc-Nová Ulice'},
  {id: '3080', name: 'Olomouc-Nové Sady'},
  {id: '3081', name: 'Olomouc-Řepčín'},
  {id: '3082', name: 'Olomouc-Smetanovy sady'},
  {id: '3084', name: 'Olovnice'},
  {id: '3085', name: 'Olovnice zastávka'},
  {id: '3090', name: 'Omice'},
  {id: '3091', name: 'Omlenice'},
  {id: '3092', name: 'Ondřejovice'},
  {id: '3093', name: 'Ondřejovice zastávka'},
  {id: '3096', name: 'Oparno'},
  {id: '3097', name: 'Opatov'},
  {id: '5747', name: 'Opatovice nad Labem'},
  {id: '3098', name: 'Opatovice nad Labem-Pohřebačka'},
  {id: '3099', name: 'Opava východ'},
  {id: '3100', name: 'Opava západ'},
  {id: '3101', name: 'Opava zastávka'},
  {id: '3102', name: 'Opava-Komárov'},
  {id: '3104', name: 'Oplocany'},
  {id: '3106', name: 'Opočno pod Orlickými horami'},
  {id: '3107', name: 'Opočno u Loun'},
  {id: '3110', name: 'Oráčov'},
  {id: '3123', name: 'Ořechov'},
  {id: '7816', name: 'Orel'},
  {id: '3124', name: 'Osek'},
  {id: '3125', name: 'Osek město'},
  {id: '3126', name: 'Osek nad Bečvou'},
  {id: '3179', name: 'Ošelín'},
  {id: '3128', name: 'Osíčko'},
  {id: '3130', name: 'Oskořínek'},
  {id: '3132', name: 'Oslavany'},
  {id: '3133', name: 'Oslavice'},
  {id: '3134', name: 'Oslavička'},
  {id: '3135', name: 'Oslí'},
  {id: '3138', name: 'Osoblaha'},
  {id: '3139', name: 'Osov'},
  {id: '3140', name: 'Osová Bítýška'},
  {id: '3141', name: 'Ostašov'},
  {id: '7690', name: 'Ostopovice'},
  {id: '3143', name: 'Ostrá'},
  {id: '3145', name: 'Ostrava hlavní nádraží'},
  {id: '3150', name: 'Ostrava střed'},
  {id: '3154', name: 'Ostrava-Bartovice'},
  {id: '3157', name: 'Ostrava-Kunčice'},
  {id: '3158', name: 'Ostrava-Kunčičky'},
  {id: '3159', name: 'Ostrava-Mariánské Hory'},
  {id: '3160', name: 'Ostrava-Stodolní'},
  {id: '3161', name: 'Ostrava-Svinov'},
  {id: '3162', name: 'Ostrava-Třebovice'},
  {id: '3163', name: 'Ostrava-Vítkovice'},
  {id: '6386', name: 'Ostrava-Zábřeh'},
  {id: '3164', name: 'Ostravice'},
  {id: '3165', name: 'Ostravice zastávka'},
  {id: '3168', name: 'Ostroměř'},
  {id: '3169', name: 'Ostrov nad Ohří'},
  {id: '3170', name: 'Ostrov nad Oslavou'},
  {id: '3171', name: 'Ostrov u Tochovic'},
  {id: '3174', name: 'Ostrožská Nová Ves'},
  {id: '3173', name: 'Ostrožská Nová Ves lázně'},
  {id: '3175', name: 'Ostružná'},
  {id: '3177', name: 'Osvračín'},
  {id: '3182', name: 'Otice'},
  {id: '3184', name: 'Otovice'},
  {id: '3185', name: 'Otovice zastávka'},
  {id: '3186', name: 'Otradovice'},
  {id: '3187', name: 'Otročín'},
  {id: '3188', name: 'Otrokovice'},
  {id: '3189', name: 'Otrokovice-Trávníky'},
  {id: '3190', name: 'Otvovice'},
  {id: '3192', name: 'Ovčáry'},
  {id: '3193', name: 'Ovesná'},
  {id: '3194', name: 'Ovesné Kladruby'},
  {id: '3195', name: 'Ovesné u Prachatic'},
  {id: '3212', name: 'Pačejov'},
  {id: '3211', name: 'Pacov'},
  {id: '3213', name: 'Padařov'},
  {id: '3218', name: 'Páleček'},
  {id: '3222', name: 'Panský'},
  {id: '3223', name: 'Pardubice hlavní nádraží'},
  {id: '3224', name: 'Pardubice závodiště'},
  {id: '3225', name: 'Pardubice-Černá za Bory'},
  {id: '3226', name: 'Pardubice-Opočínek'},
  {id: '3227', name: 'Pardubice-Pardubičky'},
  {id: '3228', name: 'Pardubice-Rosice nad Labem'},
  {id: '3229', name: 'Pardubice-Semtín'},
  {id: '3230', name: 'Pardubice-Svítkov'},
  {id: '3237', name: 'Paskov'},
  {id: '3240', name: 'Pastuchovice'},
  {id: '3242', name: 'Pátek'},
  {id: '1262', name: 'Pavlov'},
  {id: '3244', name: 'Pavlovice'},
  {id: '3251', name: 'Peč'},
  {id: '3249', name: 'Pecerady'},
  {id: '3252', name: 'Pěčín'},
  {id: '3253', name: 'Pečky'},
  {id: '3257', name: 'Peklo nad Zdobnicí'},
  {id: '3258', name: 'Pěkná'},
  {id: '3259', name: 'Pelhřimov'},
  {id: '3262', name: 'Pernek na Šumavě'},
  {id: '3263', name: 'Pernink'},
  {id: '3264', name: 'Pernolec'},
  {id: '3266', name: 'Perštejn'},
  {id: '3267', name: 'Pertoltice pod Ralskem'},
  {id: '3268', name: 'Peruc'},
  {id: '3271', name: 'Pětipsy'},
  {id: '3282', name: 'Petříkov'},
  {id: '3283', name: 'Petříkovice'},
  {id: '3272', name: 'Petrkov'},
  {id: '3273', name: 'Petrohrad'},
  {id: '3276', name: 'Petrov u Prahy'},
  {id: '3277', name: 'Petrov u Strážnice'},
  {id: '3278', name: 'Petrov-Chlomek'},
  {id: '1263', name: 'Petrovice nad Orlicí'},
  {id: '3279', name: 'Petrovice nad Úhlavou'},
  {id: '3281', name: 'Petrovice u Karviné'},
  {id: '3482', name: 'Pha-Smíchov spol.n.'},
  {id: '3292', name: 'Pila'},
  {id: '46', name: 'Pilínkov'},
  {id: '3296', name: 'Pilníkov'},
  {id: '3300', name: 'Písečná'},
  {id: '3301', name: 'Písek'},
  {id: '5826', name: 'Písek jih'},
  {id: '3302', name: 'Písek město'},
  {id: '3303', name: 'Písek zastávka'},
  {id: '3304', name: 'Písek-Dobešice'},
  {id: '3306', name: 'Pitín zastávka'},
  {id: '3307', name: 'Pivín'},
  {id: '3309', name: 'Plačkov'},
  {id: '3310', name: 'Planá nad Lužnicí'},
  {id: '3311', name: 'Planá u Mariánských Lázní'},
  {id: '3312', name: 'Plaňany'},
  {id: '3313', name: 'Plaňany zastávka'},
  {id: '3314', name: 'Plasy'},
  {id: '3315', name: 'Platěnice'},
  {id: '3321', name: 'Plavy'},
  {id: '3327', name: 'Plchůvky'},
  {id: '3322', name: 'Plesná'},
  {id: '3324', name: 'Plešnice'},
  {id: '3325', name: 'Plešovice'},
  {id: '3331', name: 'Ploskovice'},
  {id: '3332', name: 'Plotiště nad Labem'},
  {id: '3333', name: 'Ploužnice'},
  {id: '3336', name: 'Plužiny'},
  {id: '3341', name: 'Plzeň hlavní nádraží'},
  {id: '3343', name: 'Plzeň zastávka'},
  {id: '3344', name: 'Plzeň-Bílá Hora'},
  {id: '48', name: 'Plzeň-Bolevec'},
  {id: '3345', name: 'Plzeň-Doubravka'},
  {id: '3346', name: 'Plzeň-Doudlevce'},
  {id: '3347', name: 'Plzeň-Jižní Předměstí'},
  {id: '3349', name: 'Plzeň-Křimice'},
  {id: '4399', name: 'Plzeň-Orlík'},
  {id: '3350', name: 'Plzeň-Skvrňany'},
  {id: '5944', name: 'Plzeň-Slovany'},
  {id: '3351', name: 'Plzeň-Valcha'},
  {id: '3352', name: 'Plzeň-Zadní Skvrňany'},
  {id: '3353', name: 'Pňovany'},
  {id: '3354', name: 'Pňovany zastávka'},
  {id: '3355', name: 'Poběžovice'},
  {id: '3357', name: 'Počátky-Žirovnice'},
  {id: '3358', name: 'Počerady'},
  {id: '3356', name: 'Pocinovice'},
  {id: '3359', name: 'Podbořany'},
  {id: '3361', name: 'Poděbrady'},
  {id: '3362', name: 'Podhradí'},
  {id: '3609', name: 'Podhradní Lhota'},
  {id: '3363', name: 'Podhůří'},
  {id: '3364', name: 'Podivín'},
  {id: '3366', name: 'Podlesí'},
  {id: '3367', name: 'Podlešín'},
  {id: '3372', name: 'Pohled'},
  {id: '3373', name: 'Pohleď'},
  {id: '3374', name: 'Pohledští Dvořáci'},
  {id: '3376', name: 'Pohoří'},
  {id: '3379', name: 'Pokřikov'},
  {id: '3380', name: 'Poláky'},
  {id: '3381', name: 'Polanka nad Odrou'},
  {id: '3382', name: 'Polečnice'},
  {id: '3383', name: 'Polepy'},
  {id: '3384', name: 'Polerady nad Labem'},
  {id: '3389', name: 'Poličany'},
  {id: '3387', name: 'Police nad Metují'},
  {id: '3388', name: 'Police u Valašského Meziříčí'},
  {id: '3391', name: 'Polichno'},
  {id: '3390', name: 'Polička'},
  {id: '3393', name: 'Polná'},
  {id: '3392', name: 'Polná na Šumavě'},
  {id: '3394', name: 'Polom'},
  {id: '3397', name: 'Pomezí'},
  {id: '3396', name: 'Pomezí nad Ohří'},
  {id: '3398', name: 'Pomezí zastávka'},
  {id: '3399', name: 'Ponětovice'},
  {id: '3400', name: 'Poniklá'},
  {id: '3401', name: 'Poniklá zastávka'},
  {id: '3402', name: 'Popelín'},
  {id: '3403', name: 'Popice'},
  {id: '3404', name: 'Popov'},
  {id: '3405', name: 'Popovice u Rajhradu'},
  {id: '3406', name: 'Popovice u Uherského Hradiště'},
  {id: '3411', name: 'Poříčany'},
  {id: '3413', name: 'Poříčí nad Sázavou'},
  {id: '3412', name: 'Poříčí nad Sázavou-Svárov'},
  {id: '3414', name: 'Pořín'},
  {id: '3415', name: 'Poseč'},
  {id: '3416', name: 'Postoloprty'},
  {id: '3421', name: 'Poštorná'},
  {id: '3417', name: 'Postoupky'},
  {id: '3418', name: 'Postřekov'},
  {id: '3419', name: 'Postřelmov'},
  {id: '3420', name: 'Postupice'},
  {id: '3422', name: 'Poteč'},
  {id: '3424', name: 'Potštejn'},
  {id: '3425', name: 'Potůčky'},
  {id: '3427', name: 'Potůčky zastávka'},
  {id: '3428', name: 'Potůčník'},
  {id: '3429', name: 'Potvorov'},
  {id: '3430', name: 'Poutnov'},
  {id: '3431', name: 'Pouzdřany'},
  {id: '3433', name: 'Povrly'},
  {id: '3434', name: 'Povrly-Roztoky'},
  {id: '3435', name: 'Pozďatín'},
  {id: '3441', name: 'Pracejovice'},
  {id: '3493', name: 'Prachatice'},
  {id: '3494', name: 'Prachatice lázně'},
  {id: '3495', name: 'Prachovice'},
  {id: '3442', name: 'Prackovice nad Labem'},
  {id: '3444', name: 'Praha hlavní nádraží'},
  {id: '3449', name: 'Praha Masarykovo nádraží'},
  {id: '3451', name: 'Praha-Běchovice'},
  {id: '5796', name: 'Praha-Běchovice střed'},
  {id: '3452', name: 'Praha-Braník'},
  {id: '3454', name: 'Praha-Bubny'},
  {id: '6233', name: 'Praha-Bubny Vltavská'},
  {id: '3456', name: 'Praha-Čakovice'},
  {id: '3455', name: 'Praha-Cibulka'},
  {id: '3458', name: 'Praha-Dejvice'},
  {id: '3457', name: 'Praha-Dolní Počernice'},
  {id: '5951', name: 'Praha-Eden'},
  {id: '3459', name: 'Praha-Hlubočepy'},
  {id: '3460', name: 'Praha-Holešovice'},
  {id: '3201', name: 'Praha-Holešovice zastávka'},
  {id: '3461', name: 'Praha-Holyně'},
  {id: '3202', name: 'Praha-Horní Měcholupy'},
  {id: '3462', name: 'Praha-Horní Počernice'},
  {id: '3463', name: 'Praha-Hostivař'},
  {id: '6675', name: 'Praha-Jinonice'},
  {id: '5689', name: 'Praha-Kačerov'},
  {id: '3465', name: 'Praha-Kbely'},
  {id: '3466', name: 'Praha-Klánovice'},
  {id: '3467', name: 'Praha-Kolovraty'},
  {id: '3468', name: 'Praha-Komořany'},
  {id: '3469', name: 'Praha-Krč'},
  {id: '3470', name: 'Praha-Kyje'},
  {id: '3471', name: 'Praha-Libeň'},
  {id: '3474', name: 'Praha-Modřany zastávka'},
  {id: '5690', name: 'Praha-Podbaba'},
  {id: '3475', name: 'Praha-Radotín'},
  {id: '3477', name: 'Praha-Řeporyje'},
  {id: '3476', name: 'Praha-Ruzyně'},
  {id: '3479', name: 'Praha-Satalice'},
  {id: '3480', name: 'Praha-Sedlec'},
  {id: '3481', name: 'Praha-Smíchov'},
  {id: '3483', name: 'Praha-Stodůlky'},
  {id: '3484', name: 'Praha-Uhříněves'},
  {id: '3485', name: 'Praha-Veleslavín'},
  {id: '3206', name: 'Praha-Velká Chuchle'},
  {id: '3486', name: 'Praha-Vršovice'},
  {id: '3489', name: 'Praha-Vysočany'},
  {id: '5953', name: 'Praha-Zahradní Město'},
  {id: '3490', name: 'Praha-Zbraslav'},
  {id: '3478', name: 'Praha-Zličín'},
  {id: '3492', name: 'Praha-Žvahov'},
  {id: '3497', name: 'Praskačka'},
  {id: '3498', name: 'Praskolesy'},
  {id: '3499', name: 'Pravice'},
  {id: '3500', name: 'Pražák'},
  {id: '3531', name: 'Předbořice'},
  {id: '3532', name: 'Předhradí'},
  {id: '3533', name: 'Předměřice nad Labem'},
  {id: '3534', name: 'Přední Zborovice'},
  {id: '3535', name: 'Přehýšov'},
  {id: '3536', name: 'Přelouč'},
  {id: '5745', name: 'Přepeře u Turnova'},
  {id: '3538', name: 'Přerov'},
  {id: '3541', name: 'Přeštice'},
  {id: '3542', name: 'Přeštice-Zastávka'},
  {id: '3543', name: 'Převýšov'},
  {id: '3544', name: 'Přibice'},
  {id: '3545', name: 'Příbor'},
  {id: '3546', name: 'Příbram'},
  {id: '6714', name: 'Příbram sídliště'},
  {id: '3547', name: 'Přibyslav'},
  {id: '3548', name: 'Přibyslav zastávka'},
  {id: '3549', name: 'Příčina'},
  {id: '3550', name: 'Příkazy'},
  {id: '3551', name: 'Příkosice'},
  {id: '3552', name: 'Příkosice zastávka'},
  {id: '3553', name: 'Přílepy'},
  {id: '3554', name: 'Přímělkov'},
  {id: '887', name: 'Přísečná'},
  {id: '3555', name: 'Příšovice'},
  {id: '3511', name: 'Proboštov'},
  {id: '3512', name: 'Proseč nad Nisou'},
  {id: '3513', name: 'Prosečné'},
  {id: '3514', name: 'Prosečnice'},
  {id: '3515', name: 'Prosenice'},
  {id: '3516', name: 'Prosetice'},
  {id: '3517', name: 'Prosetín'},
  {id: '3518', name: 'Prostějov hlavní nádraží'},
  {id: '3519', name: 'Prostějov místní nádraží'},
  {id: '3520', name: 'Prostřední Lipka'},
  {id: '3523', name: 'Protivec'},
  {id: '3524', name: 'Protivín'},
  {id: '3525', name: 'Protivín zastávka'},
  {id: '3526', name: 'Prudká zastávka'},
  {id: '3530', name: 'Pržno'},
  {id: '52', name: 'Pšenice'},
  {id: '3557', name: 'Pševes'},
  {id: '3558', name: 'Pšovlky'},
  {id: '3559', name: 'Ptení'},
  {id: '3560', name: 'Pučery'},
  {id: '3565', name: 'Pustá Kamenice'},
  {id: '3563', name: 'Pustá Kamenice zastávka'},
  {id: '3566', name: 'Pustověty'},
  {id: '3568', name: 'Putim'},
  {id: '3571', name: 'Pyšely'},
  {id: '3575', name: 'Račice nad Berounkou'},
  {id: '3577', name: 'Račice nad Trotinou'},
  {id: '3574', name: 'Rácovice'},
  {id: '3582', name: 'Radejčín'},
  {id: '3583', name: 'Radim'},
  {id: '3585', name: 'Radkov'},
  {id: '3588', name: 'Rádlo'},
  {id: '3589', name: 'Radnice'},
  {id: '3590', name: 'Radňov'},
  {id: '3591', name: 'Radňovice'},
  {id: '3594', name: 'Radomyšl'},
  {id: '3595', name: 'Radomyšl zastávka'},
  {id: '3596', name: 'Radonice nad Ohří'},
  {id: '3597', name: 'Radonice u Kadaně'},
  {id: '3600', name: 'Radošovice'},
  {id: '3599', name: 'Radostice'},
  {id: '3598', name: 'Radostice u Trocnova'},
  {id: '3601', name: 'Radouš'},
  {id: '3603', name: 'Radvanice'},
  {id: '3606', name: 'Rájec-Jestřebí'},
  {id: '3607', name: 'Rajhrad'},
  {id: '3615', name: 'Rakovník'},
  {id: '3616', name: 'Rakovník západ'},
  {id: '3617', name: 'Rakovník zastávka'},
  {id: '3618', name: 'Rakšice'},
  {id: '3619', name: 'Rakvice'},
  {id: '3620', name: 'Ramzová'},
  {id: '3621', name: 'Rantířov'},
  {id: '3622', name: 'Rapotice'},
  {id: '3626', name: 'Raškov'},
  {id: '3768', name: 'Řasnice'},
  {id: '3769', name: 'Řasnice zastávka'},
  {id: '3625', name: 'Raspenava'},
  {id: '3630', name: 'Rataje nad Sázavou'},
  {id: '3628', name: 'Rataje nad Sázavou předměstí'},
  {id: '3629', name: 'Rataje nad Sázavou zastávka'},
  {id: '3627', name: 'Rataje nad Sázavou-Ivaň'},
  {id: '3631', name: 'Ratboř'},
  {id: '3635', name: 'Ražice'},
  {id: '3770', name: 'Řečany nad Labem'},
  {id: '3771', name: 'Řehlovice'},
  {id: '3642', name: 'Rejkovice'},
  {id: '3772', name: 'Řepice'},
  {id: '3773', name: 'Řepov'},
  {id: '3774', name: 'Řešetova Lhota'},
  {id: '3775', name: 'Řetenice'},
  {id: '3776', name: 'Řevnice'},
  {id: '3777', name: 'Řevničov'},
  {id: '3778', name: 'Řež'},
  {id: '3779', name: 'Říčany'},
  {id: '3780', name: 'Řídký'},
  {id: '3781', name: 'Řikonín'},
  {id: '3782', name: 'Říkovice'},
  {id: '3783', name: 'Řípec'},
  {id: '54', name: 'Řípec-Dráchov'},
  {id: '3675', name: 'Rochlov'},
  {id: '3665', name: 'Rodvínov'},
  {id: '3669', name: 'Rohanov'},
  {id: '3670', name: 'Rohatec'},
  {id: '3671', name: 'Rohatec kolonie'},
  {id: '3672', name: 'Rohatec zastávka'},
  {id: '3673', name: 'Rohatsko'},
  {id: '3676', name: 'Rojice'},
  {id: '3677', name: 'Rokycany'},
  {id: '3678', name: 'Rokycany předměstí'},
  {id: '5601', name: 'Rokycany-Borek'},
  {id: '3680', name: 'Rokytnice nad Jizerou'},
  {id: '3681', name: 'Rokytnice u Přerova'},
  {id: '3682', name: 'Rokytnice v Orlických horách'},
  {id: '3686', name: 'Ronov nad Doubravou'},
  {id: '3685', name: 'Ronov nad Doubravou zastávka'},
  {id: '3687', name: 'Ronov nad Sázavou'},
  {id: '3689', name: 'Ropice (Ropica)'},
  {id: '3691', name: 'Ropice zastávka (Ropica przystanek)'},
  {id: '3690', name: 'Ropice-Zálesí (Ropica-Zalesie)'},
  {id: '3694', name: 'Rosice u Brna'},
  {id: '3697', name: 'Rostoklaty'},
  {id: '3708', name: 'Roudná'},
  {id: '3710', name: 'Roudnice nad Labem'},
  {id: '3709', name: 'Roudnice nad Labem město'},
  {id: '3707', name: 'Roudnice nad Labem-Bezděkov'},
  {id: '3706', name: 'Roudnice nad Labem-Hracholusky'},
  {id: '3711', name: 'Rousínov'},
  {id: '3712', name: 'Roveň'},
  {id: '3713', name: 'Rovensko pod Troskami'},
  {id: '3714', name: 'Rovné-Divišov'},
  {id: '3724', name: 'Rožďalovice'},
  {id: '3715', name: 'Rozhovice'},
  {id: '3716', name: 'Rozhraní'},
  {id: '3725', name: 'Rožmberk nad Vltavou'},
  {id: '3726', name: 'Rožmitál pod Třemšínem'},
  {id: '3727', name: 'Rožná'},
  {id: '3729', name: 'Rožnov pod Radhoštěm'},
  {id: '3717', name: 'Rozsochatec'},
  {id: '3718', name: 'Rozsochy'},
  {id: '3719', name: 'Rozstání'},
  {id: '3720', name: 'Roztoky u Jilemnice'},
  {id: '3721', name: 'Roztoky u Křivoklátu'},
  {id: '3722', name: 'Roztoky u Prahy'},
  {id: '3723', name: 'Roztoky-Žalov'},
  {id: '3730', name: 'Rtyně nad Bílinou'},
  {id: '3732', name: 'Rtyně v Podkrkonoší'},
  {id: '3731', name: 'Rtyně v Podkrkonoší zastávka'},
  {id: '3735', name: 'Ruda nad Moravou'},
  {id: '3737', name: 'Rudíkov'},
  {id: '3740', name: 'Rudná pod Pradědem'},
  {id: '3739', name: 'Rudná pod Pradědem zastávka'},
  {id: '3741', name: 'Rudná u Prahy'},
  {id: '5355', name: 'Rudná zastávka'},
  {id: '3742', name: 'Rudoltice v Čechách'},
  {id: '3745', name: 'Rumburk'},
  {id: '3747', name: 'Rumburk zastávka'},
  {id: '3748', name: 'Ruprechtice'},
  {id: '3750', name: 'Rusová'},
  {id: '3753', name: 'Rybná nad Zdobnicí'},
  {id: '3755', name: 'Rybník'},
  {id: '3757', name: 'Rybniště'},
  {id: '3760', name: 'Rychnov nad Kněžnou'},
  {id: '3759', name: 'Rychnov nad Kněžnou zastávka'},
  {id: '3761', name: 'Rychnov u Jablonce nad Nisou'},
  {id: '53', name: 'Rychnovek'},
  {id: '3762', name: 'Rymaně'},
  {id: '3763', name: 'Rýmařov'},
  {id: '3764', name: 'Rynárec'},
  {id: '3765', name: 'Rynholec'},
  {id: '3766', name: 'Rynoltice'},
  {id: '4205', name: 'Šabina'},
  {id: '3791', name: 'Sádek u Poličky'},
  {id: '3792', name: 'Sádek u Žatce'},
  {id: '3793', name: 'Sadov'},
  {id: '3795', name: 'Sadov-Podlesí'},
  {id: '3794', name: 'Sadová'},
  {id: '3796', name: 'Sadská'},
  {id: '4208', name: 'Šakvice'},
  {id: '3799', name: 'Salajna'},
  {id: '3800', name: 'Salavice'},
  {id: '3808', name: 'Samechov'},
  {id: '3809', name: 'Samopše'},
  {id: '4210', name: 'Šanov'},
  {id: '3814', name: 'Sány'},
  {id: '4213', name: 'Šárovcova Lhota'},
  {id: '4215', name: 'Šatov'},
  {id: '3828', name: 'Sázava'},
  {id: '3826', name: 'Sázava u Žďáru'},
  {id: '3827', name: 'Sázava zastávka'},
  {id: '3829', name: 'Sázavka'},
  {id: '4216', name: 'Šebetov'},
  {id: '4217', name: 'Šebkovice'},
  {id: '3831', name: 'Sebuzín'},
  {id: '3833', name: 'Sedlčany'},
  {id: '3834', name: 'Sedlec u Mikulova'},
  {id: '3836', name: 'Sedlecko'},
  {id: '3837', name: 'Sedlejov'},
  {id: '3838', name: 'Sedlejovice'},
  {id: '3839', name: 'Sedlice'},
  {id: '3840', name: 'Sedlice město'},
  {id: '3841', name: 'Sedlíšťka'},
  {id: '5660', name: 'Sedlnice'},
  {id: '4218', name: 'Šelešovice'},
  {id: '3850', name: 'Semanín'},
  {id: '3852', name: 'Semily'},
  {id: '3853', name: 'Semínova Lhota'},
  {id: '3855', name: 'Semonice'},
  {id: '3856', name: 'Semošice'},
  {id: '3857', name: 'Semošice-Peřina'},
  {id: '3858', name: 'Sendražice'},
  {id: '3863', name: 'Senice na Hané'},
  {id: '3862', name: 'Senice na Hané zastávka'},
  {id: '3864', name: 'Senohraby'},
  {id: '3865', name: 'Senomaty'},
  {id: '4219', name: 'Šenov'},
  {id: '2965', name: 'Šenov u Nového Jičína'},
  {id: '3867', name: 'Sepekov'},
  {id: '4221', name: 'Ševětín'},
  {id: '3869', name: 'Sezimovo Ústí'},
  {id: '3901', name: 'Silůvky'},
  {id: '4223', name: 'Šimpach'},
  {id: '3910', name: 'Skalice nad Svitavou'},
  {id: '3911', name: 'Skalice u České Lípy'},
  {id: '3913', name: 'Skalná'},
  {id: '3914', name: 'Skalsko'},
  {id: '56', name: 'Skály'},
  {id: '3920', name: 'Sklené nad Oslavou'},
  {id: '3922', name: 'Skochovice'},
  {id: '3925', name: 'Skotnice'},
  {id: '3926', name: 'Skovice'},
  {id: '3927', name: 'Skramouš'},
  {id: '3928', name: 'Skrbeň'},
  {id: '3932', name: 'Skříněřov'},
  {id: '3933', name: 'Skřivany'},
  {id: '3930', name: 'Skrochovice'},
  {id: '3935', name: 'Skuhrov pod Brdy'},
  {id: '3934', name: 'Skuhrov pod Třemšínem'},
  {id: '3936', name: 'Skuteč'},
  {id: '3937', name: 'Skviřín'},
  {id: '3940', name: 'Slaný'},
  {id: '3941', name: 'Slaný předměstí'},
  {id: '4225', name: 'Šlapanice'},
  {id: '6485', name: 'Šlapanice zastávka'},
  {id: '4226', name: 'Šlapanov'},
  {id: '3942', name: 'Slapy'},
  {id: '3943', name: 'Slatina nad Zdobnicí'},
  {id: '3944', name: 'Slatina pod Hazmburkem'},
  {id: '3945', name: 'Slatina u Vysokého Mýta'},
  {id: '3946', name: 'Slatiňany'},
  {id: '3947', name: 'Slatinice'},
  {id: '3948', name: 'Slavětín nad Ohří'},
  {id: '3949', name: 'Slavětín u Březnice'},
  {id: '3950', name: 'Slaviboř'},
  {id: '3951', name: 'Slavičín'},
  {id: '3952', name: 'Slavkov u Brna'},
  {id: '3953', name: 'Slavkov u Opavy'},
  {id: '3954', name: 'Slavníč'},
  {id: '3955', name: 'Slavonice'},
  {id: '3960', name: 'Slemeno'},
  {id: '3961', name: 'Slezské Rudoltice'},
  {id: '3963', name: 'Slibovice'},
  {id: '4227', name: 'Šluknov'},
  {id: '4229', name: 'Šluknov zastávka'},
  {id: '3965', name: 'Smetanova Lhota'},
  {id: '3966', name: 'Smidary'},
  {id: '3967', name: 'Smilov'},
  {id: '3968', name: 'Smiřice'},
  {id: '3969', name: 'Smiřice zastávka'},
  {id: '3971', name: 'Smrčná'},
  {id: '3972', name: 'Smržovka'},
  {id: '3973', name: 'Smržovka dolní nádraží'},
  {id: '3974', name: 'Smržovka střed'},
  {id: '3975', name: 'Smržovka-Luční'},
  {id: '3977', name: 'Smyslov'},
  {id: '3978', name: 'Sobčice'},
  {id: '3980', name: 'Soběšín'},
  {id: '3979', name: 'Soběslav'},
  {id: '3981', name: 'Sobíňov'},
  {id: '3983', name: 'Sobotka'},
  {id: '3986', name: 'Sokolnice-Telnice'},
  {id: '3987', name: 'Sokolov'},
  {id: '3991', name: 'Solnice'},
  {id: '3992', name: 'Solnice zastávka'},
  {id: '3993', name: 'Solopysky'},
  {id: '3997', name: 'Sopotnice'},
  {id: '4003', name: 'Soumarský Most'},
  {id: '4004', name: 'Spálenec'},
  {id: '4005', name: 'Spálov'},
  {id: '4006', name: 'Spáňov'},
  {id: '4230', name: 'Špičák'},
  {id: '57', name: 'Špičky'},
  {id: '4015', name: 'Spytihněv'},
  {id: '4018', name: 'Srbsko'},
  {id: '4019', name: 'Srby'},
  {id: '4020', name: 'Srní u České Lípy'},
  {id: '4021', name: 'Sruby'},
  {id: '4231', name: 'Štáblovice'},
  {id: '4036', name: 'Stachovice'},
  {id: '4034', name: 'Stadice'},
  {id: '4232', name: 'Šťáhlavice'},
  {id: '4233', name: 'Šťáhlavy'},
  {id: '4039', name: 'Staňkov'},
  {id: '4041', name: 'Stará Boleslav'},
  {id: '4042', name: 'Stará Huť'},
  {id: '4043', name: 'Stará Paka'},
  {id: '4045', name: 'Stará Role'},
  {id: '4048', name: 'Staré Jesenčany'},
  {id: '4049', name: 'Staré Křečany'},
  {id: '4050', name: 'Staré Město pod Sněžníkem'},
  {id: '4051', name: 'Staré Město u Uherského Hradiště'},
  {id: '4052', name: 'Staré Místo u Jičína'},
  {id: '4053', name: 'Staré Sedliště'},
  {id: '4054', name: 'Staré Splavy'},
  {id: '4055', name: 'Starec'},
  {id: '4061', name: 'Stařeč'},
  {id: '4057', name: 'Starkoč'},
  {id: '4234', name: 'Štarnov'},
  {id: '4058', name: 'Starý Kolín'},
  {id: '4059', name: 'Starý Plzenec'},
  {id: '4060', name: 'Starý Šachov'},
  {id: '4062', name: 'Stašov'},
  {id: '4066', name: 'Stéblová'},
  {id: '4067', name: 'Stebnice'},
  {id: '4068', name: 'Stebno'},
  {id: '4235', name: 'Štědrá'},
  {id: '4069', name: 'Stehlovice'},
  {id: '4236', name: 'Štěpánkovice'},
  {id: '4237', name: 'Štěpánov'},
  {id: '4238', name: 'Štěpánovice'},
  {id: '4239', name: 'Šternberk'},
  {id: '4240', name: 'Štětí'},
  {id: '4241', name: 'Štětkovice'},
  {id: '4242', name: 'Štipoklasy'},
  {id: '4243', name: 'Štítary'},
  {id: '4244', name: 'Štítina'},
  {id: '4245', name: 'Štíty'},
  {id: '4075', name: 'Stochov'},
  {id: '4074', name: 'Stod'},
  {id: '4076', name: 'Stožec'},
  {id: '4079', name: 'Strachovice'},
  {id: '4078', name: 'Strahov'},
  {id: '4080', name: 'Strakonice'},
  {id: '4081', name: 'Straky'},
  {id: '4246', name: 'Štramberk'},
  {id: '4083', name: 'Strančice'},
  {id: '4106', name: 'Střapole'},
  {id: '4086', name: 'Straškov'},
  {id: '4088', name: 'Stratov'},
  {id: '4089', name: 'Stráž nad Nisou'},
  {id: '4090', name: 'Stráž nad Ohří'},
  {id: '4091', name: 'Stráž u Tachova'},
  {id: '4092', name: 'Stražisko'},
  {id: '4093', name: 'Strážnice'},
  {id: '4108', name: 'Střechov nad Sázavou'},
  {id: '4107', name: 'Středokluky'},
  {id: '4109', name: 'Střelice'},
  {id: '4110', name: 'Střelice dolní'},
  {id: '4112', name: 'Střelná'},
  {id: '4111', name: 'Střelná v Krušných horách'},
  {id: '4113', name: 'Střelské Hoštice'},
  {id: '4114', name: 'Střeň'},
  {id: '4115', name: 'Střezimíř'},
  {id: '4116', name: 'Stříbrná Skalice'},
  {id: '4117', name: 'Stříbrné Hory'},
  {id: '4118', name: 'Stříbro'},
  {id: '4120', name: 'Střítež u Českého Těšína'},
  {id: '4121', name: 'Střítež u Jihlavy'},
  {id: '4098', name: 'Struhařov'},
  {id: '4100', name: 'Strunkovice nad Blanicí'},
  {id: '5324', name: 'Strunkovice nad Volyňkou obec'},
  {id: '4102', name: 'Stružinec'},
  {id: '4103', name: 'Stružnice'},
  {id: '4123', name: 'Studánka'},
  {id: '4124', name: 'Studenec'},
  {id: '4125', name: 'Studénka'},
  {id: '4126', name: 'Studénka město'},
  {id: '4127', name: 'Stupno'},
  {id: '4129', name: 'Stvolínky'},
  {id: '4130', name: 'Stvořidla'},
  {id: '4249', name: 'Šubířov'},
  {id: '4141', name: 'Suchdol nad Lužnicí'},
  {id: '4140', name: 'Suchdol nad Lužnicí zastávka'},
  {id: '4142', name: 'Suchdol nad Odrou'},
  {id: '4143', name: 'Suchovršice'},
  {id: '4133', name: 'Sudoměř u Mladé Boleslavi'},
  {id: '4134', name: 'Sudoměř u Písku'},
  {id: '4136', name: 'Sudoměřice nad Moravou'},
  {id: '4137', name: 'Sudoměřice u Bechyně'},
  {id: '4138', name: 'Sudoměřice u Tábora'},
  {id: '4144', name: 'Sulejovice'},
  {id: '4145', name: 'Sulislav'},
  {id: '4251', name: 'Šumice'},
  {id: '4252', name: 'Šumná'},
  {id: '4253', name: 'Šumperk'},
  {id: '4152', name: 'Sušice'},
  {id: '4256', name: 'Švábov'},
  {id: '1412', name: 'Svádov'},
  {id: '4156', name: 'Svatoňovice'},
  {id: '4157', name: 'Svatý Štěpán'},
  {id: '4158', name: 'Světec'},
  {id: '4160', name: 'Světice'},
  {id: '4161', name: 'Světlá Hora'},
  {id: '4164', name: 'Světlá nad Sázavou'},
  {id: '4163', name: 'Světlá nad Sázavou město'},
  {id: '4162', name: 'Světlá nad Sázavou-Josefodol'},
  {id: '4166', name: 'Svídnice'},
  {id: '4257', name: 'Švihov u Jesenice'},
  {id: '4258', name: 'Švihov u Klatov'},
  {id: '4168', name: 'Svinětice'},
  {id: '4169', name: 'Svitávka'},
  {id: '4170', name: 'Svitavy'},
  {id: '4171', name: 'Svitavy zastávka'},
  {id: '4172', name: 'Svitavy-Lačnov'},
  {id: '4173', name: 'Svitavy-Lány'},
  {id: '4175', name: 'Svobodné Heřmanice'},
  {id: '4176', name: 'Svojetín'},
  {id: '4177', name: 'Svojkovice'},
  {id: '4178', name: 'Svojšín'},
  {id: '4179', name: 'Svor'},
  {id: '4181', name: 'Svržno'},
  {id: '4186', name: 'Sychrov'},
  {id: '4187', name: 'Synkov'},
  {id: '4188', name: 'Syřenov'},
  {id: '4261', name: 'Tábor'},
  {id: '4262', name: 'Tábor-Čápův Dvůr'},
  {id: '6016', name: 'Tábor-Měšice'},
  {id: '4263', name: 'Tachov'},
  {id: '4264', name: 'Tachov zastávka'},
  {id: '4265', name: 'Tachov-Bíletín'},
  {id: '4266', name: 'Tample'},
  {id: '4267', name: 'Tanvald'},
  {id: '4268', name: 'Tanvald zastávka'},
  {id: '4269', name: 'Tanvaldský Špičák'},
  {id: '4278', name: 'Tatce'},
  {id: '4279', name: 'Tatenice'},
  {id: '4311', name: 'Tchořovice'},
  {id: '1413', name: 'Těchlovice'},
  {id: '4282', name: 'Těchonín'},
  {id: '4283', name: 'Těchoraz'},
  {id: '4286', name: 'Telč'},
  {id: '4287', name: 'Telč-Staré Město'},
  {id: '4285', name: 'Telce'},
  {id: '4288', name: 'Telnice'},
  {id: '4289', name: 'Temelín'},
  {id: '4291', name: 'Teplá'},
  {id: '4292', name: 'Teplice lesní brána'},
  {id: '4295', name: 'Teplice nad Bečvou'},
  {id: '4296', name: 'Teplice nad Metují'},
  {id: '4293', name: 'Teplice nad Metují město'},
  {id: '4294', name: 'Teplice nad Metují skály'},
  {id: '4297', name: 'Teplice v Čechách'},
  {id: '4298', name: 'Teplice zámecká zahrada'},
  {id: '4300', name: 'Teplička u Karlových Varů'},
  {id: '4304', name: 'Terezín u Čejče'},
  {id: '4308', name: 'Tetčice'},
  {id: '4318', name: 'Tišice'},
  {id: '4319', name: 'Tišnov'},
  {id: '4321', name: 'Tlučná'},
  {id: '4322', name: 'Tlumačov'},
  {id: '4323', name: 'Tmáň'},
  {id: '4324', name: 'Tochovice'},
  {id: '6387', name: 'Tochovice zastávka'},
  {id: '58', name: 'Točník'},
  {id: '4328', name: 'Tomice'},
  {id: '4329', name: 'Tomíkovice'},
  {id: '4335', name: 'Touchovice u Loun'},
  {id: '4337', name: 'Toušice'},
  {id: '4338', name: 'Toužim'},
  {id: '4339', name: 'Tovačov'},
  {id: '4378', name: 'Třebčice'},
  {id: '4379', name: 'Třebčín'},
  {id: '4380', name: 'Třebechovice pod Orebem'},
  {id: '4381', name: 'Třebelice'},
  {id: '4382', name: 'Třebelovice'},
  {id: '4383', name: 'Třebeň'},
  {id: '4386', name: 'Třebešice'},
  {id: '61', name: 'Třebestovice'},
  {id: '4387', name: 'Třebětice'},
  {id: '4388', name: 'Třebíč'},
  {id: '4389', name: 'Třebíč-Borovina'},
  {id: '4391', name: 'Třeboň'},
  {id: '4392', name: 'Třeboň lázně'},
  {id: '4393', name: 'Třebovětice'},
  {id: '4394', name: 'Třebovice v Čechách'},
  {id: '4395', name: 'Třebušice'},
  {id: '4397', name: 'Třemešná ve Slezsku'},
  {id: '4398', name: 'Třemešné pod Přimdou'},
  {id: '4400', name: 'Třemošná u Plzně'},
  {id: '4401', name: 'Třemošnice'},
  {id: '4402', name: 'Třešť'},
  {id: '4403', name: 'Třešť město'},
  {id: '4350', name: 'Trhanov'},
  {id: '4351', name: 'Trhový Štěpánov'},
  {id: '4404', name: 'Třinec (Trzyniec)'},
  {id: '4408', name: 'Třinec centrum (Trzyniec Centrum)'},
  {id: '4407', name: 'Třinec-Konská (Trzyniec - KOŃSKA)'},
  {id: '4409', name: 'Třísov'},
  {id: '4354', name: 'Trmice'},
  {id: '4356', name: 'Trnová'},
  {id: '4357', name: 'Trnovany'},
  {id: '4358', name: 'Trnovany u Litoměřic'},
  {id: '4360', name: 'Trocnov'},
  {id: '4362', name: 'Trojany'},
  {id: '4363', name: 'Troubelice'},
  {id: '4364', name: 'Troubelice zastávka'},
  {id: '60', name: 'Troubsko'},
  {id: '4365', name: 'Trpík'},
  {id: '4366', name: 'Trpísty'},
  {id: '4367', name: 'Tršnice'},
  {id: '4368', name: 'Trutnov hlavní nádraží'},
  {id: '4369', name: 'Trutnov střed'},
  {id: '4370', name: 'Trutnov zastávka'},
  {id: '4371', name: 'Trutnov-Poříčí'},
  {id: '4373', name: 'Trutnov-Volanov'},
  {id: '4377', name: 'Tržek'},
  {id: '4410', name: 'Tuchoměřice'},
  {id: '4411', name: 'Tuklaty'},
  {id: '4415', name: 'Tuněchody'},
  {id: '4420', name: 'Turnov'},
  {id: '4422', name: 'Turnov město'},
  {id: '4427', name: 'Tvršice'},
  {id: '4430', name: 'Týn nad Vltavou'},
  {id: '4431', name: 'Týnec nad Labem'},
  {id: '4432', name: 'Týnec nad Sázavou'},
  {id: '4434', name: 'Týniště'},
  {id: '4433', name: 'Týniště nad Orlicí'},
  {id: '4435', name: 'Úborsko'},
  {id: '4437', name: 'Údolí'},
  {id: '4440', name: 'Uherské Hradiště'},
  {id: '4441', name: 'Uhersko'},
  {id: '4442', name: 'Uherský Brod'},
  {id: '4443', name: 'Uherský Ostroh'},
  {id: '4444', name: 'Uhlířské Janovice'},
  {id: '4445', name: 'Úholičky'},
  {id: '4446', name: 'Úhřetice'},
  {id: '4447', name: 'Uhřice u Kyjova'},
  {id: '4448', name: 'Uhřičice obec'},
  {id: '4449', name: 'Újezd nad Zbečnem'},
  {id: '4450', name: 'Újezd Svatého Kříže'},
  {id: '4451', name: 'Újezd u Brna'},
  {id: '4452', name: 'Újezd u Chocně'},
  {id: '4453', name: 'Újezd u Uničova'},
  {id: '4454', name: 'Újezdec u Číčenic'},
  {id: '4455', name: 'Újezdec u Luhačovic'},
  {id: '4461', name: 'Unčín'},
  {id: '4462', name: 'Unhošť'},
  {id: '4463', name: 'Uničov'},
  {id: '4464', name: 'Uničov zastávka'},
  {id: '4471', name: 'Úpohlavy'},
  {id: '4472', name: 'Úpořiny'},
  {id: '4473', name: 'Urbaneč'},
  {id: '4489', name: 'Úštěk'},
  {id: '4482', name: 'Ústí nad Labem hlavní nádraží'},
  {id: '4483', name: 'Ústí nad Labem sever'},
  {id: '4484', name: 'Ústí nad Labem západ'},
  {id: '4476', name: 'Ústí nad Labem-Střekov'},
  {id: '4485', name: 'Ústí nad Orlicí'},
  {id: '4480', name: 'Ústí nad Orlicí město'},
  {id: '4486', name: 'Ústí u Staré Paky'},
  {id: '4487', name: 'Ústí u Vsetína zastávka'},
  {id: '4491', name: 'Úvalno'},
  {id: '4492', name: 'Úvaly'},
  {id: '4493', name: 'Úžice'},
  {id: '4499', name: 'Václavice'},
  {id: '4501', name: 'Valašská Polanka'},
  {id: '4502', name: 'Valašské Klobouky'},
  {id: '4503', name: 'Valašské Meziříčí'},
  {id: '4504', name: 'Valašské Příkazy'},
  {id: '4505', name: 'Valdek'},
  {id: '4508', name: 'Valšov'},
  {id: '4509', name: 'Valtice'},
  {id: '4510', name: 'Valtice město'},
  {id: '4511', name: 'Valtířov'},
  {id: '4512', name: 'Valy u Mariánských Lázní'},
  {id: '4514', name: 'Valy u Přelouče'},
  {id: '4513', name: 'Valy u Přelouče zastávka'},
  {id: '4515', name: 'Vamberk'},
  {id: '4517', name: 'Vápenná'},
  {id: '4524', name: 'Varnsdorf'},
  {id: '5737', name: 'Varnsdorf - pivovar Kocour'},
  {id: '4526', name: 'Varnsdorf staré nádraží'},
  {id: '4527', name: 'Vávrovice'},
  {id: '4528', name: 'Včelná'},
  {id: '4531', name: 'Vejprnice'},
  {id: '4532', name: 'Vejprty'},
  {id: '4533', name: 'Vejprty koupaliště'},
  {id: '4535', name: 'Vejprty zastávka'},
  {id: '4536', name: 'Vejvanovice'},
  {id: '4539', name: 'Veleliby'},
  {id: '4540', name: 'Velešín'},
  {id: '4541', name: 'Velešín město'},
  {id: '4543', name: 'Veletice'},
  {id: '4545', name: 'Velim'},
  {id: '4546', name: 'Velká Bučina'},
  {id: '4547', name: 'Velká Bystřice'},
  {id: '4494', name: 'Velká Bystřice zastávka'},
  {id: '4549', name: 'Velká Jesenice'},
  {id: '4550', name: 'Velká Kraš'},
  {id: '4551', name: 'Velká Kraš zastávka'},
  {id: '4553', name: 'Velká nad Veličkou'},
  {id: '4554', name: 'Velká Štáhle'},
  {id: '4555', name: 'Velká Turná'},
  {id: '4556', name: 'Velké Albrechtice'},
  {id: '4558', name: 'Velké Březno'},
  {id: '4559', name: 'Velké Hamry'},
  {id: '4560', name: 'Velké Hamry město'},
  {id: '4561', name: 'Velké Hoštice'},
  {id: '4562', name: 'Velké Hydčice'},
  {id: '4564', name: 'Velké Karlovice'},
  {id: '4496', name: 'Velké Karlovice zastávka'},
  {id: '4569', name: 'Velké Meziříčí'},
  {id: '4495', name: 'Velké Meziříčí zastávka'},
  {id: '4570', name: 'Velké Opatovice'},
  {id: '4571', name: 'Velké Pavlovice'},
  {id: '4497', name: 'Velké Pavlovice zastávka'},
  {id: '4572', name: 'Velké Poříčí'},
  {id: '4573', name: 'Velké Svatoňovice'},
  {id: '4574', name: 'Velké Zboží'},
  {id: '4575', name: 'Velké Žernoseky'},
  {id: '4576', name: 'Velký Bor'},
  {id: '4577', name: 'Velký Borek'},
  {id: '4578', name: 'Velký Dvůr'},
  {id: '4579', name: 'Velký Grunov'},
  {id: '4580', name: 'Velký Luh'},
  {id: '4581', name: 'Velký Osek'},
  {id: '4582', name: 'Velký Pěčín'},
  {id: '4928', name: 'Velký Ratmírov'},
  {id: '4583', name: 'Velký Rybník'},
  {id: '4584', name: 'Velký Šenov'},
  {id: '4585', name: 'Velký Šenov zastávka'},
  {id: '4586', name: 'Velký Valtinov'},
  {id: '4589', name: 'Veltěže'},
  {id: '4590', name: 'Veltruby'},
  {id: '4591', name: 'Velvary'},
  {id: '4592', name: 'Velvěty'},
  {id: '4593', name: 'Vendolí'},
  {id: '4594', name: 'Vendolí zastávka'},
  {id: '4595', name: 'Vendryně (Wędrynia)'},
  {id: '62', name: 'Verměřovice'},
  {id: '4604', name: 'Veřovice'},
  {id: '4607', name: 'Vesce'},
  {id: '4608', name: 'Vesec u Liberce'},
  {id: '4609', name: 'Veselé pod Rabštejnem'},
  {id: '4611', name: 'Veselí nad Lužnicí'},
  {id: '4610', name: 'Veselí nad Lužnicí zastávka'},
  {id: '4612', name: 'Veselí nad Moravou'},
  {id: '4605', name: 'Veselí nad Moravou - Milokošť'},
  {id: '4606', name: 'Veselí nad Moravou-Zarazice'},
  {id: '4613', name: 'Veselí u Přelouče'},
  {id: '4614', name: 'Veselíčko'},
  {id: '4615', name: 'Vésky'},
  {id: '4617', name: 'Věžky'},
  {id: '4618', name: 'Věžná'},
  {id: '4622', name: 'Víchová nad Jizerou'},
  {id: '4621', name: 'Vidnava'},
  {id: '4627', name: 'Vilémov u Kadaně'},
  {id: '4626', name: 'Vilémov u Kadaně město'},
  {id: '4628', name: 'Vilémov u Šluknova'},
  {id: '4629', name: 'Vilémovice'},
  {id: '4638', name: 'Vilsnice'},
  {id: '4639', name: 'Vimperk'},
  {id: '4640', name: 'Vimperk zastávka'},
  {id: '4645', name: 'Višňová'},
  {id: '4646', name: 'Vítanov'},
  {id: '4647', name: 'Vitčice'},
  {id: '4648', name: 'Vitiněves'},
  {id: '4649', name: 'Vítkov'},
  {id: '4656', name: 'Vižina'},
  {id: '4655', name: 'Vizovice'},
  {id: '4793', name: 'Vladislav zastávka'},
  {id: '4795', name: 'Vlárský průsmyk'},
  {id: '4796', name: 'Vlásenice'},
  {id: '4800', name: 'Vlašim'},
  {id: '4801', name: 'Vlašim zastávka'},
  {id: '4797', name: 'Vlaské'},
  {id: '4798', name: 'Vlastec'},
  {id: '4799', name: 'Vlastějovice'},
  {id: '4802', name: 'Vlčatín'},
  {id: '4803', name: 'Vlčí Důl-Dobranov'},
  {id: '4804', name: 'Vlčice'},
  {id: '4845', name: 'Vlkaneč'},
  {id: '4846', name: 'Vlkanov'},
  {id: '4847', name: 'Vlkoš'},
  {id: '1415', name: 'Vlkov nad Lužnicí'},
  {id: '4848', name: 'Vlkov u Tišnova'},
  {id: '4849', name: 'Vlkovec'},
  {id: '4850', name: 'Vlkovice'},
  {id: '4851', name: 'Vnorovy'},
  {id: '4856', name: 'Vochov'},
  {id: '4853', name: 'Voděrady'},
  {id: '4854', name: 'Vodná'},
  {id: '4855', name: 'Vodňany'},
  {id: '4858', name: 'Vojice'},
  {id: '4860', name: 'Vojkovice nad Ohří'},
  {id: '4859', name: 'Vojkovice nad Svratkou'},
  {id: '4861', name: 'Vojtanov'},
  {id: '4862', name: 'Vojtanov obec'},
  {id: '4864', name: 'Vojtěchov'},
  {id: '4865', name: 'Volary'},
  {id: '4866', name: 'Volevčice'},
  {id: '4869', name: 'Volyně'},
  {id: '4870', name: 'Vonšov'},
  {id: '4871', name: 'Voračice'},
  {id: '4873', name: 'Votice'},
  {id: '4874', name: 'Vrábče'},
  {id: '4875', name: 'Vracov'},
  {id: '4876', name: 'Vrahovice'},
  {id: '4877', name: 'Vraňany'},
  {id: '4878', name: 'Vrané nad Vltavou'},
  {id: '4879', name: 'Vranice'},
  {id: '4880', name: 'Vranov u Stříbra'},
  {id: '4881', name: 'Vranovice'},
  {id: '4883', name: 'Vratimov'},
  {id: '4884', name: 'Vratislavice nad Nisou'},
  {id: '4885', name: 'Vrátno'},
  {id: '4886', name: 'Vráž u Berouna'},
  {id: '4887', name: 'Vráž u Písku'},
  {id: '4888', name: 'Vražkov'},
  {id: '4890', name: 'Vrbátky'},
  {id: '4891', name: 'Vrbatův Kostelec'},
  {id: '4892', name: 'Vrbčany'},
  {id: '4882', name: 'Vrbičany'},
  {id: '4893', name: 'Vrbka'},
  {id: '4894', name: 'Vrbno nad Lesy'},
  {id: '4901', name: 'Vrchlabí'},
  {id: '4902', name: 'Vrchotovy Janovice'},
  {id: '4898', name: 'Vrcovice'},
  {id: '4899', name: 'Vrdy-Koudelov'},
  {id: '4900', name: 'Vrhaveč'},
  {id: '4903', name: 'Vroutek'},
  {id: '4909', name: 'Všechlapy'},
  {id: '4910', name: 'Všejany'},
  {id: '4911', name: 'Všenice'},
  {id: '4912', name: 'Všenory'},
  {id: '4913', name: 'Všeradice'},
  {id: '4914', name: 'Všestary'},
  {id: '4915', name: 'Všesulov'},
  {id: '4916', name: 'Všetaty'},
  {id: '4907', name: 'Vsetín'},
  {id: '4932', name: 'Výheň'},
  {id: '4940', name: 'Vyškov na Moravě'},
  {id: '4941', name: 'Výškov v Čechách'},
  {id: '4933', name: 'Výsluní'},
  {id: '4942', name: 'Vyšné'},
  {id: '4934', name: 'Vysoká Pec'},
  {id: '4935', name: 'Vysoké Mýto'},
  {id: '4936', name: 'Vysoké Mýto město'},
  {id: '4937', name: 'Vysoké Popovice'},
  {id: '4938', name: 'Vysoké Třebušice'},
  {id: '4939', name: 'Vysoké Žibřidovice'},
  {id: '4943', name: 'Vyšší Brod klášter'},
  {id: '5171', name: 'Žabčice'},
  {id: '63', name: 'Záblatíčko'},
  {id: '5172', name: 'Žabokliky'},
  {id: '5173', name: 'Žabonosy'},
  {id: '5028', name: 'Záboří nad Labem'},
  {id: '5029', name: 'Záboří u Číčenic'},
  {id: '5174', name: 'Žabovřesky nad Ohří'},
  {id: '5037', name: 'Zábřeh na Moravě'},
  {id: '5036', name: 'Zábřeh na Moravě zastávka'},
  {id: '5051', name: 'Zachrašťany'},
  {id: '5175', name: 'Žacléř'},
  {id: '5039', name: 'Zadní Poříčí'},
  {id: '5040', name: 'Zadní Třebaň'},
  {id: '5041', name: 'Zádveřice'},
  {id: '5044', name: 'Záhlinice'},
  {id: '5047', name: 'Záhoří'},
  {id: '5048', name: 'Záhořice'},
  {id: '5046', name: 'Záhorovice'},
  {id: '5049', name: 'Zahrádky u České Lípy'},
  {id: '5050', name: 'Zahrady u Rumburka'},
  {id: '5052', name: 'Zaječí'},
  {id: '5053', name: 'Zaječice'},
  {id: '5054', name: 'Zajíčkov'},
  {id: '5055', name: 'Zákolany'},
  {id: '5056', name: 'Zákolany zastávka'},
  {id: '5058', name: 'Zákupy'},
  {id: '401', name: 'Zákupy-Božíkov'},
  {id: '1417', name: 'Žalany'},
  {id: '5176', name: 'Žalany zastávka'},
  {id: '5061', name: 'Zalešany'},
  {id: '5177', name: 'Žalhostice'},
  {id: '5062', name: 'Záluží'},
  {id: '5178', name: 'Žamberk'},
  {id: '5064', name: 'Záměl'},
  {id: '5065', name: 'Zámělíč'},
  {id: '5066', name: 'Zámrsk'},
  {id: '5179', name: 'Žandov'},
  {id: '5071', name: 'Zásmuky'},
  {id: '5073', name: 'Zašová'},
  {id: '5072', name: 'Zastávka u Brna'},
  {id: '5180', name: 'Žatec'},
  {id: '5181', name: 'Žatec západ'},
  {id: '5075', name: 'Zátoň'},
  {id: '5074', name: 'Zátoň-Boubín'},
  {id: '5076', name: 'Zátor'},
  {id: '5077', name: 'Závada'},
  {id: '5078', name: 'Zavidov'},
  {id: '5079', name: 'Závišín'},
  {id: '5081', name: 'Závratec'},
  {id: '5085', name: 'Zbečno'},
  {id: '5088', name: 'Zboněk'},
  {id: '5089', name: 'Zborovice'},
  {id: '5090', name: 'Zborovice zastávka'},
  {id: '5091', name: 'Zbraslavice'},
  {id: '5092', name: 'Zbůch'},
  {id: '65', name: 'Zbudov'},
  {id: '5093', name: 'Zbuzany'},
  {id: '5094', name: 'Zbýšov'},
  {id: '5095', name: 'Zbytiny'},
  {id: '5618', name: 'Žďár - průmyslová zóna'},
  {id: '5182', name: 'Žďár nad Metují'},
  {id: '5183', name: 'Žďár nad Orlicí'},
  {id: '5184', name: 'Žďár nad Sázavou'},
  {id: '5186', name: 'Žďárec u Skutče'},
  {id: '5097', name: 'Zdemyslice'},
  {id: '5098', name: 'Zdětín u Chotětova'},
  {id: '5099', name: 'Zdětín u Prostějova'},
  {id: '5100', name: 'Zdice'},
  {id: '5187', name: 'Ždírec nad Doubravou'},
  {id: '5188', name: 'Ždírec u Plzně'},
  {id: '5101', name: 'Zdislava'},
  {id: '5102', name: 'Zdislavice'},
  {id: '5103', name: 'Zdounky'},
  {id: '5189', name: 'Ždov'},
  {id: '5192', name: 'Želeč'},
  {id: '5193', name: 'Želechovice nad Dřevnicí'},
  {id: '1265', name: 'Zelená Lhota'},
  {id: '5111', name: 'Zeleneč'},
  {id: '5194', name: 'Želenice nad Bílinou'},
  {id: '5195', name: 'Želenice u Slaného'},
  {id: '5196', name: 'Želénky'},
  {id: '5198', name: 'Železná Ruda centrum'},
  {id: '5199', name: 'Železná Ruda město'},
  {id: '5191', name: 'Železná Ruda-Alžbětín'},
  {id: '5201', name: 'Železnice'},
  {id: '5203', name: 'Železný Brod'},
  {id: '5204', name: 'Želina'},
  {id: '5205', name: 'Želivec'},
  {id: '5206', name: 'Želvice'},
  {id: '5115', name: 'Zeměchy'},
  {id: '5207', name: 'Ženklava'},
  {id: '5210', name: 'Žichlínek'},
  {id: '5211', name: 'Žichovice'},
  {id: '5208', name: 'Židlochovice'},
  {id: '5209', name: 'Žihle'},
  {id: '5217', name: 'Žim'},
  {id: '5218', name: 'Žireč'},
  {id: '5219', name: 'Žírovice-Seníky'},
  {id: '1251', name: 'Žitětín'},
  {id: '5220', name: 'Živonín'},
  {id: '5221', name: 'Životice'},
  {id: '5222', name: 'Žiželice'},
  {id: '4930', name: 'Žizníkov výhybna'},
  {id: '5223', name: 'Žlábek'},
  {id: '5124', name: 'Zlatá Koruna'},
  {id: '5125', name: 'Zlaté Hory'},
  {id: '5224', name: 'Žlebské Chvalovice'},
  {id: '5225', name: 'Žleby'},
  {id: '5226', name: 'Žleby zastávka'},
  {id: '5127', name: 'Zlenice'},
  {id: '5128', name: 'Zlín střed'},
  {id: '5129', name: 'Zlín-Dlouhá'},
  {id: '5131', name: 'Zlín-Louky'},
  {id: '5133', name: 'Zlín-Malenovice'},
  {id: '5132', name: 'Zlín-Malenovice zastávka'},
  {id: '5134', name: 'Zlín-Podvesná'},
  {id: '5136', name: 'Zlín-Příluky'},
  {id: '5135', name: 'Zlín-Prštné'},
  {id: '5137', name: 'Zlín-U mlýna'},
  {id: '5138', name: 'Zliv'},
  {id: '5139', name: 'Zlonice'},
  {id: '5140', name: 'Zlonice zastávka'},
  {id: '5141', name: 'Zlonín'},
  {id: '5227', name: 'Žloukovice'},
  {id: '5228', name: 'Žlutice'},
  {id: '5142', name: 'Znojmo'},
  {id: '5750', name: 'Znojmo nemocnice'},
  {id: '5144', name: 'Znojmo-Nový Šaldorf'},
  {id: '5145', name: 'Znosim'},
  {id: '5150', name: 'Zruč nad Sázavou'},
  {id: '5149', name: 'Zruč nad Sázavou zastávka'},
  {id: '5152', name: 'Zubří'},
  {id: '5230', name: 'Žulová'},
  {id: '5162', name: 'Zvoleněves'},
];

// enrich the original raw object with name with removed diacritics
const allStations: TrainStation[] = allStationsRaw.map(x => {
  return {id: x.id, name: x.name, noDiacriticsName: removeDiacritics(x.name)};
});
