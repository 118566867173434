import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {IconProp} from '@fortawesome/fontawesome-svg-core';

interface IconProps {
  label: string;
  icon: IconProp;
}
export const IconItem = (props: IconProps) => {
  return (
    <div style={{textAlign: 'center'}}>
      <FontAwesomeIcon icon={props.icon} size="lg" />
      <div className="" style={{fontSize: '0.6em', marginTop: '0.25em'}}>
        {props.label}
      </div>
    </div>
  );
};
