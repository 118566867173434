import {useSigninCheck} from 'reactfire';

/**
 * Home page - default landing page
 * @return {React.FC}
 */
function Home() {
  const {status, data: signInCheckResult} = useSigninCheck();
  const signedIn = status !== 'loading' && signInCheckResult.signedIn === true;
  const userName = signedIn ? signInCheckResult?.user?.displayName ?? 'user' : 'Stranger';
  return (
    <div>
      <h1>Home</h1>
      <div>Welcome to RIS, {userName}!</div>
    </div>
  );
}

export default Home;
