import {Table} from 'react-bootstrap';
import {StockData} from '../hooks/useStocks';
import MinusDeleteButton from './shared/MinusDeleteButton';
import PlusAddButton from './shared/PlusAddButton';
import PrettyCzDate from './shared/PrettyCzDate';
import {StocksSettingsBuy} from './Stocks.d';

{
  /* MINITABLE in row with buying details */
}
type MyStocksDataForBuys = {
  stocksBuy: StocksSettingsBuy[];
  stock: StockData;
  detailsShowing: string;
  setupMode: boolean;
  netProfitUSD: (a: number, b: number, c: number) => number;
  prettyUSDPlusMinusNumber: (a: number) => string;
  czk: number | undefined;
  prettyCZKPlusMinusNumber: (a: number) => string;
  deleteBuyClick: (a: StocksSettingsBuy) => void;
  editBuyClick: (a: StocksSettingsBuy) => void;
};
// eslint-disable-next-line require-jsdoc
function StockBuys({
  stocksBuy,
  stock,
  detailsShowing,
  setupMode,
  netProfitUSD,
  prettyUSDPlusMinusNumber,
  czk,
  prettyCZKPlusMinusNumber,
  deleteBuyClick,
  editBuyClick,
}: MyStocksDataForBuys) {
  return (
    <>
      {stocksBuy.map((buy, index) => {
        // NO BUYs - minitable shows only if there is a buy
        if (stock.symbol !== buy.symbol)
          return (
            <Table key={index} className={detailsShowing === stock.symbol && setupMode ? 'd-block' : 'd-none'}></Table>
          );
        // IF BUYs - minitable shows only if there is a buy
        else
          return (
            <Table striped key={index} className={detailsShowing === buy.symbol ? 'd-block' : 'd-none'}>
              <thead>
                <tr className="small text-secondary">
                  <th className="text-begin">Bought</th>
                  <th className="text-center">OPEN PRICE</th>
                  <th className="text-center">VOLUME</th>
                  <th className="text-center">NET PROFIT</th>
                  <th className="text-end fw-normal">in CZK</th>
                  <th className="text-end"></th>
                </tr>
              </thead>
              <tbody className="small fw-bold">
                <tr>
                  <td></td>
                  {/* OPEN PRICE */}
                  <td className="text-center">${buy.openPrice}</td>
                  {/* VOLUME */}
                  <td className="text-center"> {buy.volume}</td>
                  {/* NET PROFIT in $ */}
                  <td
                    className={
                      netProfitUSD(stock.regularMarketPrice, buy.openPrice, buy.volume) >= 0
                        ? 'text-center text-success'
                        : 'text-center text-danger'
                    }
                  >
                    {prettyUSDPlusMinusNumber(netProfitUSD(stock.regularMarketPrice, buy.openPrice, buy.volume))}
                  </td>
                  <td className="text-center text-secondary fw-normal">
                    {prettyCZKPlusMinusNumber(
                      netProfitUSD(stock.regularMarketPrice, buy.openPrice, buy.volume) * (czk ? czk : 1)
                    )}
                  </td>

                  {/* EDIT button */}
                  <td className="text-end">
                    <PlusAddButton visibility={setupMode} doOnClick={() => editBuyClick(buy)} label="Edit" />
                  </td>
                </tr>
                <tr>
                  {/* DATE of buy */}
                  <td className="text-begin">
                    <PrettyCzDate dateStringIso={buy.openDateIso} text="" />
                  </td>
                  <td></td>
                  <td></td>
                  {/* NET PROFIT in % */}
                  <td
                    className={
                      Math.round(((stock.regularMarketPrice - buy.openPrice) / (buy.openPrice / 100)) * 100) / 100 >= 0
                        ? 'text-center text-success'
                        : 'text-center text-danger'
                    }
                  >
                    {Math.round(((stock.regularMarketPrice - buy.openPrice) / (buy.openPrice / 100)) * 100) / 100} %
                  </td>
                  <td></td>
                  {/* "-" (delete buy) button in % */}
                  <td className="text-end">
                    <MinusDeleteButton visibility={setupMode} doOnClick={() => deleteBuyClick(buy)} label="" />
                  </td>
                </tr>
              </tbody>
              <tfoot></tfoot>
            </Table>
          );
      })}
    </>
  );
}

export default StockBuys;
