import {useEffect, useState} from 'react';
import {Formik, Form, Field, ErrorMessage} from 'formik';
import {useSettings} from '../hooks/useSettings';
import {useExchangeRates} from '../hooks/useExchangeRates';
import {REQUEST_STATUS} from '../types/requestStatus';
import PlusAddButton from './shared/PlusAddButton';
import MinusDeleteButton from './shared/MinusDeleteButton';
import {Button, Modal, Table} from 'react-bootstrap';
import WindowedSelect from 'react-windowed-select';
import PrettyCzDateTime from './shared/PrettyCzDateTime';
import SettingsButton from './shared/SettingsButton';
/**
 * Exchange Rate component draw page content
 * @return {React.FC}
 */
function ExchangeRate() {
  /** Settings */
  interface ExchangeRateSettings {
    currencies: string[];
    amounts: number[];
    currentAmount: number;
  }
  /** Default settings to use if there are no or invalid user settings available */
  const defaultUserSettings: ExchangeRateSettings = {
    currencies: ['CZK', 'USD', 'EUR'],
    amounts: [1, 10, 100],
    currentAmount: 1,
  };
  // ------------------------ states ----------------------------------------------------------
  // hold array of amounst on buttons
  const [buttonAmounts, setButtonAmounts] = useState<number[]>(defaultUserSettings.amounts);
  // current amount
  const [myCurrentAmount, setAmount] = useState<number>(defaultUserSettings.currentAmount);
  // array of user's currencies
  const [userCurrencies, setUserCurrencies] = useState<string[]>(defaultUserSettings.currencies);
  // holds the value, if + and - setting buttons are visible
  const [setupMode, setSetupMode] = useState(false);
  // holds the value, if menu with rates are visible
  const [showRates, setShowRates] = useState(false);
  // main currency is on the 1.st place of user currencies
  const mainCurrency = userCurrencies[0];
  // main custom hook state with status and data
  const [exchangeRatesStatus, exchangeRatesStatusData] = useExchangeRates(mainCurrency);
  // main custom hook with user settings
  const {settings: userSettings, setSettings: setUserSettings} = useSettings('exchange-rates');
  // holds data from useExchangeRates hook state
  const allRates = exchangeRatesStatusData?.rates;
  const arrAllRates = allRates ? Object.keys(allRates) : ['EUR'];
  const arrAllRatesWithoutUsersRates: string[] = [];
  for (const prvek of arrAllRates) {
    if (!userCurrencies.includes(prvek)) {
      arrAllRatesWithoutUsersRates.push(prvek);
    }
  }
  // loading user settings from hook
  const fillFromSettingsHook = () => {
    // use default + override with what is available in user settings
    const s = Object.assign(defaultUserSettings, userSettings);
    setUserCurrencies(s.currencies);
    setButtonAmounts(s.amounts);
    setAmount(s.currentAmount);
  };
  // set the values
  useEffect(() => {
    fillFromSettingsHook();
  }, [userSettings]);
  // ---------------------------- components --------------------------------------------
  /** Modal Select drop down with all available stock symbols
   * @return {React.FC} JSX
   */
  function ModalSelectStock() {
    const handleClose = () => {
      setShowRates(false);
    };
    return allRates ? (
      <Modal show={showRates} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Add Currency</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <WindowedSelect
            windowThreshold={100}
            placeholder="Select currency"
            onChange={(x: any) => {
              if (x?.value) {
                addCurrencyToUserCurrenciesClick(x?.value);
                handleClose();
              }
            }}
            // filterOption={createFilter({ignoreAccents: false})} // speed up by not requesting diacritics handling
            options={arrAllRatesWithoutUsersRates.map(x => {
              return {
                value: x,
                label: x,
              };
            })}
          />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    ) : (
      <>no data</>
    );
  }

  interface PropsCurr {
    currency: string;
  }
  /**
   * a component returns 1 row of amount, currency and rate from userCurrencies
   * @param {PropsCurr} props currency code e.g. 'EUR'
   * @return {React.FC} component representing the row
   */
  function OneRowOfUserCurrency(props: PropsCurr) {
    const currency = props.currency;
    if (!allRates || !Object.hasOwn(allRates, currency)) return <></>;
    const rate = allRates[currency];
    return (
      <tr
        onClick={() => {
          switchMainCurrencyClick(currency);
        }}
      >
        <td className="text-begin" role="currency">
          {1 * myCurrentAmount} {mainCurrency}
        </td>
        <td className="text-center">
          <strong>{currency}</strong>{' '}
        </td>
        <td className="text-end">{(Math.round(rate * myCurrentAmount * 1000) / 1000).toFixed(2)}</td>
        <td className="text-end">
          <MinusDeleteButton
            visibility={setupMode}
            label="Delete"
            doOnClick={() => removeCurrencyFromUserCurrenciesClick(currency)}
          />
        </td>
      </tr>
    );
  }
  /**
   * a component of user's Rates
   * @return {React.FC} - rows with amounts, currencies and rates
   */
  function TableOfUserCurrency() {
    return (
      <Table striped hover>
        <thead>
          <tr>
            <th className="text-begin">Amount in {mainCurrency}</th>
            <th className="text-center">Currency</th>
            <th className="text-end">Value</th>
            <th className="text-end"></th>
          </tr>
        </thead>
        <tbody>
          {userCurrencies.map((item, index) => {
            if (index === 0) return <tr key={index}></tr>; // skip the main currency here
            return <OneRowOfUserCurrency currency={item} key={index} />;
          })}
        </tbody>
        <tfoot>
          <tr>
            <td>
              <PlusButtonForCurrencyEditation />
            </td>
            <td></td>
            <td></td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
    );
  }
  interface PropsValue {
    value: number;
  }
  /**
   * a component draws button with amount
   * @param {PropsValue} props - number value
   * @return {React.FC}
   */
  function AmountButton(props: PropsValue) {
    return (
      <>
        <Button
          variant="primary"
          onClick={() => changeAmountInCurrenciesClick(props.value)}
          role="amount"
          data-testid={'amount-' + props.value}
        >
          {props.value}
        </Button>{' '}
        <MinusDeleteButton visibility={setupMode} label="" doOnClick={() => removeAmountButtonClick(props.value)} />{' '}
      </>
    );
  }
  /**
   * a component for drawing Amount buttons for changing amounts
   * @return {React.FC}
   */
  function AmountButtons() {
    if (!buttonAmounts) return <></>;
    return (
      <>
        <p>
          {buttonAmounts
            .sort((a, b) => a - b) // sort by growing value
            .map((item, index) => {
              return <AmountButton value={item} key={index} />;
            })}
        </p>
        <Formik
          initialValues={{ownAmount: ''}}
          onSubmit={values => {
            const num = parseFloat(values.ownAmount);
            if (!isNaN(num)) changeAmountInCurrenciesClick(num);
          }}
        >
          {({values}) => (
            <Form>
              <Field type="number" name="ownAmount" placeholder="zadejte hodnotu" />
              <ErrorMessage name="ownAmount" component="div" />
              <PlusAddButton
                label="Add"
                visibility={setupMode}
                doOnClick={() => {
                  addAmountClick(values.ownAmount);
                }}
              />
            </Form>
          )}
        </Formik>
      </>
    );
  }
  /**
   * a component returns '+' button for editation of user's currency
   * @return {React.FC}
   */
  function PlusButtonForCurrencyEditation() {
    return (
      <>
        <PlusAddButton visibility={setupMode} doOnClick={showAllCurrenciesClick} label="Add" />
        <ModalSelectStock />
      </>
    );
  }
  // ============== onClick functions ================================================================
  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv
  // shows all editing buttons for editation
  const showSettingButtonsClick = () => {
    if (!setupMode) {
      setShowRates(false);
    }
    setSetupMode(!setupMode);
  };
  // "-" button remove currency from userCurrencies component
  const removeCurrencyFromUserCurrenciesClick = (str: string) => {
    const newArray = userCurrencies.filter(item => item !== str);
    setUserCurrencies(newArray);
    setUserSettings({currencies: newArray, amounts: buttonAmounts, currentAmount: myCurrentAmount});
  };
  // "+" button shows a component with menu of all un-used currencies
  const showAllCurrenciesClick = () => setShowRates(!showRates);
  // button add the currency to userCurrencies
  const addCurrencyToUserCurrenciesClick = (str: string) => {
    if (!userCurrencies.includes(str)) {
      const newArray = [...userCurrencies, str];
      setUserCurrencies(newArray);
      setUserSettings({currencies: newArray, amounts: buttonAmounts, currentAmount: myCurrentAmount});
    }
  };
  // press to "user rate row" switch the mainCurrency and fetch new rates for picked rate
  const switchMainCurrencyClick = (str: string) => {
    const newArray = userCurrencies.filter(p => p !== str);
    newArray.unshift(str);
    setUserCurrencies(newArray);
    setUserSettings({currencies: newArray, amounts: buttonAmounts, currentAmount: myCurrentAmount});
  };
  // button with amount will switch amounts to all userCurrencies
  const changeAmountInCurrenciesClick = (num: number) => {
    setAmount(num);
    setUserSettings({currencies: userCurrencies, amounts: buttonAmounts, currentAmount: num});
  };
  // "-" button remove amount button
  const removeAmountButtonClick = (num: number) => {
    const newArray = buttonAmounts.filter(item => item !== num);
    setButtonAmounts(newArray);
    setUserSettings({currencies: userCurrencies, amounts: newArray, currentAmount: myCurrentAmount});
  };
  // "+" button add input value to buttonAmounts
  const addAmountClick = (strNum: string) => {
    const num = parseFloat(strNum);
    if (num && !isNaN(num)) {
      const newField = [...buttonAmounts, num];
      newField.sort((x, y) => x - y);
      setButtonAmounts(newField);
      setUserSettings({currencies: userCurrencies, amounts: newField, currentAmount: myCurrentAmount});
    }
  };
  // ===================================================================================
  // ============================= rednder main component ==============================
  return (
    <div id="ExchangeRate">
      <h1>Exchange Rate</h1>
      <SettingsButton visibility={true} doOnClick={showSettingButtonsClick} label={''} />
      <h2>{mainCurrency}</h2>
      {exchangeRatesStatus === REQUEST_STATUS.LOADING && <div> ...načítám data... </div>}
      {exchangeRatesStatus === REQUEST_STATUS.FAILURE && <div>no data</div>}
      {exchangeRatesStatus === REQUEST_STATUS.SUCCESS && (
        <>
          <TableOfUserCurrency />
          <PrettyCzDateTime dateStringIso={exchangeRatesStatusData?.timestampIso} text="poslední aktualizace: " />
          <AmountButtons />
        </>
      )}
    </div>
  );
  // }
}
export default ExchangeRate;
