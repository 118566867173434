import {DateTime} from 'luxon';
import {StockDay} from '../components/Stocks.d';
import _ from 'lodash';

/**
 * Finds nearest business day for given date
 * @param {DateTime} date
 * @param {StockDay[]} stockDays
 * @return {StockDay | undefined} stock day or null if not found
 */
export function findNearestOlderRecordByDate(date: DateTime, stockDays: StockDay[]): StockDay | undefined {
  const targetDate = date.toISODate();
  stockDays = _.orderBy(stockDays, x => x.date, 'desc');
  for (const x of stockDays) {
    if (x.date <= targetDate) return x;
  }
  return undefined;
}
