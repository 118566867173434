import './App.css';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import {useSigninCheck} from 'reactfire';
import {AppLayout} from './AppLayout';
import {SignInDialog} from '../auth/Login';
import Home from './Home';
import NavigationBar from './NavigationBar';
import ErrorBoundary from './ErrorBoundary';
import DemoPage1 from './DemoPage1';
import DemoPage2 from './DemoPage2';
import Calculator from './Calculator';
import Stocks from './Stocks';

import {FirebaseProvider} from '../firebase/FirebaseProvider';
import {UserSettingsProvider} from '../context/UserSettingsProvider';
import ExchangeRate from './ExchangeRate';
import Trains from './Trains';
import Mortgage from './Mortgage';
import {Col, Container, Row} from 'react-bootstrap';

/**
 * Main application
 * @return {React.FC}
 */
function App() {
  return (
    <div>
      <FirebaseProvider>
        <AppWithFirebase />
      </FirebaseProvider>
    </div>
  );
}

/** App with Firebase context available
 * @return {React.FC}
 */
function AppWithFirebase() {
  const {status, data: signInCheckResult} = useSigninCheck();
  // signInCheckResult;
  // status;
  // return (
  //   <ul>
  //     <li key="1">Signed in: {'' + signInCheckResult?.signedIn}</li>
  //     <li key="2">User info: {signInCheckResult?.user?.displayName ?? '-'}</li>
  //     <li key="3">User id: {signInCheckResult?.user?.uid ?? '-'}</li>
  //     <li key="4">
  //       User e-mail: {signInCheckResult?.user?.email ?? '-'} (
  //       {signInCheckResult?.user?.emailVerified ? 'verified' : 'unverified'})
  //     </li>
  //   </ul>
  // );
  // return <AppNotSignedIn />;
  if (status === 'success') {
    if (signInCheckResult?.signedIn === true && signInCheckResult?.user?.uid) {
      // user signed in
      return (
        <ErrorBoundary>
          <AppSignedIn />
        </ErrorBoundary>
      );
    } else {
      // user NOT signed in
      return <AppNotSignedIn />;
    }
  }
  // Firebase initializing...
  return <>...</>;
}

/** App context to show when user is signed in
 * @return {React.FC}
 */
function AppSignedIn() {
  return (
    <UserSettingsProvider>
      <BrowserRouter>
        <AppLayout>
          <Container>
            <Row>
              <Col sm={2}>
                <NavigationBar />
              </Col>
              <Col sm={9}>
                <Routes>
                  <Route path="/" element={<Home />}></Route>
                  <Route path="/demoPage1" element={<DemoPage1 />}></Route>
                  <Route path="/demoPage2" element={<DemoPage2 />}></Route>
                  <Route path="/calculator" element={<Calculator />}></Route>
                  <Route path="/exchangeRate" element={<ExchangeRate />}></Route>
                  <Route path="/stocks" element={<Stocks />}></Route>
                  <Route path="/trains" element={<Trains />}></Route>
                  <Route path="/mortgage" element={<Mortgage />}></Route>
                </Routes>
              </Col>
            </Row>
          </Container>
        </AppLayout>
      </BrowserRouter>
    </UserSettingsProvider>
  );
}

/** App context to show when user is NOT signed in
 * @return {React.FC}
 */
function AppNotSignedIn() {
  return <SignInDialog />;
}

export default App;
