// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyCIdwJUs-Dw9TVlx7ad-yaeUvcTg8ABYHs',
  authDomain: 'ris.rajdlova.cz',
  projectId: 'ris-data',
  storageBucket: 'ris-data.appspot.com',
  messagingSenderId: '210361274258',
  appId: '1:210361274258:web:8d8c982387e9034541fb4b',
  measurementId: 'G-NGVKYY7J71',
};
