import {Colors} from '../../constants/colors';

/**
 *
 * @return {nn}
 */
export const Line = ({color = Colors.grey}: {color?: string}): JSX.Element => {
  return (
    <div
      style={{
        borderColor: color,
        border: '0.2px solid',
        width: '100%',
      }}
    />
  );
};
