import {getAuth, connectAuthEmulator} from 'firebase/auth'; // Firebase v9+
import {getFunctions, connectFunctionsEmulator} from 'firebase/functions'; // Firebase v9+
import {initializeAppCheck, ReCaptchaV3Provider} from 'firebase/app-check';
import {getFirestore, connectFirestoreEmulator} from 'firebase/firestore';
import {
  FirebaseAppProvider,
  FirestoreProvider,
  FunctionsProvider,
  AuthProvider,
  AppCheckProvider,
  useFirebaseApp,
  SuspenseWithPerf,
} from 'reactfire';
import {firebaseConfig} from './firebaseConfig';
import {firebaseConfigRegion} from './firebaseConfigRegion';
import {firebaseConfigAppCheck} from './firebaseConfigAppCheck';
import 'firebase/performance';
import Props from '../types/Props';

// production/test mode
const TEST_MODE = process.env.NODE_ENV !== 'production';

// for non-production mode, we'll use 'demo-' prefix, which is hardcoded Firebase prefix for test projects
if (TEST_MODE) {
  firebaseConfig.projectId = 'demo-' + firebaseConfig.projectId;
}

/**
 * Initializer of Firebase components
 * @param {Props} children element
 * @return {React.ReactNode} components wrapped into initialized Firebase providers.
 */
function FirebaseComponents({children}: Props): React.ReactElement {
  const app = useFirebaseApp(); // a parent component contains a `FirebaseAppProvider`

  // Google AppCheck protecting backend endpoints
  let appCheck;
  if (firebaseConfigAppCheck.appCheckEnabled) {
    appCheck = initializeAppCheck(app, {
      provider: new ReCaptchaV3Provider(firebaseConfigAppCheck.siteKeyToken),
      isTokenAutoRefreshEnabled: true,
    });
  }

  // initialize Firebase SDKs
  const auth = getAuth(app);
  const functions = getFunctions(app, firebaseConfigRegion.gcpRegionFunctions);
  const firestore = getFirestore(app);

  // non-production env are supposed to use local emulators
  if (TEST_MODE) {
    // Set up emulators
    connectAuthEmulator(auth, 'http://127.0.0.1:9099');
    connectFunctionsEmulator(functions, '127.0.0.1', 5001);
    // connectFirestoreEmulator(firestore, '127.0.0.1', 8080);
    connectFirestoreEmulator(firestore, 'localhost', 8080);
  }

  // any child components will be able to use `useUser`, `useFunctions`, etc.
  const body = (
    <AuthProvider sdk={auth}>
      <FunctionsProvider sdk={functions}>
        <FirestoreProvider sdk={firestore}>{children}</FirestoreProvider>
      </FunctionsProvider>
    </AuthProvider>
  );
  if (appCheck) {
    return <AppCheckProvider sdk={appCheck}>{body}</AppCheckProvider>;
  }
  return body;
}

/**
 * Provider of the Firebase features to children underneath
 * @param {Props} children
 * @return {React.ReactNode} elements provided with Firebase services
 */
export function FirebaseProvider({children}: Props): React.ReactElement {
  return (
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <SuspenseWithPerf fallback={'Firestore loading...'} traceId="load-firebase-status">
        <FirebaseComponents>{children}</FirebaseComponents>
      </SuspenseWithPerf>
    </FirebaseAppProvider>
  );
}
