import {doc, setDoc, getDocFromCache, DocumentReference, deleteDoc} from 'firebase/firestore';
// import { initializeFirestore, doc, collection, enableIndexedDbPersistence, increment, updateDoc,
//  orderBy, query, addDoc, DocumentData, setDoc } from 'firebase/firestore';
import {useFirestoreDocData, useFirestore, useSigninCheck} from 'reactfire';

const PARENT_COLLECTION_NAME = 'storage';

/**
 * Updates document in Firestore db
 * @param {any} docRef
 * @param {any} data
 * @param {boolean} enforceWrite Do skip current document comparison to new data before actual write
 * @return {Promise}
 */
async function setFirestoreDocData(docRef: DocumentReference<any>, data: any, enforceWrite = false): Promise<void> {
  // console.log(
  //   `useFirestoreUserDocument.setFirestoreDocData(): ${
  //     enforceWrite ? '[enforced]' : '[only-if-updated]'
  //   } writing data [${docRef.path}]= `,
  //   data
  // );
  // store only if it really changes anything
  if (!enforceWrite) {
    try {
      const cachedDoc = await getDocFromCache(docRef);
      const origJson = JSON.stringify(cachedDoc.data());
      const newJson = JSON.stringify(data);
      // console.log(`useFirestoreUserDocument.setFirestoreDocData(): cachedInDb = ${origJson}`);
      // console.log(`useFirestoreUserDocument.setFirestoreDocData(): incoming = ${newJson}`);
      if (origJson === newJson) {
        // console.log('useFirestoreUserDocument: setFirestoreDocData(): Skiping doc write. No content change.');
        return;
      }
    } catch (e) {
      // console.log('useFirestoreUserDocument: setFirestoreDocData(): error = ', e);
    }
  }
  // write to Firestore
  // console.log('useFirestoreUserDocument: setDoc starting...');
  await setDoc(docRef, data);
  // console.log('useFirestoreUserDocument: setDoc done');
}

/**
 * Custom hook providing r/w access to a specific Firestore document of the signed-in user.
 * REQUIRES FirestoreProvider configured in the hierarchy above.
 * @param {string} docName Key of the settings. Each component/use-case is supposed to have an arbitrary key.
 * @return {{}} { status, data, setData, clearData }
 */
export function useFirestoreUserDocument(docName: string) {
  const firestore = useFirestore();
  // console.log('useFirestoreDocument(): firestore.app.projectId=' + JSON.stringify(firestore.app.options?.projectId));
  const {data: signInCheckResult} = useSigninCheck();
  const signedIn = signInCheckResult?.signedIn === true;
  const uid = signInCheckResult?.user?.uid ?? 'anonymous';

  // subscribe to a document for realtime updates
  // Firestore document specific to user
  // console.log('useFirestoreUserDocument(): path=', 'users', uid, PARENT_COLLECTION_NAME, docName);
  const docRef = doc(firestore, 'users', uid, PARENT_COLLECTION_NAME, docName);
  const setData = async (x: any) => {
    signedIn && uid && (await setFirestoreDocData(docRef, x));
  };
  const clearData = async () => {
    signedIn && uid && (await deleteDoc(docRef));
  };

  // console.log('useFirestoreUserDocument(): about to read data...');
  const {status, data} = useFirestoreDocData(docRef, {
    idField: 'id', // this field will be added to the object created from each document
  });
  // console.log('useFirestoreUserDocument(): data=', data);

  return {status, data, setData, clearData};
}
