import {styles} from '../../constants/styles';

/**
 *
 * @return {nn}
 */
export const Spacing = ({height = styles.margin}: {height?: number}): JSX.Element => {
  return (
    <div
      style={{
        height: height,
        marginLeft: styles.margin,
      }}
    />
  );
};
