import {useState, useEffect} from 'react';

// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
}

interface WindowSizeInfo {
  size: Size;
  isLandscapeMode: boolean;
}
/**
 * Custom hook providing browser window size in React's friendly way.
 * @return {Size}
 */
export function useWindowSize(): WindowSizeInfo {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });

  // derive landscape/portrait mode
  const isLandscapeMode: boolean =
    windowSize.width === undefined ||
    windowSize.height === undefined ||
    windowSize.width >= windowSize.height;

  useEffect(() => {
    /**
     * Handler to call on window resize
     */
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    // Add event listener to get called when browser window resizes
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // return cleanup function to release callRemove event listener
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  // return state size
  return {size: windowSize, isLandscapeMode};
}
