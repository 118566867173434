import {screenPc, screenTbl} from '../constants/styles';
import CalculatorBasic from './calculator/CalculatorBasic';
import {CalculatorManDays} from './calculator/CalculatorManDays';

/**
 * @return {React.FC}
 */
function Calculator() {
  return (
    <>
      <h1>Calculator</h1>
      <div
        style={{
          marginTop: 30,
          display: 'flex',
          gap: 40,
          flexDirection: screenPc || screenTbl ? 'row' : 'column',
          marginBottom: 40,
        }}
      >
        <div style={{flex: 1, marginTop: 16}}>
          <CalculatorBasic />
        </div>
        <div style={{flex: 1, marginTop: 16}}>
          <CalculatorManDays />
        </div>
      </div>
    </>
  );
}
export default Calculator;
