import {useState, useEffect} from 'react';
import {useFunctions} from 'reactfire';
import {httpsCallable} from 'firebase/functions';
// import {DateTime} from 'luxon';
import {REQUEST_STATUS} from '../types/requestStatus';
// import {useLocalStorage} from './useLocalStorage';
export * from '../types/requestStatus';

export interface ExchangeRates {
  base: string; // e.g. 'CZK'
  timestampIso: string; // '2022-07-26T06:18:58+0000'
  rates: {[key: string]: number}; // e.g. { 'USD': 0.042, 'EUR': 0.037  }
}

/**
 * Fetches exchange rates from a remote API
 * @param {Functions} functions Firebase functions
 * @param {string} base Source currency code. e.g. 'CZK'
 * @return {ExchangeRates} exchange rates for the given base currency
 */
async function fetchExchangeRates(functions: any, base: string): Promise<ExchangeRates> {
  // TODO local caching of today's data

  console.log('fetchExchangeRates, base: ' + base);
  // invoke the function
  const fbExchangeRates = httpsCallable(functions, 'exchangeRates');
  const result = await fbExchangeRates({currencyCode: base});
  const rates = result.data as ExchangeRates;
  return rates;

  // // hardcoded values to get started
  // const delay = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));
  // await delay(2000); // simulate data loading delay
  // const exchangeRates: ExchangeRates = {
  //   base: base,
  //   timestampIso: DateTime.now().toISO(),
  //   rates: {USD: 0.042, EUR: 0.037},
  // };
  // return exchangeRates;
}

/**
 * Custom hook providing exchange rates for given base currency
 * @param {string} base Source currency code. e.g. 'CZK'
 * @param {boolean} noCache Do not use cache for data.
 * @return {ExchangeRateInfo}
 */
export function useExchangeRates(base: string, noCache = false): [REQUEST_STATUS, ExchangeRates?, string?] {
  // state to keep track of loading
  const [status, setStatus] = useState(REQUEST_STATUS.LOADING);
  const [error, setError] = useState<string | undefined>(undefined);
  const [data, setData] = useState<ExchangeRates | undefined>(undefined);
  // get access to Firebase
  const functions = useFunctions(); // requires FirebaseProvider as a parent
  // get access to Local Storage
  // const localStorage = useLocalStorage('cache-exchange-rates-' + base);

  // effect to fetch data
  useEffect(() => {
    const fetchData = async () => {
      try {
        setStatus(REQUEST_STATUS.LOADING);
        // try to fetch data from local cache
        // TODO implement caching

        // request to load data, you can use fetch API too
        const payload = await fetchExchangeRates(functions, base);
        // set data in state and loading to success
        setData(payload);
        setStatus(REQUEST_STATUS.SUCCESS);
      } catch (e: unknown) {
        console.error('Error', e);
        setStatus(REQUEST_STATUS.FAILURE);
        setError((e as Error).toString());
      }
    };
    fetchData();
  }, [base]);

  return [status, data, error];
}
