import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSquareMinus} from '@fortawesome/free-regular-svg-icons';

/** Settings */
interface DoOnClickFunc {
  (): void;
}
interface ButtonProps {
  visibility: boolean;
  doOnClick: DoOnClickFunc;
  label: string;
}
/**
 * @param {ButtonProps} props
 * @return {React.FC}
 */
function MinusDeleteButton(props: ButtonProps) {
  return (
    <button
      style={props.visibility ? {visibility: 'visible'} : {visibility: 'hidden'}}
      type="button"
      className="btn btn-outline-danger btn-sm"
      onClick={event => {
        event.stopPropagation();
        props.doOnClick();
      }}
    >
      <FontAwesomeIcon icon={faSquareMinus} />
      {props.label === '' ? '' : '\xa0' + '\xa0' + props.label}
    </button>
  );
}

export default MinusDeleteButton;
