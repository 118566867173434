import {DateTime} from 'luxon';
import {useState} from 'react';
import {useSettings} from '../hooks/useSettings';
import {useTrains} from '../hooks/useTrains';
import Select from 'react-select';
import SettingsButton from './shared/SettingsButton';
import {Button} from 'react-bootstrap';
import PlusAddButton from './shared/PlusAddButton';
import PrettyCzDateTime from './shared/PrettyCzDateTime';
import MinusDeleteButton from './shared/MinusDeleteButton';

/**
 * @return {React.FC}
 */
function Trains() {
  // ------------- states -----------------------------------------------
  const [setupMode, setSetupMode] = useState(false); // for visibility of + and - settings buttons
  const [showMenu, setShowMenu] = useState(false); // for visibility of menu with stations
  const {settings: userSettings, setSettings: setUserSettings} = useSettings('trains'); // user settings from custom hook

  const {allStations} = useTrains();
  const trainsUserSettings = (userSettings as string[]) ?? [];
  const userStations = trainsUserSettings?.map(station => allStations.find(x => x.name === station));

  const MySelectComponent = () => (
    <Select
      placeholder="Vyberte stanici"
      onChange={x => addValueFromMenuButtonClick(x?.value)}
      options={allStations.map(x => {
        return {
          value: x.name,
          label: x.name,
        };
      })}
    />
  );
  interface PropsUrl {
    buttonText: string;
    url: string;
  }
  /**
   * @param {PropsUrl} props
   * @return {React.FC}
   */
  function TrainStationsButton(props: PropsUrl) {
    return (
      <a href={props.url} target="_blank" rel="noreferrer">
        {<Button> {props.buttonText}</Button>}
      </a>
    );
  }

  /**
   * @param {PropsUrl} props
   * @return {React.FC}
   */
  function TrainDeparturesButton(props: PropsUrl) {
    return (
      <a href={props.url} target="_blank" rel="noreferrer">
        {<Button variant="secondary"> {props.buttonText}</Button>}
      </a>
    );
  }

  /**
   * @return {React.FC}
   */
  function DeparturesButtons() {
    const c = 'https://provoz.spravazeleznic.cz/tabule/Pages/StationTable.aspx?Key=';
    if (userStations) {
      return (
        <>
          {userStations.map((x, i) => {
            if (!x) return <>chyba</>;
            return (
              <span key={i}>
                <TrainStationsButton key={i} buttonText={x.name} url={c + x.id} />
                <MinusDeleteButton visibility={setupMode} doOnClick={() => removeClick(x.name)} label={''} />{' '}
                {/* <MinusButtonForRemoving symbol={x.name} /> */}
              </span>
            );
          })}
        </>
      );
    } else return <></>;
  }
  /**
   * @return {React.FC}
   */
  function MenuWithStations() {
    return (
      <div style={setupMode ? {visibility: 'visible'} : {display: 'none'}}>
        {/* sets setting mode*/}
        <div style={showMenu ? {display: 'block'} : {display: 'none'}}>
          {/* sets showing symbols*/}
          <MySelectComponent />
        </div>
      </div>
    );
  }

  // ========================click funcions========================
  // vvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvvv

  // shows all editing buttons for editation
  const showSettingButtonsClick = () => {
    if (!setupMode) {
      setShowMenu(false);
    }
    setSetupMode(!setupMode);
  };

  // shows editing menu for adding stations
  const visibilityToEditationClick = () => {
    setShowMenu(!showMenu);
  };

  // add the value to user's settings
  const addValueFromMenuButtonClick = (x: any) => {
    if (!trainsUserSettings.includes(x)) setUserSettings([...trainsUserSettings, x]);
  };

  // remove from the user settings
  const removeClick = (s: string) => {
    const newArray = trainsUserSettings.filter(p => p !== s);
    setUserSettings(newArray);
  };

  // ------------------------ the main component ----------------------------------------------
  return (
    <>
      <h1>Trains</h1>
      <SettingsButton visibility={true} doOnClick={showSettingButtonsClick} label={''} />
      <br />
      <br />
      <h5 style={{color: 'grey'}}>ODJEZDY VLAKŮ</h5>
      <DeparturesButtons />
      <br />
      <PlusAddButton visibility={setupMode} doOnClick={visibilityToEditationClick} label={'Přidat stanici'} />
      <MenuWithStations />
      {setupMode ? (
        <br />
      ) : (
        <>
          <br /> <br />
        </>
      )}
      <TrainDeparturesButton buttonText="Spoje vlaků" url="https://idos.idnes.cz/vlaky/spojeni/" />{' '}
      <TrainDeparturesButton buttonText="Poloha vlaků" url="https://mapy.spravazeleznic.cz/vlaky" />{' '}
      <TrainDeparturesButton buttonText="MHD Praha" url="https://idos.idnes.cz/praha/spojeni/" />{' '}
      <PrettyCzDateTime dateStringIso={DateTime.now().toISO()} text="poslední aktualizace: " />
    </>
  );
}

export default Trains;
