// import {useState} from 'react';
import {Colors} from '../../constants/colors';
import {styles} from '../../constants/styles';

/**
 *
 * @return {nn}
 */
export const Input = ({
  label = '',
  bgColor = Colors.primary,
  inpColor = Colors.white,
  labelColor = Colors.black,
  onChangeCallback,
  width = styles.inputMDWidthAndHeight,
  disabled = false,
  value,
  additonalText = '',
}: {
  label?: string;
  bgColor?: string;
  inpColor?: string;
  labelColor?: string;
  onChangeCallback?: (value: string) => void;
  width?: number | string;
  disabled?: boolean;
  value?: string;
  additonalText?: string;
}): JSX.Element => {
  // const [inputValue, setInputValue] = useState(value ?? '');

  const onInputChange = (e: any) => {
    // only numbers
    const re = /^[0-9\b]+$/;
    if (re.test(e.target.value) || e.target.value === '') {
      // setInputValue(e.target.value);
      onChangeCallback && onChangeCallback(e.target.value);
    }
  };
  return (
    <div
      style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: styles.commonCardWidth,
      }}
    >
      <label
        style={{
          borderRadius: 8,
          fontSize: 16,
          color: labelColor,
          textAlign: 'start',
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'flex-end',
          alignItems: 'center',
        }}
      >
        {label}
        <input
          style={{
            backgroundColor: bgColor,
            color: inpColor,
            textAlign: 'center',
            width: width ?? styles.inputMDWidthAndHeight,
            height: styles.inputMDWidthAndHeight,
            border: '0.5px solid',
            borderColor: bgColor,
            borderRadius: 8,
            fontSize: 30,
            marginLeft: styles.margin,
          }}
          type="input"
          onChange={onInputChange}
          value={additonalText ? value + ' ' + additonalText : value}
          maxLength={2}
          disabled={disabled}
        />
      </label>
    </div>
  );
};
