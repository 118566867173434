import Image from 'react-bootstrap/Image';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUser} from '@fortawesome/free-solid-svg-icons';

interface UserIconItemProps {
  label: string;
  iconUrl: string | null;
}
export const UserIconItem = (props: UserIconItemProps) => {
  return (
    <div style={{textAlign: 'center'}}>
      {props.iconUrl ? (
        <Image className="rounded-circle" width="50" src={props.iconUrl} fluid />
      ) : (
        <FontAwesomeIcon icon={faUser} size="lg" />
      )}
      <div className="" style={{fontSize: '0.6em', marginTop: '0.25em'}}>
        {props.label}
      </div>
    </div>
  );
};
